import React, { useEffect, useState } from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { NavLink } from 'react-router-dom'

function TopIntro({ topIntros }) {

    const defaulData = [
        {
            id: 1,
            title: "Customer Episode Design",
            description: "A proven way to transform the customer experience at scale. Focus on earning the passionate loyalty of customers.",
        },
        {
            id: 2,
            title: "Net Promoter System",
            description: "A proven way to transform the customer experience at scale. Focus on earning the passionate loyalty of customers, while inspiring the energy, enthusiasm and creativity of employees to accelerate desirable profit.",
        },
    ]

    const [topIntrosMap, setTopIntrosMap] = useState(defaulData);

    useEffect(() => {
        if (topIntros) {
            setTopIntrosMap(topIntros)
        } else {
            setTopIntrosMap(defaulData)
        }
    }, [topIntros])

    return (
        <div>
            <div className=' px-5'>
                <div className=' max-w-[1200px] mx-auto w-full'>
                    <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-8'>
                        {
                            topIntrosMap.map((item) => {
                                return (
                                    <div key={item.id} className=' relative hover:bg-red-50 hover:cursor-pointer'>
                                        <div key={item.id} className=' border-[1px] border-gray-500/50 px-5 py-6 border-t-red-700 border-t-[4px]  h-full grid grid-cols-1 place-content-between'>
                                            <div className=' text-[24px] font-bold text-gray-700'>{item.title}</div>
                                            <div className=' text-[15px] mt-5 font-serif leading-snug '>
                                                {item.description}
                                            </div>
                                            <div className=' mt-10'>
                                                <NavLink className=' flex'>
                                                    <span>Go</span> <BsArrowRight size={20} className=' relative top-[3px] left-[5px]' />
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopIntro
