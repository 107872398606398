import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../../../context/GlobalContext"
import AllBusinessesGrid from "./AllBusinessesGrid"
import GrayButton from "../../components/GrayButton"
import PageTitle from "../../components/PageTitle"

export const AllBusinessesContent = ({ preloadedValues, user }) => {

    const parsedPreloadedValue = (JSON.parse(preloadedValues))
    const [loading, setLoading] = useState(false)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const [userData, setUserData] = useState(null)

    const [srcData, setSrcData] = useState([])

    const getBusinesses = async () => {
        let endpoint = REACT_APP_API_URL + '?operation=READ_BUSINESSES_BY_USERID&user_id=' + user.user_id
        //window.location.href = endpoint
        let response = await fetch(
            endpoint,
            {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }

            }
        );

        const result = await response.json();
        setSrcData(result.data)
    }

    useEffect(() => {
        getBusinesses()
    }, [])





    return (
        <div className=' mt-6'>

            <div className=" border-b-[1px] border-b-gray-500/50 pb-[16px] grid grid-cols-2">
                <PageTitle title={"My Businesses"} />

                <div className="flex place-content-end place-items-center space-x-1 ">



                    <GrayButton
                        title="Go to home"
                        url="/account/home" />



                </div>
            </div>

            <div className=' h-[32px]'></div>

            <div className=' border-[1px] border-gray-500/30 rounded-[10px] px-[16px] py-[22px]'>

                <div className=' flex gap-3 font-bold text-xl'>
                    <div>
                        {parsedPreloadedValue.email}
                    </div>
                    <div>-</div>
                    <div className=' text-orange-300'>
                        Primary
                    </div>
                </div>
                <div className=' text-sm mt-2'>

                    Below are businesses owned by this account. You can add more businesses, edit them and manage them under this account.
                </div>

                <div className=' mt-5'>
                    <ul className=' list-outside list-disc ml-5 space-y-2'>
                        <li>
                            <div className=' font-semibold'>
                                Visible in emails
                            </div>
                            <ul className=' list-outside'>
                                <li>
                                    Businesses created under this account may be accessed publicly and in emails by public viewers. The email for this account may be used as the 'author' or 'committer' of changes to this account and businesses and assets owned by this account.
                                </li>
                            </ul>
                        </li>

                        <li>
                            <div className=' font-semibold'>
                                Receives notifications
                            </div>
                            <ul className=' list-outside'>
                                <li>
                                    This email address is the default used for Skyepages notifications, i.e., replies to issues, etc.                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>



            <div className=' mt-8 w-full'>
                <AllBusinessesGrid data={srcData} />

            </div>
        </div>
    )
}