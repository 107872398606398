import { createContext, useEffect, useState } from "react";

const BusinessProfileContext = createContext()

const BusinessProfileProvider = ({ children }) => {
    const [business, setBusiness] = useState("a")

    let val = {
        business,
        setBusiness
    }

    return (
        <BusinessProfileContext.Provider value={val}>
            {children}
        </BusinessProfileContext.Provider>
    )
}

export { BusinessProfileContext, BusinessProfileProvider }