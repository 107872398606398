import React, { useEffect, useState } from 'react'
import "../../App.css"
import { ArrowRightIcon } from '@heroicons/react/outline';

const HeroAnimated = ({ category, topTitle, description, id, title, img, imgLink, location, property }) => {



    return (

        <div>
            <style>
                {`
                    .container-img-${id} {
                        background-image: url("${imgLink}");
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        height: 100%;
                        objectFit: cover;
                        width: 100%;
                        animation: slide 10s linear;
                        zIndex: 0;
                    }

                    .scroller-box {
                        display: flex;
                        alignItems: center;
                        flexDirection: row;
                        width: 100%;
                        height: 90vh;
                        backgroundColor: red;
                        position: relative;
                        overflow: hidden;
                    }
                    .top-dark {
                        background-image: linear-gradient(rgba(0, 0, 0, 0.55), transparent);
                        height: 200px;
                        position: absolute;
                        z-index: 1;
                        top: 0px;
                        width: 100%;
                    }

                    .bottom-dark {
                        position: absolute;
                        width: 100%;
                        height: 20%;
                        bottom: 0px;
                        background-image: linear-gradient(360deg, rgba(0, 0, 0, 1), transparent);
                    }

                    .left-dark {
                        position: absolute;
                        width: 50%;
                        height: 100%;
                        bottom: 0px;
                        z-index: 0;
                        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.58), transparent);
                    }

                    @keyframes slide {
                        100% {
                            transform: scale(0%);
                            transition-duration: 1000s;
                        }

                        100% {
                            transform: scale(120%, 120%);
                            transition-duration: 1000s;
                            /* The image width */
                        }
                    }
                `}
            </style>

            <div className='scroller-box'>
                <div className='top-dark'></div>
                <div className={`container-img-${id}`}></div>
                <div className='bottom-dark'></div>
                <div className='left-dark'></div>

                <div className=' w-full absolute h-screen flex flex-row px-5'>
                    <div className=' max-w-[1200px] mx-auto w-full flex flex-row items-center'>
                        <div className='w-[80%] md:w-[60%] lg:w-[45%] space-y-5  '>
                            <div className='text-lg text-white font-semibold text-left'>
                                {category}
                            </div>
                            <div className=' text-4xl lg:text-5xl font-black text-white leading-[100%] text-left '>
                                {title}
                            </div>
                            <div className=' text-white font-semibold flex space-x-2 hover:cursor-pointer hover:translate-x-6 transition duration-500'>
                                <span className='text-lg'>Read More</span>
                                <span className=''>
                                    <ArrowRightIcon className=' h-8 w-[50px] ' />
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default HeroAnimated
