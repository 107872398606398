import React from 'react'

const SocialMediaIcons = ({ icon, handleUrl }) => {
    return (
        <div>
            <div>
                <a href={handleUrl}>
                    <div className='w-[22px] h-[22px] rounded-full border-[1px] border-gray-400 flex place-content-center place-items-center text-[17px]'>
                        {icon}
                    </div>
                </a>
            </div>
        </div>
    )
}

export default SocialMediaIcons
