import { createContext, useState } from "react";

const BusinessContext = createContext()

function BusinessProvider({ children }) {
    const [businessData, setBusinessData] = useState([])

    const getBusinessData = (data) => {
        if (data) {
            setBusinessData(data)
        }
    }

    const value = {
        getBusinessData,
        businessData,
        setBusinessData
    }
    return (
        <BusinessContext.Provider value={value}>
            {children}
        </BusinessContext.Provider>
    )
}

export { BusinessProvider, BusinessContext }