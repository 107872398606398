import React, { useContext, useEffect, useState } from 'react'
import { AiFillBackward, AiFillBank, AiFillEye, AiFillHome, AiFillPlusCircle, AiFillSecurityScan, AiFillStepBackward, AiOutlineAim, AiOutlineEdit, AiOutlineHome, AiOutlineLogin, AiOutlineMail, AiOutlinePicCenter, AiOutlinePullRequest, AiOutlineSearch, AiOutlineSecurityScan, AiOutlineSetting, AiOutlineShoppingCart, AiOutlineSolution, AiOutlineUser, AiOutlineWifi, AiTwotoneBuild, AiTwotoneEdit, AiTwotoneMail, AiTwotonePlusCircle } from 'react-icons/ai'
import Account from '../Account'


import { RiAdvertisementFill } from 'react-icons/ri'
import { GlobalContext } from '../../../context/GlobalContext'
import { UtilContext } from '../../../context/UtilContext'
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom'
import { HiHome } from 'react-icons/hi'

const genericLinks = [
    {
        id: 1,
        link: "/",
        title: "Home",
        icon: <AiOutlineShoppingCart size={20} />
    },
    {
        id: 2,
        link: "/search",
        title: "Search",
        icon: <AiTwotonePlusCircle size={20} />
    },
    {
        id: 3,
        link: "/privacy",
        title: "Privacy",
        icon: <AiOutlineAim size={20} />
    },
    {
        id: 4,
        link: "/advertise",
        title: "Advertise",
        icon: <AiOutlineAim size={20} />
    },
    {
        id: 4,
        link: "/contact",
        title: "Contact",
        icon: <AiOutlineAim size={20} />
    },
]

const accountData = [
    {
        id: 1,
        link: "/account/home",
        title: "Home",
        icon: <AiOutlineShoppingCart size={20} />
    },
    {
        id: 2,
        link: "/account/add-business",
        title: "Add Business",
        icon: <AiTwotonePlusCircle size={20} />
    },
    {
        id: 3,
        link: "/account/all-businesses",
        title: "All Businesses",
        icon: <AiOutlineAim size={20} />
    },
]

const settingsData = [
    {
        id: 1,
        link: "/account/settings/profile",
        title: "Profile",
        icon: <AiOutlineSetting size={20} />
    },
    {
        id: 2,
        link: "/account/settings/email",
        title: "Email",
        icon: <AiOutlineMail size={20} />
    },
    {
        id: 3,
        link: "/account/settings/change-password",
        title: "Change Password",
        icon: <AiOutlineLogin size={20} />
    },
    {
        id: 4,
        link: "/account/settings/reset-password",
        title: "Reset Password",
        icon: <AiOutlinePullRequest size={20} />
    },
]

const otherLinksData = [
    {
        id: 1,
        link: "/",
        title: "Home",
        icon: <HiHome size={20} />
    },
    {
        id: 2,
        link: "/search",
        title: "Search",
        icon: <AiOutlineSearch size={20} />
    },
    {
        id: 3,
        link: "/advertise",
        title: "Advertise",
        icon: <RiAdvertisementFill size={20} />
    },
    {
        id: 4,
        link: "/privacy",
        title: "Privacy",
        icon: <AiFillSecurityScan size={20} />
    },

]

const SettingsLeftNav = () => {
    const {
        REACT_APP_API_USER_IMG_URI,
        REACT_APP_API_URL,
        REACT_APP_IMG_PATH,
        user
    } = useContext(GlobalContext)

    const { getUrlId,
        getSubstr } = useContext(UtilContext)

    const [loading, setLoading] = useState(true)
    const [userData, setUserData] = useState(null)

    const location = useLocation();
    const { hash, pathname, search } = location

    const getImg = () => {
        let imag = ""

        if (userData?.img) {
            imag = REACT_APP_API_USER_IMG_URI + userData?.img
        } else {
            imag = REACT_APP_IMG_PATH + "/img/st.png"
        }

        return imag
    }

    useEffect(() => {
        //alert(getUrlId())
    }, [])

    useEffect(() => {
        const endpoint = REACT_APP_API_URL + "?operation=READ_USER&id=" + user?.user_id
        //window.location.href = (endpoint)
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                console.log(res.data)
                //alert(res.data.data[0])
                const data = JSON.stringify(res.data.data[0])

                setUserData(JSON.parse(data))
                setLoading(false)
                //alert(userData)
            })
    }, [setUserData])


    const getNavLinks = (data) => {
        return data.map((item) => {
            let activatedLink = false;

            if (pathname === item.link) {
                activatedLink = true;

            } else {
                activatedLink = false;

            }

            return (
                <div key={item.id} className={`${activatedLink ? 'bg-gray-500/20' : ''} 
                    @apply px-3 rounded-[6px] hover:cursor-pointer hover:bg-gray-500/15 py-[8px] font-semibold flex space-x-3 place-content-start place-items-center

                `}>
                    <div>
                        {item.icon}
                    </div>
                    <div className={`inline-block w-full`}>
                        <a className='' href={item.link}>
                            <div>
                                {item.title}
                            </div>
                        </a>
                    </div>
                </div>
            )
        })
    }

    const getNavHeader = (hdrText, icon) => {
        return (
            <div className='py-[8px] font-bold 
            text-[17px] flex space-x-3 
            place-content-start 
            place-items-center
 '>
                <div>
                    {icon}
                </div>
                <div className=' relative top-[2px]'>
                    {hdrText}
                </div>
            </div>
        )
    }

    return (
        <div>

            <div className=''>
                {
                    user &&
                    <div className=' flex place-items-center gap-4 mt-6 mb-6'>
                        <div className=' rounded-full w-[62px] h-[60px] border-[1px] border-gray-700 overflow-hidden relative'>
                            <img
                                className=' w-full h-full object-cover'
                                src={getImg()} alt="" />
                        </div>
                        <div className=' space-y-[-3px]'>
                            <div className=' text-2xl font-bold tracking-tight'>{getSubstr(user?.first_name, 20)}</div>
                            <div className=' text-[17px]'>Business</div>
                        </div>
                    </div>
                }

                {user && getNavHeader("Account", <AiOutlineHome size={20} />)}
                {user && getNavLinks(accountData)}

                {user &&
                    <div className='border-b-[1px] border-gray-500/40 mt-[16px] mb-[16px]'></div>
                }

                {
                    user &&
                    getNavHeader("Settings", < AiOutlineUser size={20} />)
                }

                {
                    user &&
                    getNavLinks(settingsData)
                }


                {user &&
                    <div className='border-b-[1px] border-gray-500/40 mt-[16px] mb-[16px]'></div>
                }


                {getNavHeader("Home", <AiOutlineLogin size={20} />)}
                {getNavLinks(otherLinksData)}


                {/*  <div className='border-b-[1px] border-gray-500/40 mt-[16px] mb-[16px]'></div> */}








            </div>
        </div>
    )
}

export default SettingsLeftNav
