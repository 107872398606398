import React from 'react'
import { BiBuilding, BiLibrary } from 'react-icons/bi'
import { BsHouse, BsMarkerTip, BsMusicNote } from 'react-icons/bs'
import { CgDesktop } from 'react-icons/cg'
import { FaDesktop } from 'react-icons/fa'
import { GrCafeteria, GrLounge } from 'react-icons/gr'
import { LuWine } from 'react-icons/lu'
import { MdAirplaneTicket, MdLocalLaundryService } from 'react-icons/md'
import { PiComputerTower } from 'react-icons/pi'
import { SiMarketo } from 'react-icons/si'
import Tooltip from './AppTooltip'
import AppTooltip from './AppTooltip'
import { Typography } from '@mui/material'

const DisplayFacilities = ({ facilities }) => {
    return (
        <div className=' flex flex-wrap'>
            {facilities.has_bar == true &&
                <AppTooltip
                    className=" cursor-pointer shadow-lg"
                    title={
                        <React.Fragment>
                            <Typography color="inherit"><b>Bar</b></Typography>
                            <em><b><u>{"Details and Offers:"}</u></b></em> .{' '}
                            {facilities.bar}
                        </React.Fragment>
                    }>
                    <div className='flex cursor-pointer flex-col w-fit place-content-center place-items-center'>
                        <div className=' text-[15px]'>
                            <LuWine />
                        </div>
                        <div className='capitalize text-[12px]'>
                            Bar
                        </div>
                    </div>
                </AppTooltip>
            }
            {
                facilities.has_cafetaria == true &&

                <AppTooltip
                    className=" cursor-pointer shadow-lg"
                    title={
                        <React.Fragment>
                            <Typography color="inherit"><b>Cafe</b></Typography>
                            <em><b><u>{"Details and Offers:"}</u></b></em> .{' '}
                            {facilities.cafetaria}
                        </React.Fragment>
                    }>
                    <div className='flex flex-col cursor-pointer space-x-2 w-fit place-content-center place-items-center'>
                        <div className=' text-[15px]'>
                            <GrCafeteria />
                        </div>
                        <div className='capitalize text-[12px]'>
                            Cafe
                        </div>
                    </div>
                </AppTooltip>
            }

            {
                facilities.has_library == true &&

                <AppTooltip
                    className=" cursor-pointer shadow-lg"
                    title={
                        <React.Fragment>
                            <Typography color="inherit"><b>Library</b></Typography>
                            <em><b><u>{"Details and Offers:"}</u></b></em> .{' '}
                            {facilities.library}
                        </React.Fragment>
                    }>
                    <div className='flex flex-col cursor-pointer space-x-2 w-fit place-content-center place-items-center'>
                        <div className=' text-[15px]'>
                            <BiLibrary />
                        </div>
                        <div className='capitalize text-[12px]'>
                            Library
                        </div>
                    </div>
                </AppTooltip>
            }

            {
                facilities.has_lounge === true &&
                <AppTooltip
                    className=" cursor-pointer shadow-lg"
                    title={
                        <React.Fragment>
                            <Typography color="inherit"><b>Lounge</b></Typography>
                            <em><b><u>{"Details and Offers:"}</u></b></em> .{' '}
                            {facilities.lounge}
                        </React.Fragment>
                    }>
                    <div className='flex flex-col cursor-pointer space-x-2 w-fit place-content-center place-items-center'>
                        <div className=' text-[15px]'>
                            <GrLounge />
                        </div>
                        <div className='capitalize text-[12px]'>
                            Lounge
                        </div>
                    </div>
                </AppTooltip>
            }

            {
                facilities.has_music == true &&
                <AppTooltip
                    className=" cursor-pointer shadow-lg"
                    title={
                        <React.Fragment>
                            <Typography color="inherit"><b>Music</b></Typography>
                            <em><b><u>{"Details and Offers:"}</u></b></em> .{' '}
                            {facilities.music}
                        </React.Fragment>
                    }>
                    <div className='flex flex-col space-x-2 w-fit place-content-center place-items-center cursor-pointer'>
                        <div className=' text-[15px]'>
                            <BsMusicNote />
                        </div>
                        <div className='capitalize text-[12px]'>
                            Music
                        </div>
                    </div>
                </AppTooltip>
            }

            {
                facilities.has_business_center == true &&


                <AppTooltip
                    className=" cursor-pointer shadow-lg"
                    title={
                        <React.Fragment>
                            <Typography color="inherit"><b>Business Center</b></Typography>
                            <em><b><u>{"Details and Offers:"}</u></b></em> .{' '}
                            {facilities.business_center}
                        </React.Fragment>
                    }>
                    <div className='flex flex-col space-x-2 w-fit place-content-center place-items-center cursor-pointer'>
                        <div className=' text-[15px]'>
                            <FaDesktop />
                        </div>
                        <div className='capitalize text-[12px]'>
                            Business
                        </div>
                    </div>
                </AppTooltip>
            }

            {
                facilities.has_mini_mart == true &&

                <AppTooltip
                    className=" cursor-pointer shadow-lg"
                    title={
                        <React.Fragment>
                            <Typography color="inherit"><b>Minimart</b></Typography>
                            <em><b><u>{"Details and Offers:"}</u></b></em> .{' '}
                            {facilities.mini_mart}
                        </React.Fragment>
                    }>
                    <div className='flex flex-col space-x-2 w-fit place-content-center place-items-center cursor-pointer'>
                        <div className=' text-[15px]'>
                            <MdLocalLaundryService />
                        </div>
                        <div className='capitalize text-[12px]'>
                            Minimart
                        </div>
                    </div>
                </AppTooltip>

            }

        </div >
    )
}

export default DisplayFacilities
