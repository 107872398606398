import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { UtilContext } from '../../context/UtilContext'
import axios from 'axios'
import HeaderLightSearch from '../../components/header/headerLight/HeaderLightSearch'
import HeaderSpace from '../../components/header/HeaderSpace'
import SubNav from '../business/SubNav'
import H16 from '../../components/body/H16'
import { BusinessProvider } from '../business/BusinessContext'
import PageStructure from '../business/PageStructure'
import Error404 from '../404/error404'
import Prefooter from '../../components/footer/Prefooter'
import Footer from '../../components/footer/Footer'
import { sha256 } from 'js-sha256'
import TopBanner from '../../components/ads/TopBanner'
import H32 from '../../components/body/H32'

const Interceptor = () => {
    const { getUrlId } = useContext(UtilContext)
    const [username, setUsername] = useState("")
    const [businessData, setBusinessData] = useState(null)
    const { REACT_APP_API_URL, user, gcolors } = useContext(GlobalContext)
    const [loading, setLoading] = useState(true)
    const { setCookie, getCookie } = useContext(UtilContext)
    const [businessVisitorCookie, setBusinessVisitorCookie] = useState(null)

    const getBusinessData = async (username) => {
        const endpoint = REACT_APP_API_URL + "?operation=READ_BUSINESS_BY_USERNAME&username=" + username
        //window.location.href = (endpoint)
        //return false
        //await new Promise((resolve) => setTimeout(resolve, 10000));
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                console.log(res)
                const data = JSON.stringify(res.data.data[0])
                //alert(data)
                setBusinessData((JSON.parse(data)))
                setLoading(false)
                //alert(businessData)
            }).catch((res) => {
                console.log(res)
                //alert("here")
            })
    }

    const getBusinessData2 = async (username) => {
        const endpoint = REACT_APP_API_URL + "?operation=READ_BUSINESS_BY_USERNAME&username=" + username
        //window.location.href = (endpoint)
        //return false

        return await axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                console.log(res)
                const data = JSON.stringify(res.data.data[0])
                return data
                //alert(data)
                //setBusinessData((JSON.parse(data)))
                //setLoading(false)
                //alert(businessData)
            }).catch((res) => {
                console.log(res)
                return null
                //alert("here")
            })
    }

    //let usernamex = getUrlId()
    //let datax = getBusinessData2(username)
    //alert(JSON.stringify(datax))

    useEffect(() => {
        let username = getUrlId()
        setUsername(username)
    }, [])

    useEffect(() => {
        if (username) {
            getBusinessData(username)
        }
        setLoading(false)
    }, [username])

    const logVisit = async (cookie) => {
        const data = new FormData()
        data.append('cookie_id', cookie)
        data.append('business_id', businessData?.business_id)
        data.append('webpage', 'business')
        data.append('client', navigator.userAgent)
        data.append('url_visited', window.location.href)

        let endpoint = REACT_APP_API_URL + "?operation=LOG_VISIT"
        endpoint += "&cookie_id=" + cookie
        endpoint += "&business_id=" + businessData?.business_id
        endpoint += "&webpage=" + 'business'
        endpoint += "&client=" + navigator.userAgent
        endpoint += "&url_visited=" + window.location

        //alert(endpoint)
        //window.location.href = endpoint
        //return false

        const res = await axios.post(endpoint, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((rsp) => {

        }).catch((rsp) => {
            let err = (rsp.response.data.data[0].error)
            console.log(rsp)
        })
    }

    const createCookie = () => {
        const cookie = getCookie("business-visitor")

        if (cookie === null) {
            let days = 1
            let cookieVal = new Date().getTime()
            let valueHash = sha256(cookieVal.toString())

            setCookie("business-visitor", valueHash, days)

            setBusinessVisitorCookie(getCookie("business-visitor"))
            logVisit(getCookie("business-visitor"))
            console.log(businessVisitorCookie)
        } else {
            setBusinessVisitorCookie(cookie)
            logVisit(getCookie("business-visitor"))
        }



    }

    useEffect(() => {
        if (businessData) {
            if (process.env.REACT_APP_ENV == 'DEV') {
                createCookie()
                //alert('boo')
            } else {
                if (user?.user_id === businessData?.owner_id) {
                    //do nothing
                    //alert('he')
                } else {
                    createCookie()
                    console.log(businessData)
                }
            }



        }

    }, [businessData])

    if (businessData !== null) {

    }


    return (
        <div>


            {loading === false && businessData !== null &&

                <BusinessProvider>
                    <HeaderLightSearch
                        bgColor={"bg-transparent"}
                        bgColorOnScroll={"bg-white"}
                        logoColor={"text-[#fff]"}
                        logoTextColor={"text-[#4b0d96]"}
                        logoTextColorOnScroll={"text-[#4b0d96]"}
                        logoColorOnScroll={"text-[#fff]"}
                        logoBgColor={"bg-[#4b0d96]"}
                        logoBgColorOnScroll={"bg-[#4b0d96]"}
                        color={"text-[#fff]"}
                        colorBorder={"border-b-[1px] border-b-black"}
                        colorOnScroll={"text-black"}
                        borderOnScroll={"border-b-[1px] border-black"}
                        borderColorOnHover={"border-b-[1px] border-b-black"}
                    />

                    <HeaderSpace />


                    <SubNav />
                    <H16 />
                    <TopBanner
                        dataAdSlot={'3148685431'}
                        dataAdFormat={'auto'}
                        dataFullWidthResponsive={true}
                    />
                    <H32 />

                    <PageStructure
                        business={businessData}
                    />
                    <HeaderSpace />
                    <Prefooter />
                    <Footer />
                </BusinessProvider>

            }

            {
                loading === false && businessData === null && <Error404 />
            }

        </div>
    )
}

export default Interceptor
