import React, { useContext, useEffect, useRef } from 'react'
import { MenuIcon } from '@heroicons/react/outline'
import { GlobalContext, GlobalProvider, GlobalSetterContext, useGlobal } from '../../context/GlobalContext'
import MobileMenu from '../header/MobileMenu'


const Hamburger = ({ isScrolling, color, colorOnScroll }) => {

    let { horizontalNav, toggler, mobileMenu } = useContext(GlobalContext)
    let { setHorizontalNav, setToggler, setMobileMenu } = useContext(GlobalSetterContext)

    //setHorizontalNav(horizontalNav)

    //console.log(horizontalNav)

    const toggleMenu = (e) => {
        e.preventDefault()
        setToggler(!toggler)
    }

    useRef(() => {

    })

    return (
        <div>
            <button
                onBlur={async (e) => {
                    await new Promise((resolve) => setTimeout(resolve, 200));
                    toggleMenu(e)
                }}
            >
                <MenuIcon className={`h-6 w-7 ${isScrolling ? colorOnScroll : color}`} onClick={(e) => toggleMenu(e)} />
            </button>
            <MobileMenu />
        </div>
    )
}

export default Hamburger
