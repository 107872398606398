import React, { useEffect, useState } from 'react'
import { BsArrowReturnRight } from 'react-icons/bs'
import { NavLink } from 'react-router-dom'

function HowWeCanHelp({ ourhelp }) {

    const defaulData = [
        {
            id: 1,
            title: "M&A Strategy"
        },
        {
            id: 2,
            title: "M&A Capability"
        },
        {
            id: 3,
            title: "M&A Due Diligence"
        },
        {
            id: 4,
            title: "Post-Merger Integration"
        },
    ]

    const [ourhelpMap, setOurhelpMap] = useState(defaulData)

    useEffect(() => {
        if (ourhelp) {
            setOurhelpMap(ourhelp)
        }
    }, [ourhelp])

    return (
        <div>
            <div className=' px-5'>
                <div className=' max-w-[1200px] mx-auto w-full'>
                    <div className=' grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5 '>
                        {
                            ourhelpMap.map((item) => {
                                return (
                                    <div key={item.id}>
                                        <div className=' grid grid-cols-1  
                                        border-[1px] border-gray-400/70 px-6 py-7 h-full border-t-[3px]
                                        hover:cursor-pointer hover:bg-red-300/20 space-y-5 place-content-between
                                        '>
                                            <div className=' text-3xl font-semibold'>
                                                {item.title}
                                            </div>
                                            <div className=' h-auto'>
                                                {item.description}                                            </div>
                                            <div>
                                                <NavLink>
                                                    <div className=' text-md flex'>

                                                        <BsArrowReturnRight size={20} />
                                                        <span className=' relative top-[-2px] left-2'>Go</span>


                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowWeCanHelp
