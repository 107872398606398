import React from 'react'
import { z } from 'zod'

const passwordValidation = new RegExp(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
);

const LoginSchema = z.object({
    password: z.string()
        .min(8, { message: "Password must not be less than 8 characters" })
        .regex(passwordValidation, { message: "Your password must be alphanumeric with at least one capital letter. E.g. Abcd1273%^&*(" }),
    email: z.string()
        .email({ message: "Please Enter a valid email." })

})

export default LoginSchema
