import React from 'react'

function H32() {
    return (
        <div>
            <div className=' h-[32px]'></div>
        </div>
    )
}

export default H32
