import React, { useState } from 'react'


const jsondata = [
    {
        time: "Closed"
    },
    {
        time: "08:00 am"
    },
    {
        time: "08:30 am"
    },
    {
        time: "09:00 am"
    },
    {
        time: "09:30 am"
    },
    {
        time: "10:30 am"
    },
    {
        time: "10:30 am"
    },
    {
        time: "11:00 am"
    },
    {
        time: "11:30 am"
    },
    {
        time: "12:00 pm"
    },
    {
        time: "12.30 pm"
    },
    {
        time: "13:00 pm"
    },
    {
        time: "13:30 pm"
    },
    {
        time: "14:00 pm"
    },
    {
        time: "14:30 pm"
    },
    {
        time: "15:00 pm"
    },
    {
        time: "15:30 pm"
    },
    {
        time: "16:00 pm"
    },
    {
        time: "16:30 pm"
    },
    {
        time: "17:00 pm"
    },
    {
        time: "17:30 pm"
    },
    {
        time: "18:00 pm"
    },
]
const TimeDropDown = ({ register, changeHandler, controlName, labelTop, labelBottom, placeholder, error }) => {
    const [value, setValue] = useState('')




    return (
        <div>
            <div className='flex flex-col w-[100%] mb-0 '>
                <label className='font-bold mb-[5px]' htmlFor="title">{labelTop}</label>

                <select id=""
                    {...register(controlName, {
                        onChange: changeHandler
                    })}
                    className='border-[1px] outline-none 
                    border-gray-500/40 bg-gray-500/5 
                    shadow-md rounded-md px-[10px] 
                    py-[10px] text-[15px]
                     w-full'
                    placeholder='Select Time'

                >
                    <option value="" Selected>Select Time</option>
                    {jsondata.map((item) => {
                        return (
                            <option value={item.time} Selected>{item.time}</option>
                        )
                    })}

                </select>



                {error && (
                    <div className=' text-red-400 mt-[5px] text-[15px]'>
                        <span className=' text-red-500 font-bold'>{error.message}
                        </span>
                    </div>)}

                <label className='font-normal text-[14px] mt-[8px] leading-[18px]'>
                    {labelBottom}
                </label>
            </div>
        </div>

    )
}
export default TimeDropDown
