import { StarIcon } from "@heroicons/react/outline";
import { createContext } from "react";
import { BiSolidStar, BiStar } from "react-icons/bi";

const UtilContext = createContext()

const UtilProvider = ({ children }) => {

    const getUrlId = () => {
        const url = window.location.href;
        let lastUrlSegmentWithSlash = url.substring(url.lastIndexOf("/"));
        let lastUrlSegment = lastUrlSegmentWithSlash.split("/")
        const item_id = lastUrlSegment[1];
        return (item_id)

    }

    const getSubstr = (str, len) => {
        if (str) {
            if (str.length > len) {
                return str.substring(0, len - 3) + '...'
            } else {
                return str
            }
        } else {
            return str
        }
    }

    function computeRating(totalx) {
        return Math.round(totalx);
    }

    const getStars = (rating) => {
        let i = 0;
        let actualRating = computeRating(rating);
        let stars = [];
        for (i = 1; i <= 5; i++) {
            if (i <= actualRating) {
                stars.push(<BiSolidStar className=' text-yellow-400' />)
            } else {
                stars.push(<BiStar className=' text-gray-400' />)
            }
        }
        return stars;
    }

    const setCookie = (name, value, days) => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + days);

        document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
    };

    const getCookie = (name) => {
        const cookies = document.cookie
            .split("; ")
            .find((row) => row.startsWith(`${name}=`));

        return cookies ? cookies.split("=")[1] : null;
    };

    const deleteCookie = (name) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    };

    function shortenNumbers(value) {
        if (value >= 1000000000) {
            value = (value / 1000000000).toFixed(1) + "B"
        }

        if (value >= 1000000) {
            value = (value / 1000000).toFixed(1) + "M"
        }
        else if (value >= 1000) {
            value = (value / 1000).toFixed(1) + "K";
        }

        return value;
    }

    function getHostPortProtocol(window) {
        let host = null
        let protocol = null
        let port = null

        if (window) {
            host = window.location.hostname
            protocol = window.location.protocol
            port = window.location.port

            if (host && protocol && port) {
                if (port === 80) {
                    return protocol + "//" + host
                }
                return protocol + "//" + host + ":" + port
            }
        }
        return null
    }

    const value = {
        getUrlId,
        getSubstr,
        getStars,
        setCookie,
        getCookie,
        deleteCookie,
        shortenNumbers,
        getHostPortProtocol
    }

    return (
        <UtilContext.Provider value={value}>
            {children}
        </UtilContext.Provider>
    )
}

export { UtilContext, UtilProvider }