import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import { StarIcon } from '@heroicons/react/outline';
import ActivitySideInfo from '../../components/body/ActivitySideInfo';
import SearchGrid from './SearchGrid';
import { GlobalContext, GlobalSetterContext } from '../../context/GlobalContext';
import SearchResultGrid from '../search/SearchResultGrid';
import Featured from '../../components/body/Featured';
import SubNavFlex from '../../components/body/subnavflex/SubNavFlex';
import { FaCarSide } from 'react-icons/fa';
import { MdFlight, MdPrecisionManufacturing, MdRealEstateAgent } from 'react-icons/md';
import { GiScales } from 'react-icons/gi';
import { BiMusic, BiRestaurant } from 'react-icons/bi';
import { AiFillInsurance } from 'react-icons/ai';
import VerticalBanner from '../../components/ads/VerticalBanner';
import H32 from '../../components/body/H32';
import { CgSpinner } from 'react-icons/cg';

const navlnk = [
    {
        title: "Automotive",
        icon: <FaCarSide size={18} />,
        link: "/search?what=auto&where="
    },
    {
        title: "Manufacturing",
        icon: <MdPrecisionManufacturing size={18} />,
        link: "/search?what=manufacturing&where="
    },
    {
        title: "Travels",
        icon: <MdFlight size={18} />,
        link: "/search?what=travel&where="
    },
    {
        title: "Legal Services",
        icon: <GiScales size={18} />,
        link: "/search?what=law&where="
    },
    {
        title: "Restaurant",
        icon: <BiRestaurant size={18} />,
        link: "/search?what=restaurant&where="
    },
    {
        title: "Finance",
        icon: <AiFillInsurance size={15} />,
        link: "/search?what=finance&where="
    },

]

const navlnk2 = [
    {
        title: "Travels",
        icon: <MdFlight size={18} />,
        link: "/search?what=travel&where="
    },
    {
        title: "Restaurant",
        icon: <BiRestaurant size={18} />,
        link: "/search?what=restaurant&where="
    },
    {
        title: "Finance",
        icon: <AiFillInsurance size={15} />,
        link: "/search?what=finance&where="
    },

]

function SearchResults() {
    const [searchdata, setSearchdata] = useState([])
    const { REACT_APP_API_URL, isAdsense } = useContext(GlobalContext)
    const { setAdsense } = useContext(GlobalSetterContext)
    const [srcData, setSrcData] = useState(null)
    const [what, setWhat] = useState(undefined)
    const [where, setWhere] = useState(undefined)
    const [params] = useSearchParams()
    const [isLoaded, setIsLoaded] = useState(false)

    const getBusinessesByParams = async (what, where) => {

        let endpoint = REACT_APP_API_URL + '?operation=SEARCH_BUSINESSES_BY_PARAMS'
        endpoint += "&what=" + what
        endpoint += "&where=" + where

        //window.location.href = endpoint
        //return false;

        let response = null

        try {
            //await new Promise((resolve) => setTimeout(resolve, 10000));
            response = await fetch(
                endpoint,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }

                }
            );
            const result = await response.json();
            //console.log(result.data)
            setSrcData(result.data)
        } catch (e) {
            //alert("Error: " + e)
        }


    }

    function setSearchParamData() {
        let xwhat = params.get('what')
        let xwhere = params.get('where')

        if (xwhat == 'undefined' || xwhat == '' || xwhat == null) {
            xwhat = ''
        }
        if (xwhere == 'undefined' || xwhere == '' || xwhere == null) {
            xwhere = ''
        }
        setWhat(xwhat)
        setWhere(xwhere)

    }

    useEffect(() => {
        setIsLoaded(true)
    }, [setIsLoaded])

    useEffect(() => {
        setSearchParamData()
    }, [setSearchParamData])

    useEffect(() => {
        if (isLoaded) {
            getBusinessesByParams(what, where)
        }
    }, [isLoaded])

    let [destinations, setDestinations] = useState([]);





    return (
        <>
            <div>
                <div className=' max-w-[1200px] mx-auto w-full '>
                    <div className=' flex place-content-between place-items-center  mb-1 mt-3 border-b-[1px] border-t-[1px] border-gray-500/20'>
                        <div className='text-[21px]  font-black'>
                            Latest Businesses
                        </div>
                        <div className='hidden w-fit truncate gap-3  px-2 lg:flex  place-content-end place-items-center'>
                            {
                                navlnk.map((item) => {
                                    return (
                                        <div>
                                            <div className='underline'>
                                                <a href={item?.link}>
                                                    <div>
                                                        {item?.title}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className=' lg:hidden w-[50%] truncate gap-3 overflow-hidden h-[40px] flex place-content-end place-items-center'>
                            {
                                navlnk2.map((item) => {
                                    return (
                                        <div>
                                            <div className='underline'>
                                                <a href={item?.link}>
                                                    <div>
                                                        {item?.title}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className=' grid grid-cols-1 lg:grid-cols-12 
                    md:gap-x-5 mx-auto w-full     
                    '>
                        <div className='lg:col-span-8  lg:mr-[-50px] '>
                            <div className=' '>
                                {
                                    srcData !== null ?
                                        <div>
                                            <SearchResultGrid data={srcData} />
                                        </div>
                                        : <div className=' flex items-center'>
                                            <CgSpinner size={20} className=" animate-spin" />
                                            Working...
                                        </div>
                                }
                            </div>

                        </div>

                        <div className='lg:col-span-4 lg:ml-[60px]'>
                            <div className=' h-[10px]'></div>
                            <Featured />
                            <H32 />
                            <div className=' lg:sticky lg:top-[100px]'>
                                <VerticalBanner
                                    dataAdSlot={'3148685431'}
                                    dataAdFormat={'auto'}
                                    dataFullWidthResponsive={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchResults
