import { MapIcon, PhoneIcon } from '@heroicons/react/outline'
import React, { useContext } from 'react'
import { BiCopy, BiLocationPlus, BiSolidLocationPlus } from 'react-icons/bi'
import { BsStar, BsStarFill } from 'react-icons/bs'
import { CgWebsite } from 'react-icons/cg'
import { PiPhoneCall, PiPhoneCallFill } from 'react-icons/pi'
import { SiInternetexplorer, SiWebmoney } from 'react-icons/si'
import { BusinessContext } from './BusinessContext'
import ClipBoardCopy from './ClipBoardCopy'
import CopyAllOutlined from '@mui/icons-material/CopyAllOutlined'
import { CopyAll, CopyAllRounded, CopyAllSharp } from '@mui/icons-material'

const LeftTop = () => {
    const { businessData } = useContext(BusinessContext)

    return (
        <div>
            <div className=' grid-cols-1 divide-y-[1px] divide-gray-500/30 border-[1px] border-gray-500/30 rounded-[4px] px-[18px] hidden lg:block py-2  shadow-lg'>
                <div className=' w-full'>
                    <a href={`tel:+${businessData?.country_code} ${businessData?.mobile}`} className=' inline-block w-full '>
                        <div className=' flex place-items-center place-content-start space-x-2 py-3 w-full '>
                            <div className=''>
                                <PiPhoneCallFill className='text-[25px]  md:text-[21px]' />
                            </div>
                            <div className=' font-bold tracking-tighter text-[20px] '>
                                {businessData?.mobile}
                            </div>

                        </div>
                    </a>
                </div>

                <div className=' flex place-items-center place-content-start space-x-2 py-4'>
                    <div className=''>
                        <SiWebmoney className=' text-[21px] text-black-600' />
                    </div>
                    <div className=' text-[17px] font-bold w-full '>
                        <a href={businessData?.website}
                            className=' text-black w-full inline-block text-[17px]'
                        >
                            Visit Website
                        </a>
                    </div>
                </div>

                <div className=' flex place-items-center place-content-start space-x-2 py-4'>
                    <div className=' '>
                        <BiSolidLocationPlus className=' text-[25px]' />
                    </div>
                    <div className='  w-full'>
                        <a href={`https://www.google.com/maps/search/${businessData?.address_one}, ${businessData?.address_two}`}
                            className='text-[17px]   inline-block w-full hover:text-gray-400'
                        >
                            <ul className=' space-y-[-4px] inline-block w-full'>
                                <li>
                                    <div className=' text-black font-bold inline-block w-full text-[17px] '>
                                        View in map
                                    </div>
                                </li>
                                <li className=' text-[13px]'>
                                    {businessData?.address_one}
                                </li>
                                <li className=' text-[13px]'>
                                    {businessData?.address_two}
                                </li>
                            </ul>
                        </a>

                    </div>
                </div>

                <div className=' flex place-items-center place-content-start space-x-2 py-4'>
                    <div className=''>
                        <BiCopy className=' text-[21px] text-cyan-800' />
                    </div>
                    <div className=' text-[17px] font-bold '>

                        <ClipBoardCopy />
                    </div>
                </div>

            </div>
        </div >
    )
}

export default LeftTop
