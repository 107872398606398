import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css";
import { GlobalContext } from '../../context/GlobalContext';

const images = [
    {
        original: "https://picsum.photos/id/1018/1000/600/",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
        original: "https://picsum.photos/id/1015/1000/600/",
        thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
        original: "https://picsum.photos/id/1019/1000/600/",
        thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
    {
        original: "https://picsum.photos/id/1018/1000/600/",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
        original: "https://picsum.photos/id/1015/1000/600/",
        thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
        original: "https://picsum.photos/id/1019/1000/600/",
        thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
];

const BusinessGallery = ({ imagex }) => {
    //alert(imagex)
    const [imgs, setImgs] = useState(null)
    const {
        REACT_APP_API_BUSINESS_GALLERY_IMG_URI
    } = useContext(GlobalContext)
    const [imgO, setImgO] = useState(null)
    const [reconstructedImgJson, setReconstructedImgJson] = useState([])

    let mapper = []
    let parsedImg = JSON.parse(imagex)
    parsedImg.map((item) => {
        let mapItem = {}
        mapItem.original = REACT_APP_API_BUSINESS_GALLERY_IMG_URI + item.image_filename
        mapItem.thumbnail = REACT_APP_API_BUSINESS_GALLERY_IMG_URI + item.image_filename
        mapper.push(mapItem)
    })


    let galleryjson = JSON.stringify(mapper)
    //setReconstructedImgJson(mapper)
    //console.log(stringified)
    console.log(galleryjson)





    const refImg = useRef(null)
    const renderCustomControls = () => <span>refImg.current.getCurrentIndex()</span>
    const onClickHandler = () => {
        refImg.current.fullScreen();
        document.getElementsByClassName("image-gallery-slide-wrapper")[0].style.display = 'block'
    };
    const onScreenChangeHandler = () => {
        document.getElementsByClassName("image-gallery-slide-wrapper")[0].style.display = 'none'
    }








    return (
        <div>
            <style>
                {`
                .fullscreen-modal{
                    z-index: 10000 !important;
                }
                .image-gallery-thumbnails-wrapper{
                width:100%;
                }
                .image-gallery-slide-wrapper{
                    display: none;
                    
                }
                .image-gallery-thumbnail{
                    width: 150px;
                    
                }
                .image-gallery-thumbnail-inner img{
                    object-fit: cover
                }
                .image-gallery-thumbnail-image{
                    height: 140px;
                }
                .image-gallery-thumbnails-container{
                    position:relative;
                    float:left;
                }
                `}
            </style>
            <div className=' flex  place-content-start place-items-center gap-2 border-t-[3px] border-gray-200 pt-3'>
                <div className=' w-fit font-black text-[19px] tracking-tighter flex place-items-center '>
                    Photo Gallery
                </div>
                <div
                    onClick={() => { onClickHandler() }}
                    className=' w-fit text-[12px] hover:cursor-pointer tracking-tighter flex place-items-center underline'>
                    {JSON.parse(imagex).length} PICTURE
                    {JSON.parse(imagex).length === 1 ? '' : 'S'}
                </div>
            </div>

            <div className=''>
                {mapper &&

                    <ReactImageGallery

                        items={mapper}
                        useBrowserFullscreen={false}
                        onThumbnailClick={() => {
                            onClickHandler()

                        }}
                        ref={refImg}
                        onScreenChange={() => {
                            onScreenChangeHandler()
                        }}

                        showNav={true}

                    />}
            </div>


        </div>
    )
}

export default BusinessGallery
