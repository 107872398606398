import React from 'react'
import { BsBank2 } from 'react-icons/bs'

const Founded = ({ businessData }) => {
    const calculateYear = (year) => {
        let yr = year * 1
        let noOfYears = new Date().getFullYear() - yr
        return noOfYears
    }
    return (
        <div>
            <div className=' flex place-content-start place-items-center gap-2'>
                <div>
                    <BsBank2 className=' text-[20px]' />
                </div>

                <div className=' flex flex-col space-y-[-5px] text-[11px]'>
                    <div>
                        Established
                    </div>
                    <div>
                        <b>{calculateYear(businessData?.established)} Years</b> ago
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Founded
