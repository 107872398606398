import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { Rating } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import StarIconOutline from '@mui/icons-material/StarBorderOutlined'
import H32 from '../../components/body/H32'

const RelatedBusinesses = ({ category }) => {

    const [businessData, setBusinessData] = useState(null)
    const [loading, setLoading] = useState(true)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const [cat, setCategory] = useState("")

    const getBusinessData = (category) => {

        const endpoint = REACT_APP_API_URL + "?operation=READ_BUSINESSES_BY_CATEGORY&category=" + category
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {

                console.log(res)
                const data = JSON.stringify(res.data.data)
                console.log(data)
                setBusinessData(JSON.parse(data))
                setLoading(false)
                //alert(businessData)
            })
            .catch((res) => {

                console.log(res)
            })
    }

    useEffect(() => {
        if (category !== "") {

            getBusinessData(category)


        }
    }, [])

    useEffect(() => {
        if (businessData !== null) {
            console.log(businessData)
            console.log("A")
        }
    }, [businessData])
    return (
        <div>
            <div className='font-bold text-[21px] tracking-tighter'>
                Related
            </div>
            <H32 />
            <div className=' grid grid-cols-1 md:grid-cols-2 gap-3'>
                {
                    (businessData !== null) && businessData?.map((item) => {
                        return (
                            <div className=' mb-6 '>
                                <div className='  space-x-1 relative'>
                                    <a href={`/business/${item?.business_id}`} className=' w-full inline-block'>
                                        <span className='text-blue-700 font-black'>
                                            {item?.title}
                                        </span>
                                    </a>
                                    <span>
                                        ({item?.rating_count})
                                    </span>
                                </div>
                                <div>
                                    <Rating
                                        name="hover-feedback"
                                        value={item?.overall_rating}
                                        precision={1}
                                    />
                                </div>
                                <div className=' mt-[-10px] text-[14px]'>
                                    {item?.address_one}, {item?.address_two}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default RelatedBusinesses
