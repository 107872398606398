import React from 'react'

function HeaderSpace() {
    return (
        <div>
            <div className=' h-[70px]
            flex place-content-center place-items-center
            '>
            </div>
        </div>
    )
}

export default HeaderSpace
