import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { StarIcon } from '@heroicons/react/outline';
import ActivitySideInfo from './ActivitySideInfo';



function Products() {
    const cardjson = [
        {
            id: 1,
            title: "Generative AI For The Rest Of US: Your Future, Decoded",
            category: "Historical Tours",
            price: 29.99,
            currency: "USD",
            symbol: "$",
            tour_duration: "2 hours",
            quantity: "per adult",
            description: "The primary purpose of this book is to provide the average person with a comprehensive, 360-degree view of the Generative AI landscape.",
            terms: "free cancellation",
            img: "https://m.media-amazon.com/images/I/41fOFPXPqeL.jpg",
            link: "https://amzn.to/45cPP1k",
            recommendation: "Recommended by 98% of buyers",
            rating: 5,
        },
        {
            id: 2,
            title: "Artificial Intelligence For Dummies (For Dummies (Computer/Tech))",
            category: "Business Tours",
            price: 22.33,
            currency: "USD",
            symbol: "$",
            tour_duration: "2 hours",
            quantity: "per adult",
            description: "Every time you use a smart device or some sort of slick technology―be it a smartwatch, smart speaker, security alarm, or even customer service chat box...",
            terms: "free cancellation",
            img: "https://betanews.com/wp-content/uploads/2024/05/Artificial-Intelligence-For-Dummies.jpg",
            link: "https://amzn.to/3XsbbWP",
            recommendation: "Recommended by 98% of buyers",
            rating: 4.1,
        },
        {
            id: 3,
            title: "The Basics of Bitcoins and Blockchains: An Introduction to Cryptocurrencies and the Technology that Powers Them",
            category: "Gaming Tours",
            price: 11.76,
            currency: "USD",
            symbol: "$",
            tour_duration: "2 hours",
            quantity: "per adult",
            description: "There’s a lot written on cryptocurrency and blockchains. But, for the uninitiated, most of this information can be indecipherable.",
            terms: "free cancellation",
            img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1616973380i/57557658.jpg",
            link: "https://amzn.to/457TJsl",
            recommendation: "Recommended by 98% of buyers",
            rating: 4.5,
        },
        {
            id: 4,
            title: "Mastering Cryptocurrency Trading: A comprehensive guide to financial success",
            category: "Architectural Tours",
            price: 35.95,
            currency: "USD",
            symbol: "$",
            tour_duration: "2 hours",
            quantity: "per adult",
            description: "Mastering Cryptocurrency Trading is your go- to guide for diving into the world of trading digital currencies",
            terms: "free cancellation",
            img: "https://m.media-amazon.com/images/I/71dS+JbmMPL._AC_UF1000,1000_QL80_.jpg",
            link: "https://amzn.to/3Vdl5Zg",
            recommendation: "Recommended by 98% of buyers",
            rating: 4.5,
        },
        {
            id: 5,
            title: "The Ultimate Guide to Cryptocurrency: Navigating the World of Digital Assets ",
            category: "Rental Tours",
            price: 14.99,
            currency: "USD",
            symbol: "$",
            tour_duration: "2 hours",
            quantity: "per adult",
            description: `Introducing "The Ultimate Guide to Cryptocurrency: Navigating the World of Digital Assets" – your comprehensive roadmap to understanding and mastering the captivating realm of cryptocurrencies.`,
            terms: "free cancellation",
            img: "https://m.media-amazon.com/images/I/41M1-I1bKvL._AC_SF480,480_.jpg",
            link: "https://amzn.to/4c5SMCX",
            recommendation: "Recommended by 98% of buyers",
            rating: 4.8,
        },
        {
            id: 6,
            title: "The Only Altcoin Investing Book You'll Ever Need: Your Definitive Manual for Profiting from the Next Crypto Bull Run",
            category: "Boating Scenes",
            price: 15.75,
            currency: "USD",
            symbol: "$",
            tour_duration: "2 hours",
            quantity: "per adult",
            description: "The crypto market is still growing faster than the internet was in 1997...And with altcoins emerging as the stars of the next decade... this book is your essential guide to navigating the dynamic world of alternative cryptocurrencies.",
            terms: "free cancellation",
            img: "https://i1.sndcdn.com/artworks-zx3NoBdxiu7n9lvM-U4C5MQ-t500x500.jpg",
            link: "https://amzn.to/3Vux5Hm",
            recommendation: "Recommended by 98% of buyers",
            rating: 4.6,
        },
    ]

    let [destinations, setDestinations] = useState([]);

    function getLink(lnk) {
        return "/item/" + lnk
    }

    function computeRating(totalx) {
        return Math.round(totalx);
    }

    function getStars(rating) {
        let i = 0;
        let limit = computeRating(rating);
        let stars = [];
        for (i = 0; i < limit; i++) {
            stars.push(<StarIcon key={i} className=' h-4 text-red-400' />)
        }
        return stars;
    }

    function getSubstring(str) {
        const maxLen = 100;
        let finalString = "";
        if (str.length > maxLen) {
            finalString += str.substring(0, maxLen) + "...";
        } else {
            finalString = str;
        }
        return finalString;
    }

    return (
        <>
            <div>
                <div className=' max-w-[1200px] mx-auto w-full'>
                    <div className=' grid grid-cols-1 max-w-[800px] mx-auto w-full
                         divide-y
                    '>
                        {
                            cardjson.map((item) => {
                                return (
                                    <div key={item.id} className=' hover:bg-slate-100'>
                                        <NavLink to={item.link}>
                                            <div className=' grid grid-cols-12 gap-4 py-3 '>
                                                <div className='  col-span-4 sm:col-span-3 relative h-[180px] md:h-[280px] w-auto  flex place-content-center border-[1px] border-gray-300 px-3 ml-5'>
                                                    <img src={item.img} alt="" className=' object-scale-down ' />
                                                </div>
                                                <div className=' col-span-8 sm:col-span-9'>
                                                    <div className=' font-bold text-gray-600 text-[22px] tracking-tight leading-tight pr-3'>
                                                        {item.title}
                                                    </div>
                                                    <div className=' text-[15px] mt-2 leading-tight font-sans pr-3'>
                                                        {item.description}
                                                    </div>
                                                    <div className=' text-[25px] font-black tracking-tighter mt-3 '>
                                                        <span>{item.symbol}</span><span>{(item.price).toFixed(2)}</span>
                                                    </div>
                                                    <div className=' flex mt-4'>
                                                        {getStars(item.rating)}
                                                        <div className=' relative top-[-2px] ml-2 mr-[-3px] h-[20px] border-r-[1px] border-gray-400'></div>
                                                        <span className=' text-[15px] font-bold relative top-[-3.5px] text-gray-500 ml-2'>{item.rating}</span>
                                                    </div>
                                                    <div className=' text-sm'>
                                                        Recommended by {(((item.rating * 1) / 5) * 100).toFixed(0)}% of buyers
                                                    </div>
                                                    <div>

                                                        <button
                                                            onClick={(() => {
                                                                window.location.href = item.link
                                                            })}
                                                            className=' bg-red-700 text-white px-5 pt-[1px] pb-[4px] rounded-full mt-2 ml-[-3px] hover:bg-blue-700'>On Amazon</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Products
