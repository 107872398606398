import React, { useContext, useEffect } from "react";
import Header from "./components/header/Header";
import Hero from "./components/body/Hero";
import { Routes, Route } from "react-router-dom";
import { GlobalContext, GlobalSetterContext, useGlobal } from "./context/GlobalContext";
import AlertContext from "./context/AlertContext";
import ProtectedRoute from "./context/ProtectedRoute";
import PublicRoute from './context/PublicRoute'
import Business from "./pages/business/Business";
import Error404 from "./pages/404/error404";
import Interceptor from "./pages/interceptor/Interceptor";
import ProgressBar from '@badrap/bar-of-progress'
import { Router } from "react-router-dom";

const progress = new ProgressBar({
  size: 4,
  color: "#FE595E",
  className: "z-50",
  delay: 100
})
progress.start()
setTimeout(() => {
  progress.finish()
}, 1000)

function App({ Component, pageProps }) {

  const {
    globalNav,
    toggler,
    user,
    featuresLinks,
    publicRoutes,
    protectedAccount,
    horizontalNav,
  } = useContext(GlobalContext)


  const {
    setGlobalNav,
    setToggler,
    setHorizontalNav
  } = useContext(GlobalSetterContext);

  const { Alert } = useContext(AlertContext)

  useEffect(() => {
    console.log("30")
    console.log(JSON.stringify(publicRoutes))
  }, [publicRoutes])

  return (
    <div>
      <Alert />
      <Routes>
        {/* {
          horizontalNav.map((item) => {
            return <Route key={item.link} path={item.link + "/*"} element={item.page} />
          })
        } */}

        <Route path='/account' element={<ProtectedRoute user={user} />}>

          {
            protectedAccount.map((item) => {
              return (
                <Route
                  key={item.link}
                  path={item.link + ""}
                  element={item.page}
                />
              )

            })
          }
        </Route>

        <Route path='/' element={<PublicRoute />}>
          {
            publicRoutes.map((item) => {
              return (
                <Route
                  key={item.link}
                  path={item.link + ""}
                  element={item.page}
                />
              )

            })
          }

          <Route path='/error' exact={true} element={<Error404 />} />
        </Route>


      </Routes>

      {/* <Interceptor /> */}
      {/* <Error404 /> */}

    </div>
  );
}

export default App;
