import React, { useState, useEffect, useRef } from 'react'
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDot, RxDotFilled } from 'react-icons/rx';
import { ArrowRightIcon } from '@heroicons/react/outline';


const featuredProducts = [
    {
        //url: "https://authentictheme.com/vault/wp-content/uploads/sites/19/2017/10/authentic-demo-image-00033-1600x2416.jpg",
        url: "img/rev2.jpg",
        category: "Enterprise Technology",
        title: "Blockchain Brings Intelligence to Payment Systems.",
    },
    {
        url: "img/slide11.jpeg",
        category: "Program Interfaces",
        title: "Let APIs open up opportunities lurking in your data.",
    },
    {
        url: "https://goahead.axiomthemes.com/wp-content/uploads/2024/02/post31-copyright.jpg",
        category: "Outsourcing",
        title: "The power of Outsourcing & Third Party Knowledge.",
    },
    {
        url: "img/aix3.jpg",
        category: "Artificial Intelligence",
        title: "Soar your business with embedded Artificial Intelligence.",
    }
]

let count = 0;
//let slideInterval;

function SlideCarousel3() {
    const [currentIndex, setCurrentIndex] = useState(0)
    let [slideInterval, setSlideInterval] = useState(null)

    const slideRef = useRef()

    const removeAnimation = () => {
        slideRef.current.classList.remove("fade-anim")
    }



    const startSlider = () => {
        slideInterval = setInterval(() => {
            handleNextClick()
        }, 10000)
        setSlideInterval(slideInterval)
    }

    const pauseSlider = () => {
        let clearedInterval = clearInterval(slideInterval);
        setSlideInterval(clearedInterval)
    }

    useEffect(() => {
        //slideRef.current.addEventListener("animationend", removeAnimation)
        slideRef.current.addEventListener("mouseenter", pauseSlider)
        slideRef.current.addEventListener("mouseleave", startSlider)
        startSlider()

        return () => {
            pauseSlider();
        }
    }, [])

    const handlePrevClick = () => {
        count = (currentIndex + featuredProducts.length - 1) % featuredProducts.length
        setCurrentIndex(count)
        //slideRef.current.classList.add("fade-anim")
    }

    const handleNextClick = () => {
        count = (count + 1) % featuredProducts.length
        setCurrentIndex(count)
        //slideRef.current.classList.add("fade-anim")
    }


    const handleGoToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex)
        //slideRef.current.classList.add('fade-anim')
    }


    return (



        <div>
            <style>
                {`
                
                    .fade-anim img#im {
                        //animation: fadeAnim 0.3s linear;
                        
                    }

                    .fade-anim img#im {
                        //animation: slide 100s linear;
                        
                    }


                    @keyframes fadeAnim {
                        from {
                            opacity: 1;
                        }
                        to {
                            opacity: 85%;
                        }
                    }

                    @keyframes slide {
                        100% {
                            transform: scale(0%);
                            
                        }

                        100% {
                            transform: scale(180%, 180%);
                            
                            /* The image width */
                        }
                    }
                `}
            </style>




            <div className='relative h-screen overflow-hidden'>
                <div ref={slideRef} className=' w-full select-none group '>

                    <div className=' z-50 group-hover:bg-white/40 absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl p-2 bg-white/20 text-white cursor-pointer rounded-full'>
                        <BsChevronCompactLeft onClick={handlePrevClick} size={30} />
                    </div>
                    <div className=' z-50 group-hover:bg-white/40 absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl p-2 bg-white/20 text-white cursor-pointer rounded-full'>
                        <BsChevronCompactRight onClick={handleNextClick} size={30} />
                    </div>

                    <div className=' w-full absolute h-screen flex flex-row px-5 z-[2] '>
                        <div className=' max-w-[1200px] mx-auto w-full flex flex-row items-center'>
                            <div className='w-[90%] md:w-[60%] lg:w-[50%] space-y-5  '>
                                <div className='text-lg text-white font-semibold text-left'>
                                    {featuredProducts[currentIndex].category}
                                </div>
                                <div className=' text-[38px] lg:text-5xl font-black text-white leading-[100%] text-left tracking-wide '>
                                    {featuredProducts[currentIndex].title}
                                </div>
                                <div className=' z-[3] text-white font-semibold flex space-x-2 hover:cursor-pointer hover:translate-x-6 transition duration-500'>
                                    <span className='text-lg'>Read More</span>
                                    <span className=''>
                                        <ArrowRightIcon className=' h-8 w-[50px] ' />
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <img id='im' src={featuredProducts[currentIndex].url} alt="" className=' w-full h-screen bg-cover object-cover z-[0]' />

                    <div className='absolute top-0 w-full h-[200px] bg-gradient-to-b from-black/55 to-transparent z-[0]'></div>
                    <div className='absolute bottom-0 w-full h-[20%] bg-gradient-to-t from-black/95 to-transparent z-[0]'></div>
                    <div className='absolute bottom-0 w-[100%] md:w-[50%] h-[100%] bg-gradient-to-r from-black/85 to-transparent z-[0]'></div>


                    <div className=' mx-5 flex place-content-center'>
                        {/* <div className=' px-5 flex justify-between max-w-[1200px] mx-auto py-2 space-x-2 absolute w-full bottom-[10px] z-[5] '>
                            {
                                featuredProducts.map((slide, slideIndex) => {
                                    return (
                                        <div
                                            key={slideIndex}
                                            onClick={() => {
                                                setTimeout(function () {
                                                    handleGoToSlide(slideIndex)
                                                }, 200)
                                            }}

                                            className={` ${slideIndex === currentIndex ? 'text-white border-t-[8px] border-red-500 relative bottom-[8px]' : ' text-white/50 '} text-sm lg:text-normal cursor-pointer 
                                     px-0 pt-8 pb-3 font-bold align-baseline text-center  h-[100px]
                                    `}
                                        >

                                            {slide.category}

                                        </div>
                                    )
                                })
                            }
                        </div> */}

                        <div className='flex justify-center py-2 space-x-2 absolute w-full bottom-3 z-[5] '>
                            {
                                featuredProducts.map((slide, slideIndex) => {
                                    return (
                                        <div
                                            key={slideIndex}
                                            onClick={() => {
                                                setTimeout(function () {
                                                    handleGoToSlide(slideIndex)
                                                }, 200)
                                            }}

                                            className=' text-4xl text-white cursor-pointer rounded-full bg-white/10 p-0'
                                        >
                                            {
                                                (slideIndex === currentIndex) ? <RxDotFilled /> : <RxDot />
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>

            </div>
        </div>


    )
}

export default SlideCarousel3
