import { zodResolver } from '@hookform/resolvers/zod'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import OtherSettingsSchema from './OperatingTimeSchema'
import GrayButton from '../../components/GrayButton'
import GreenButton from '../../components/GreenButton'
import TimeDropDown from '../../components/TimeDropDown'
import OperatingTimeSchema from './OperatingTimeSchema'
import { GlobalContext } from '../../../../context/GlobalContext'
import axios from 'axios'
import AlertContext from '../../../../context/AlertContext'


const days = [
    {
        day: "Monday",
        from: "monday_from",
        to: "monday_to",
    },
    {
        day: "Tuesday",
        from: "tuesday_from",
        to: "tuesday_to",
    },
    {
        day: "Wednesday",
        from: "wednesday_from",
        to: "wednesday_to",
    },
    {
        day: "Thursday",
        from: "thursday_from",
        to: "thursday_to",
    },
    {
        day: "Friday",
        from: "friday_from",
        to: "friday_to",
    },
    {
        day: "Saturday",
        from: "saturday_from",
        to: "saturday_to",
    },
    {
        day: "Sunday",
        from: "sunday_from",
        to: "sunday_to",
    },
]
const OperatingTime = ({ businessData, operatingTimeData }) => {
    console.log(operatingTimeData)
    console.log(businessData?.business_id)
    const parsedPreloadedValue = ((operatingTimeData))
    const [formData, setFormData] = useState(parsedPreloadedValue)
    const [working, setWorking] = useState(false)
    const { REACT_APP_API_URL }
        = useContext(GlobalContext)
    const [operatingTime, setOperatingTime] = useState(null)

    const [loading, setLoading] = useState(false)
    const { showAlert, timerRef, closeAlert, fadeOutAlert } = useContext(AlertContext)


    function getFormValues() {

        return parsedPreloadedValue
    }

    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const updateOperatingTime = async (data) => {
        setWorking(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        let xdata = JSON.stringify(data)
        let xobj = JSON.parse(xdata)
        xobj["id"] = businessData?.business_id


        const endpoint = REACT_APP_API_URL + "?operation=UPDATE_OPERATING_TIME"
        //alert(JSON.stringify(xobj))

        //return false

        const res = await axios.post(endpoint, xobj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((rsp) => {

            showAlert("success", "Update Successful.")

            setTimeout(() => {
                showAlert("info", "Reloading...")

                window.location.href = "/account/business-profile/settings/" + businessData?.business_id


            }, 1000)


        }).catch((rsp) => {

            let err = (rsp.response.data.data[0].error)
            showAlert("danger", "Error during update")
            fadeOutAlert(timerRef)
            setWorking(false)
            console.log(rsp)
        })
    }


    const onSubmit = async (data) => {
        showAlert("info", "Working...")
        let status = await updateOperatingTime(data)
    }

    const {
        register,
        handleSubmit,
        watch,
        setError,
        setValue,
        getValues,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: getFormValues(),
        resolver: zodResolver(OperatingTimeSchema)
    })




    return (
        <div>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
                <div className=' border-[1px] border-gray-300 rounded-[8px]'>
                    <div className='m-5'>
                        <div className=' font-bold mb-3 text-[19px]'>
                            Business Operating Time
                        </div>
                        <div className=' bg-blue-100 p-3 rounded-[8px] font-bold grid grid-cols-12'>
                            <div className=' col-span-3'>
                                Days</div>
                            <div className=' col-span-4'>
                                Opening Time</div>
                            <div className=' col-span-5'>
                                Closing Time</div>
                        </div>
                        <div className='h-[24px]'></div>
                        <div className=' border-[1px] border-gray-300 rounded-[8px]'>
                            {
                                days.map((item) => {
                                    return <div className=' grid grid-cols-12 gap-2 m-3 place-content-center border-b-[1px] border-gray-200 pb-4 '>
                                        <div className=' col-span-2 font-bold '>
                                            {item.day.substring(0, 3)}
                                        </div>
                                        <div className=' relative col-span-5'>
                                            <TimeDropDown
                                                register={register}
                                                changeHandler={changeHandler}
                                                controlName={item.from}
                                                error={errors?.bar}
                                                labelTop={"From"}
                                                labelBottom={""}

                                            />
                                        </div>
                                        <div className=' relative col-span-5'>
                                            <TimeDropDown
                                                register={register}
                                                changeHandler={changeHandler}
                                                controlName={item.to}
                                                error={errors?.bar}
                                                labelTop={"To"}
                                                labelBottom={""}

                                            />
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className='mt-3'>
                            <GreenButton
                                working={working}
                                title={"Update Operating Time"}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default OperatingTime
