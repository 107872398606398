import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import { StarIcon } from '@heroicons/react/outline';
import ActivitySideInfo from '../../components/body/ActivitySideInfo';
import SearchGrid from './SearchGrid';
import { GlobalContext, GlobalSetterContext } from '../../context/GlobalContext';
import SearchResultGrid from './SearchResultGrid';
import Featured from '../../components/body/Featured';
import { FaCarSide } from 'react-icons/fa';
import { MdFlight, MdPrecisionManufacturing } from 'react-icons/md';
import { GiScales } from 'react-icons/gi';
import { BiRestaurant } from 'react-icons/bi';
import { AiFillInsurance } from 'react-icons/ai';
import H32 from '../../components/body/H32';
import VerticalBanner from '../../components/ads/VerticalBanner';
import { CgSearchLoading, CgSpinner } from 'react-icons/cg';
import { GridLoadIcon } from '@mui/x-data-grid';

const navlnk = [
    {
        title: "Automotive",
        icon: <FaCarSide size={18} />,
        link: "/search?what=auto&where="
    },
    {
        title: "Manufacturing",
        icon: <MdPrecisionManufacturing size={18} />,
        link: "/search?what=manufacturing&where="
    },
    {
        title: "Travels",
        icon: <MdFlight size={18} />,
        link: "/search?what=travel&where="
    },
    {
        title: "Legal Services",
        icon: <GiScales size={18} />,
        link: "/search?what=law&where="
    },
    {
        title: "Restaurant",
        icon: <BiRestaurant size={18} />,
        link: "/search?what=restaurant&where="
    },
    {
        title: "Finance",
        icon: <AiFillInsurance size={15} />,
        link: "/search?what=finance&where="
    },

]

const navlnk2 = [
    {
        title: "Travels",
        icon: <MdFlight size={18} />,
        link: "/search?what=travel&where="
    },
    {
        title: "Restaurant",
        icon: <BiRestaurant size={18} />,
        link: "/search?what=restaurant&where="
    },
    {
        title: "Finance",
        icon: <AiFillInsurance size={15} />,
        link: "/search?what=finance&where="
    },

]


function SearchResults() {
    const [searchdata, setSearchdata] = useState([])
    const { REACT_APP_API_URL, isAdsense } = useContext(GlobalContext)
    const { setAdsense } = useContext(GlobalSetterContext)
    const [srcData, setSrcData] = useState(null)
    const [what, setWhat] = useState(undefined)
    const [where, setWhere] = useState(undefined)
    const [params] = useSearchParams()
    const [isLoaded, setIsLoaded] = useState(false)

    const getBusinessesByParams = async (what, where) => {

        let endpoint = REACT_APP_API_URL + '?operation=SEARCH_BUSINESSES_BY_PARAMS'
        endpoint += "&what=" + what
        endpoint += "&where=" + where

        //window.location.href = endpoint
        //return false;

        let response = null

        try {
            //await new Promise((resolve) => setTimeout(resolve, 1000));
            response = await fetch(
                endpoint,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }

                }
            );
            const result = await response.json();
            //console.log(result.data)
            setSrcData(result.data)
        } catch (e) {
            console.log("Error: " + e)
        }


    }

    function setSearchParamData() {
        let xwhat = params.get('what')
        let xwhere = params.get('where')

        if (xwhat == 'undefined' || xwhat == '' || xwhat == null) {
            xwhat = ''
        }
        if (xwhere == 'undefined' || xwhere == '' || xwhere == null) {
            xwhere = ''
        }
        setWhat(xwhat)
        setWhere(xwhere)

    }

    useEffect(() => {
        setIsLoaded(true)
    }, [setIsLoaded])

    useEffect(() => {
        setSearchParamData()
    }, [setSearchParamData])

    useEffect(() => {
        if (isLoaded) {
            getBusinessesByParams(what, where)
        }
    }, [isLoaded])



    const cardjson = [
        {
            id: 1,
            title: "Generative AI For The Rest Of US: Your Future, Decoded",
            category: "Historical Tours",
            price: 29.99,
            currency: "USD",
            symbol: "$",
            tour_duration: "2 hours",
            quantity: "per adult",
            description: "The primary purpose of this book is to provide the average person with a comprehensive, 360-degree view of the Generative AI landscape.",
            terms: "free cancellation",
            img: "https://m.media-amazon.com/images/I/41fOFPXPqeL.jpg",
            link: "https://amzn.to/45cPP1k",
            recommendation: "Recommended by 98% of buyers",
            rating: 5,
        },
        {
            id: 2,
            title: "Artificial Intelligence For Dummies (For Dummies (Computer/Tech))",
            category: "Business Tours",
            price: 22.33,
            currency: "USD",
            symbol: "$",
            tour_duration: "2 hours",
            quantity: "per adult",
            description: "Every time you use a smart device or some sort of slick technology―be it a smartwatch, smart speaker, security alarm, or even customer service chat box...",
            terms: "free cancellation",
            img: "https://betanews.com/wp-content/uploads/2024/05/Artificial-Intelligence-For-Dummies.jpg",
            link: "https://amzn.to/3XsbbWP",
            recommendation: "Recommended by 98% of buyers",
            rating: 4.1,
        },
        {
            id: 3,
            title: "The Basics of Bitcoins and Blockchains: An Introduction to Cryptocurrencies and the Technology that Powers Them",
            category: "Gaming Tours",
            price: 11.76,
            currency: "USD",
            symbol: "$",
            tour_duration: "2 hours",
            quantity: "per adult",
            description: "There’s a lot written on cryptocurrency and blockchains. But, for the uninitiated, most of this information can be indecipherable.",
            terms: "free cancellation",
            img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1616973380i/57557658.jpg",
            link: "https://amzn.to/457TJsl",
            recommendation: "Recommended by 98% of buyers",
            rating: 4.5,
        },
        {
            id: 4,
            title: "Mastering Cryptocurrency Trading: A comprehensive guide to financial success",
            category: "Architectural Tours",
            price: 35.95,
            currency: "USD",
            symbol: "$",
            tour_duration: "2 hours",
            quantity: "per adult",
            description: "Mastering Cryptocurrency Trading is your go- to guide for diving into the world of trading digital currencies",
            terms: "free cancellation",
            img: "https://m.media-amazon.com/images/I/71dS+JbmMPL._AC_UF1000,1000_QL80_.jpg",
            link: "https://amzn.to/3Vdl5Zg",
            recommendation: "Recommended by 98% of buyers",
            rating: 4.5,
        },
        {
            id: 5,
            title: "The Ultimate Guide to Cryptocurrency: Navigating the World of Digital Assets ",
            category: "Rental Tours",
            price: 14.99,
            currency: "USD",
            symbol: "$",
            tour_duration: "2 hours",
            quantity: "per adult",
            description: `Introducing "The Ultimate Guide to Cryptocurrency: Navigating the World of Digital Assets" – your comprehensive roadmap to understanding and mastering the captivating realm of cryptocurrencies.`,
            terms: "free cancellation",
            img: "https://m.media-amazon.com/images/I/41M1-I1bKvL._AC_SF480,480_.jpg",
            link: "https://amzn.to/4c5SMCX",
            recommendation: "Recommended by 98% of buyers",
            rating: 4.8,
        },
        {
            id: 6,
            title: "The Only Altcoin Investing Book You'll Ever Need: Your Definitive Manual for Profiting from the Next Crypto Bull Run",
            category: "Boating Scenes",
            price: 15.75,
            currency: "USD",
            symbol: "$",
            tour_duration: "2 hours",
            quantity: "per adult",
            description: "The crypto market is still growing faster than the internet was in 1997...And with altcoins emerging as the stars of the next decade... this book is your essential guide to navigating the dynamic world of alternative cryptocurrencies.",
            terms: "free cancellation",
            img: "https://i1.sndcdn.com/artworks-zx3NoBdxiu7n9lvM-U4C5MQ-t500x500.jpg",
            link: "https://amzn.to/3Vux5Hm",
            recommendation: "Recommended by 98% of buyers",
            rating: 4.6,
        },
    ]

    let [destinations, setDestinations] = useState([]);

    function getLink(lnk) {
        return "/item/" + lnk
    }

    function computeRating(totalx) {
        return Math.round(totalx);
    }

    function getStars(rating) {
        let i = 0;
        let limit = computeRating(rating);
        let stars = [];
        for (i = 0; i < limit; i++) {
            stars.push(<StarIcon key={i} className=' h-4 text-red-400' />)
        }
        return stars;
    }

    function getSubstring(str) {
        const maxLen = 100;
        let finalString = "";
        if (str.length > maxLen) {
            finalString += str.substring(0, maxLen) + "...";
        } else {
            finalString = str;
        }
        return finalString;
    }

    return (
        <>
            <div>
                <div className=' max-w-[1200px] mx-auto w-full '>
                    <div className=' flex place-content-between place-items-center  mb-1 mt-3 border-b-[1px] border-t-[1px] border-gray-500/20'>
                        <div className='text-[21px] tracking-tighter '>
                            <b>Automotive</b> in the <b>United States</b>
                        </div>
                        <div className='hidden  w-fit truncate gap-3  px-2 lg:flex  place-content-end place-items-center'>
                            {
                                navlnk.map((item) => {
                                    return (
                                        <div>
                                            <div className='underline'>
                                                <a href={item?.link}>
                                                    <div>
                                                        {item?.title}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className='hidden lg:hidden w-[50%] truncate gap-3 overflow-hidden h-[40px] md:flex place-content-end place-items-center'>
                            {
                                navlnk2.map((item) => {
                                    return (
                                        <div>
                                            <div className='underline'>
                                                <a href={item?.link}>
                                                    <div>
                                                        {item?.title}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>


                    </div>

                    <div className=' grid grid-cols-1 lg:grid-cols-12 
                    md:gap-x-5 mx-auto w-full     
                    '>
                        <div className='lg:col-span-8  lg:mr-[-50px] '>
                            <div className=' '>
                                {
                                    srcData !== null ?
                                        <SearchResultGrid data={srcData} />
                                        : <div className=' flex items-center'><CgSpinner size={20} className=" animate-spin" /> Working...</div>
                                }
                            </div>

                        </div>

                        <div className='lg:col-span-4 lg:ml-[60px]'>
                            <div className=' h-[10px]'></div>
                            <Featured />
                            <H32 />
                            <div className=' lg:sticky lg:top-[100px]'>
                                <VerticalBanner
                                    dataAdSlot={'3148685431'}
                                    dataAdFormat={'auto'}
                                    dataFullWidthResponsive={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchResults
