import React, { useContext, useEffect, useState } from 'react'
import HeaderLightSearch from '../../../../components/header/headerLight/HeaderLightSearch'
import HeaderSpace from '../../../../components/header/HeaderSpace'
import SettingsLeftNav from '../../components/SettingsLeftNav'
import Prefooter from '../../../../components/footer/Prefooter'
import Footer from '../../../../components/footer/Footer'
import SubHeaderNav from '../../components/SubHeaderNav'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../../../../context/GlobalContext'
import AccountGridTable from './HomeGridTable'
import { HomeContent } from './HomeContent'
import axios from 'axios'

const Home = () => {
    const { user, REACT_APP_API_URL } = useContext(GlobalContext)
    const [loading, setLoading] = useState(true)
    const [userData, setUserData] = useState(null)
    const navigate = useNavigate()
    const { gcolors } = useContext(GlobalContext)

    useEffect(() => {
        const endpoint = REACT_APP_API_URL + "?operation=READ_USER&id=" + user.user_id
        //window.location.href = (endpoint)
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                console.log(res.data)
                //alert(res.data.data[0])
                const data = JSON.stringify(res.data.data[0])

                setUserData(data)
                setLoading(false)
                //alert(userData)
            })
    }, [setUserData])


    return (
        <div>
            <HeaderLightSearch
                bgColor={"bg-transparent"}
                bgColorOnScroll={"bg-white"}
                logoColor={"text-[#fff]"}
                logoTextColor={"text-[#4b0d96]"}
                logoTextColorOnScroll={"text-[#4b0d96]"}
                logoColorOnScroll={"text-[#fff]"}
                logoBgColor={"bg-[#4b0d96]"}
                logoBgColorOnScroll={"bg-[#4b0d96]"}
                color={"text-[#fff]"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />

            <HeaderSpace />

            <div className=' px-4'>
                <div className=' max-w-[1200px] mx-auto w-full grid grid-cols-1 lg:grid-cols-12 gap-8'>
                    <div className='hidden lg:block lg:col-span-3'>
                        <SettingsLeftNav />
                    </div>
                    <div className=' lg:col-span-9'>


                        {
                            (userData) ?
                                <HomeContent
                                    preloadedValues={userData}
                                    user={user}
                                /> : 'Waiting for profile data...'
                        }
                    </div>
                </div>
            </div>

            <HeaderSpace />
            <Footer />

        </div>
    )
}



export default Home
