import React from 'react'

function Datatable() {
    return (
        <div>

        </div>
    )
}

export default Datatable
