import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import H16 from './H16'

const Featured = () => {
    const [srcData, setSrcData] = useState([])
    const { REACT_APP_API_URL } = useContext(GlobalContext)

    const getLatestBusinesses = async () => {
        let endpoint = REACT_APP_API_URL + '?operation=READ_LATEST_BUSINESSES'
        let response = null

        try {
            response = await fetch(
                endpoint,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }

                }
            );
            const result = await response.json();
            setSrcData(result.data)
        } catch (e) {

        }


    }

    useEffect(() => {
        getLatestBusinesses()
    }, [])
    return (
        <div className=' border-[1px] border-gray-500/30 rounded-[4px]'>
            <div className=' mx-5 my-5'>
                <div className='font-semibold text-[19px]'>Featured Businesses</div>
                <div className=' h-[8px]'></div>
                <div className=' divide-y-[1px] divide-black/30'>
                    {
                        srcData.map((item) => {
                            return (
                                <div className=' mb-[12px] pt-[12px] pb-[12px]'>
                                    <div className=' text-[19px] text-black font-black'>
                                        {item?.title}
                                    </div>
                                    <div className=' font-bold tracking-tighter'>
                                        {item?.mobile}
                                    </div>
                                    <div className='leading-[19px] text-[15px] truncate mt-[4px] tracking-tighter'>
                                        {item?.business_phrases}
                                    </div>
                                    <div className=' mt-[3px] text-[15px] leading-[19px] tracking-tighter'>
                                        {item?.address_one}, {item?.address_two}
                                    </div>
                                    <div className=' flex flex-wrap mt-[4px] gap-x-3'>
                                        <div>
                                            <a
                                                className=' text-blue-800'
                                                href={item?.website ? item?.website : '#'}>
                                                Visit Website
                                            </a>
                                        </div>

                                        <div>
                                            <a
                                                className=' text-blue-800 underline'
                                                href={'/business/' + item?.hash}>
                                                More info
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}

export default Featured
