import { SearchCircleIcon } from '@heroicons/react/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiSolidLocationPlus } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { GrLocation } from 'react-icons/gr'
import { useSearchParams } from 'react-router-dom'

import SearchSchema from './SearchSchema'
import Default from './where/Default'
import Where from './where/Where'

const HeaderSearchForm = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false)
    const initialValues = {
        what: searchParams.get("what") ? searchParams.get("what") : '',
        where: searchParams.get("where") ? searchParams.get("where") : ''
    }
    const [formData, setFormData] = useState(initialValues)

    const submitHandler = async (values) => {

        setLoading(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        console.log(values)
        let what = values.what
        let where = values.where
        let q = "what=" + what + "&where=" + where
        let srch = "/search?" + q
        window.location.href = (srch)
    }

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        setError,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: initialValues,
        resolver: zodResolver(SearchSchema)
    })

    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    return (
        <div>
            <form onSubmit={handleSubmit(submitHandler)}>
                <div className=' flex place-content-center place-items-center'>
                    <div className='border-[1px] border-gray-500/30 h-[45px] w-[44%]
                px-[4px] py-[2px] flex place-items-center gap-2 bg-white
                '>
                        <div className='ml-1 place-content-center'>
                            <BsSearch size={17} />
                        </div>
                        <div className='place-content-center w-full '>
                            <input
                                {...register("what", {
                                    onChange: changeHandler
                                })}
                                className=' text-[17px] outline-none 
                             w-full bg-transparent'
                                placeholder='Find a business?'
                                type="text"
                            />
                        </div>
                    </div>

                    {/* <div className='border-[1px] border-gray-500/30 h-[45px] w-[44%]
                px-[4px] py-[0px] flex place-items-center gap-2 bg-white
                '>
                        <div className='ml-1 place-content-center'>
                            <GrLocation size={17} />
                        </div>
                        <div className='place-content-center w-full '>
                            <input
                                {...register("where", {
                                    onChange: changeHandler
                                })}
                                className=' text-[17px] outline-none 
                             w-full'
                                placeholder='Where?'
                                type="text"
                            />
                        </div>
                    </div> */}

                    <Where
                        register={register}
                        setValue={setValue}
                        changeHandler={changeHandler}
                    />

                    <button
                        className=' bg-[#4b0d96] text-white px-3 h-[45px]
                        rounded-r-none 
                        '
                    >

                        <span className='md:hidden'>
                            <BsSearch size={20} />
                        </span>
                        <span className=' hidden md:block'>Find</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default HeaderSearchForm
