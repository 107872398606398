import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../../../context/GlobalContext"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import SettingsLeftNav from "../../components/SettingsLeftNav"
import SubHeaderNav from "../../components/SubHeaderNav"
import { BusinessProfileContent } from "./BusinessProfileContent"
import HeaderSpace from "../../../../components/header/HeaderSpace"
import Prefooter from "../../../../components/footer/Prefooter"
import Footer from "../../../../components/footer/Footer"
import HeaderLightSearch from "../../../../components/header/headerLight/HeaderLightSearch"
import { BusinessContext } from "../../../business/BusinessContext"
import BusinessProfileContext, { BusinessProfileProvider } from "../../../../context/BusinessProfileContext"

const BusinessProfile = () => {
    const { user, REACT_APP_API_URL, setBusinessProfile, gcolors } = useContext(GlobalContext)
    const [loading, setLoading] = useState(true)
    const [businessData, setBusinessData] = useState(null)
    const navigate = useNavigate()

    const [id, setId] = useState("")

    const urlid = () => {
        const url = window.location.href;
        let lastUrlSegmentWithSlash = url.substring(url.lastIndexOf("/"));
        let lastUrlSegment = lastUrlSegmentWithSlash.split("/")
        const item_id = lastUrlSegment[1];
        setId(item_id)

    }

    function getBusinessData(id) {
        const endpoint = REACT_APP_API_URL + "?operation=READ_BUSINESS&id=" + id
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = JSON.stringify(res.data.data[0])

                setBusinessData(data)
                setBusinessProfile(data)
                setLoading(false)
                //alert(businessData)
            })
            .catch((res) => {
                console.log(res)
            })
    }
    useEffect(() => {
        urlid()
    }, [])

    useEffect(() => {
        if (id) {
            getBusinessData(id)
        }
    }, [id])






    return (
        <div>
            <HeaderLightSearch
                bgColor={"bg-transparent"}
                bgColorOnScroll={"bg-white"}
                logoColor={"text-[#fff]"}
                logoTextColor={"text-[#4b0d96]"}
                logoTextColorOnScroll={"text-[#4b0d96]"}
                logoColorOnScroll={"text-[#fff]"}
                logoBgColor={"bg-[#4b0d96]"}
                logoBgColorOnScroll={"bg-[#4b0d96]"}
                color={"text-[#fff]"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />

            <HeaderSpace />
            <div className=" mx-5">
                <div className=' max-w-[1200px] mx-auto w-full grid lg:grid-cols-12 gap-8'>
                    <div className='hidden lg:block lg:col-span-3'>
                        <SettingsLeftNav />
                    </div>
                    <div className=' lg:col-span-9'>



                        {
                            (businessData) ?
                                <BusinessProfileContent
                                    preloadedValues={businessData}
                                    user={user}
                                    businessId={id}
                                />
                                : 'Loading...'

                        }
                    </div>
                </div>
            </div>
            <HeaderSpace />

            <Prefooter />

            <Footer />
        </div>
    )
}



export default BusinessProfile
