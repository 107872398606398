import React from 'react'
import Header from '../../components/header/Header'
import HeroAnimated from '../../components/body/HeroAnimated'
import SliderBox from '../../components/body/Slider'
import Hero from '../../components/body/Hero'
import Prefooter from '../../components/footer/Prefooter'
import Footer from '../../components/footer/Footer'
import Datatable from '../../components/body/Datatable'
import H64 from '../../components/body/H64'
import HeaderLightSearch from '../../components/header/headerLight/HeaderLightSearch'
import HeaderLight from '../../components/header/headerLight/HeaderLight'
import AdvertiseContent from './AdvertiseContent'
import H32 from '../../components/body/H32'

const Advertise = () => {
    return (
        <div>



            <HeaderLight
                bgColor={"bg-transparent"}
                bgColorOnScroll={"bg-white"}
                logoColor={"text-[#4b0d96]"}
                logoColorOnScroll={"text-[#fff]"}
                logoBgColor={"bg-white"}
                logoBgColorOnScroll={"bg-[#4b0d96]"}
                color={"text-[#fff]"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />
            <HeroAnimated
                imgLink={"https://unbound.radiantthemes.com/wp-content/uploads/2018/06/business-agency-3-banner.jpg"}
                title={"Stay ahead in business? Advertise with us."}
            />

            <H32 />
            <AdvertiseContent />
            <H32 />

            <div>
                <Prefooter />
            </div>

            <Footer />
        </div>
    )
}

export default Advertise
