import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const UpdateBusinessSchema = z.object({
    title: z.string()
        .min(1, { message: "Enter a business name" })
        .min(3, { message: "Busines Name must not be less than 3 characters" })
        .max(100, { message: "Business name must not be more than 100 characters." }),
    country_code: z.string()
        .min(1, { message: "Please enter a country code." }),
    mobile: z.string()
        .min(1, { message: "Please enter a valid mobile or phone number" })
        .min(5, { message: "Mobile number must not be less than 5 characters" })
        .max(18, { message: "Mobile number must not be more than 15 characters" }),
    address_one: z.string()
        .min(3, { message: "Address must not be less than 3 characters" })
        .max(100, { message: "Address must not be more than 100 characters" }),
    address_two: z.string()
        .max(100, { message: "Address must not be more than 100 characters" }),
    country: z.string()
        .min(1, { message: "Please select your country." }),
    established: z.string(),
    intro: z.any(),
    business_category: z.string()
        .min(2, { message: "Please select a business category" }),
    business_phrases: z.string()
        .min(3, { message: "Business Phrases must not be less than 3 characters" })
        .max(1000, { message: "Business Phrases must not be more than 100 characters" }),
    business_id: z.string(),
    products: z.string(),
    services: z.string(),
    xsoc: z.any(),
    fbsoc: z.any(),
    linksoc: z.any(),
    website: z.union(
        [
            z.string().url().nullish(),
            z.literal("")
        ]
    ),

    email: z.string()
        .email({ message: "Please enter a valid email" })
})
export default UpdateBusinessSchema
