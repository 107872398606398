import React, { useContext, useEffect, useState } from 'react'
import { UtilContext } from '../../context/UtilContext'
import { ClockIcon } from '@heroicons/react/outline'
import { BsClock } from 'react-icons/bs'
import LeftColumn from './LeftColumn'
import RightColumn from './RightColumn'
import ContactIcons from './ContactIcons'
import H32 from '../../components/body/H32'
import LeftColumn2 from './OrderComponent'
import OrderComponent from './OrderComponent'
import ClaimBusiness from './ClaimBusiness'
import WorkDays from './WorkDays'
import ContactDetails from './ContactDetails'
import BusinessDetails from './BusinessDetails'
import { BusinessContext } from './BusinessContext'
import RatingReview from './rating/RatingReview'
import BusinessGallery from './BusinessGallery'
import H16 from '../../components/body/H16'
import { GlobalContext } from '../../context/GlobalContext'
import axios from 'axios'
import BusinessGalleryCopy from './BusinessGalleryCopy'
import FacilitiesDetails from './FacilitiesDetails'
import RatingCo from './rating/RatingCo'
import Ratings from './rating/Ratings'
import RelatedBusinesses from './RelatedBusinesses'
import BusinessProfileBanner from '../../components/ads/BusinessProfileBanner'
import VerticalBanner from '../../components/ads/VerticalBanner'


const PageStructure = ({ business }) => {

    const [businessId, setBusinessId] = useState("")
    const { getStars } = useContext(UtilContext)
    const [open, setOpen] = useState(true)
    const { businessData, getBusinessData } = useContext(BusinessContext)
    const [rating, setRating] = useState(0)
    const [businessGalleryData, setBusinessGalleryData] = useState(null)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const [loading, setLoading] = useState(true)
    const { user } = useContext(GlobalContext)

    useEffect(() => {
        setLoading(false)
    }, [])

    useEffect(() => {
        if (business !== null) {
            getBusinessData(business)
        }
    }, [business])

    useEffect(() => {
        if (businessData !== null) {
            setBusinessId(businessData?.business_id)
        }
    }, [businessData])


    const getBusinessGalleryData = (id) => {

        const endpoint = REACT_APP_API_URL + "?operation=READ_BUSINESS_GALLERY_DATA&id=" + id
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = JSON.stringify(res.data.data)
                console.log(data)
                setBusinessGalleryData(data)
                setLoading(false)
                //alert(businessData)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    useEffect(() => {
        if (businessId !== "") {

            getBusinessGalleryData(businessId)


        }
    }, [businessId, loading])



    useEffect(() => {
        if (businessGalleryData !== null) {
            console.log(businessGalleryData)
        }
    }, [businessGalleryData])


    return (
        <div>
            <div className=' px-5'>
                {/* First row */}

                <div className=' w-full max-w-[1100px] mx-auto'>
                    <div className=' grid grid-cols-1 gap-0 lg:grid-cols-12'>
                        <div className='lg:col-span-4 order-last md:order-first px-[35px]'>
                            <LeftColumn />
                        </div>
                        <div className=' lg:col-span-8 w-full '>
                            {
                                businessId &&
                                <RightColumn id={businessId} />
                            }

                            <ContactIcons />

                            <div className=' lg:hidden'>
                                <H16 />
                            </div>




                        </div>
                    </div>
                </div>

                <div className='block'>
                    <H16 />
                </div>

                {/* Second row */}

                <div className='w-full max-w-[1100px] mx-auto'>
                    <div className=' grid grid-cols-1 gap-0 lg:grid-cols-12'>
                        <div className='md:col-span-4 order-first lg:px-[35px]'>
                            {/* <OrderComponent />
                            <H32 /> */}
                            {
                                business?.owner_role === "admin" &&
                                <ClaimBusiness />
                            }

                            {
                                businessId &&
                                <div>
                                    <H32 />
                                    <WorkDays businessId={businessId} />
                                </div>

                            }
                            <H32 />

                            <div className=' lg:sticky lg:top-[100px]'>
                                <VerticalBanner
                                    dataAdSlot={'3148685431'}
                                    dataAdFormat={'auto'}
                                    dataFullWidthResponsive={true}
                                />
                            </div>

                        </div>
                        <div className=' md:col-span-8 '>
                            {
                                businessGalleryData !== null &&
                                <BusinessGallery imagex={businessGalleryData} />
                            }
                            <H32 />
                            <div className=' border-t-[1px] border-gray-300'></div>
                            <RatingReview
                                rating={rating}
                                setRating={setRating}
                                businessId={business?.business_id}

                            />

                            <H16 />
                            <ContactDetails />
                            <H16 />

                            <BusinessDetails />
                            <H32 />
                            <div className='border-b-[1px]'></div>
                            <H16 />
                            {
                                business?.business_id &&
                                <FacilitiesDetails businessId={business?.business_id} />
                            }

                            <H32 />
                            <div className='border-b-[1px]'></div>
                            <H16 />
                            {
                                business?.business_category &&
                                <RelatedBusinesses category={business?.business_category} />
                            }

                            <H32 />

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}



export default PageStructure
