import React from 'react'

const AdvertiseContent = () => {
    return (
        <div>
            <div className=' mx-5'>
                <div className=' max-w-[1200px] mx-auto w-full'>
                    <div className=' grid grid-cols-12'>

                        <div className=' col-span-12 md:col-span-10 lg:col-span-7  space-y-8 text-[17px] tracking-normal'>
                            <p>
                                <div className=' text-2xl leading-tight font-bold'>
                                    Advertise
                                </div>
                            </p>
                            <p>
                                <b>Skyepages</b> is home to trendy and cutting edge business directory services, from showcasing local, regional and international firms to connecting businesses globally.
                            </p>

                            <p>
                                At Skyepages, we are committed to bold innovation in an ever-evolving business landscape. Our core value is focused on authentic business-to-business engagement among businesses registered on our platform, a connection driven by data and deep expertise.
                            </p>

                            <p>
                                Our  business directory services span multiple business sectors over different mediums ranging from digital portals to social media, video, print and more.
                            </p>

                            <p>
                                If interested in advertising on Skyepages.com, please contact Business and Advert Unit at <b><a href="mailto:ba@skyepages.com"><u>ba@skyepages.com</u></a></b>.
                            </p>

                            <p>

                                <ul>
                                    <li>
                                        <b>Business & Advert Unit (B&A)</b>
                                    </li>
                                    <li>
                                        Skyepage Directory Services
                                    </li>
                                    <li>
                                        <a href="mailto:ba@skyepages.com">
                                            <u>ba@skyepages.com</u>
                                        </a>
                                    </li>
                                </ul>
                            </p>
                            <p></p>
                        </div>
                        <div className=' col-span-0 md:col-span-1 lg:col-span-5'></div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AdvertiseContent
