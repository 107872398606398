import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const strength = {
    1: "very Weak",
    2: "Weak",
    3: "Meduim",
    4: "Strong",
};
function checkStrength(pass) {
    if (pass.length > 15)
        return console.log(
            pass + " Password is too lengthy"
        );
    else if (pass.length < 8)
        return console.log(
            pass + " Password is too short"
        );

    let regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!^%*?&]{8,15}$/;
    if (regex.test(pass)) {
        return console.log(pass
            + " Password is strong");
    }
    let count = 0;
    let regex1 = /[a-z]/;
    if (regex1.test(pass)) count++;
    let regex2 = /[A-Z]/;
    if (regex2.test(pass)) count++;
    let regex3 = /[\d]/;
    if (regex3.test(pass)) count++;
    let regex4 = /[!@#$%^&*.?]/;
    if (regex4.test(pass)) count++;

    console.log(pass, "Pasword is " + strength[count]);
}

const phoneValidation = new RegExp(
    /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
);

const passwordValidation = new RegExp(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
);

const ResetPwdSchema = z.object({
    password: z.string()
        .min(3, { message: "Password must not be less than 8 characters in length." })
        .regex(passwordValidation, { message: "Your password must be alphanumeric with at least one capital letter. E.g. Abcd1273%^&*(" }),
    password2: z.string()
        .min(3, { message: "Password must not be less than 8 characters in length." })
        .regex(passwordValidation, { message: "Your password must be alphanumeric with at least one capital letter. E.g. Abcd1273%^&*(" }),

    /* mobile: z.string()
    .regex(phoneValidation, { message: "Please enter a valid phone number" }) */
}).refine((data) => data.password === data.password2, {
    message: "Passwords don't match",
    path: ["password2"], // path of error
});

export default ResetPwdSchema
