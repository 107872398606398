import React from 'react'

function H16() {
    return (
        <div>
            <div className=' h-[16px]'></div>
        </div>
    )
}

export default H16
