import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { CheckIcon } from '@heroicons/react/outline'
import { GlobalContext } from '../../../../context/GlobalContext'
import axios from 'axios'

const EditGalleryImage = ({ imageData }) => {
    const [image, setImage] = useState(imageData)
    const [open, setOpen] = useState()
    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)
    const [maxWidth, setMaxWidth] = useState('sm');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [msg, setMsg] = useState("")
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const [formData, setFormData] = useState([])

    const handleClickOpen = () => {
        setOpen(true)
        setComplete(false)
        setLoading(false)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const changeHandler = async (e) => {
        let value = e.target.value
        let name = await e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const editImageDescription = async (formData) => {
        //alert(JSON.stringify(formData))
        setLoading(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        let url = REACT_APP_API_URL + '?operation=UPDATE_BUSINESS_GALLERY_IMAGE_TITLE'
        url += "&image_guid=" + formData["image_guid"]
        url += "&business_id=" + formData["business_id"]
        url += "&title=" + formData["image_title"]

        //alert(url)

        //window.location.href = url
        //return false
        try {
            let rst = axios.post(url, formData)
                .then(function (response) {
                    setMsg(response.data);
                    //setComplete(true)
                    //setLoading(false)
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000)

                })
                .catch(function (e) {
                    setMsg(e.response.data)
                    setComplete(true)
                    setLoading(false)
                })


        } catch (e) {

        }
    }

    return (
        <div>
            <a href="javascript:(0)"
                onClick={handleClickOpen}
            >
                Edit
            </a>
            <Dialog
                open={open}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: async (event) => {
                        event.preventDefault()
                        setLoading(true)
                        setComplete(false)
                        const formData = new FormData(event.currentTarget)
                        const formJson = Object.fromEntries(formData.entries())
                        formData.image_title = formJson.image_title
                        formData.image_guid = image.image_guid
                        formData.business_id = image.business_id
                        await editImageDescription(formData)
                        //await new Promise((resolve) => setTimeout(resolve, 2000));
                        //setComplete(true)
                        //setLoading(false)
                        //await new Promise((resolve) => setTimeout(resolve, 3000));
                        //setComplete(false)
                        //handleClose()

                    },
                }}
            >
                <DialogTitle>
                    <div className=' font-bold text-red-900'>Edit Picture Title</div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {complete ? 'Your description has been successfully updated. You can close this window to continue.' : 'Enter the description for this photo. This can be changed at any time you wish. To continue simply fill or edit the description below.'}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="image_title"
                        name="image_title"
                        label="Edit Description"
                        type="text"
                        fullWidth
                        variant="standard"
                        defaultValue={image.title}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {complete ? 'Close' : 'Cancel'}
                    </Button>
                    <Button type="submit" disabled={complete || loading ? "disabled" : ''}>
                        {loading ? <CircularProgress size={20} /> :

                            complete ? <CheckIcon /> : 'Submit'

                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default EditGalleryImage
