import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext, GlobalSetterContext } from '../../context/GlobalContext'
import { BsChevronDown, BsChevronRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { HomeIcon, LoginIcon, LogoutIcon } from '@heroicons/react/outline'
import { FiSettings } from 'react-icons/fi'
import axios from 'axios'

const dropdown = [
    {
        id: 1,
        txt: "Account",
        link: "/account",
        icon: <HomeIcon width={18} height={18} />
    },
    {
        id: 2,
        txt: "Settings",
        link: "/account/settings/profile",
        icon: <FiSettings width={22} height={20} />
    },

]

const UserDropDown = () => {
    let { user, REACT_APP_API_USER_IMG_URI, REACT_APP_API_URL, REACT_APP_IMG_PATH } = useContext(GlobalContext)
    let { logoutUser } = useContext(GlobalSetterContext)
    let [isMenu, setIsMenu] = useState(false)
    const [loading, setLoading] = useState(true)
    const [userData, setUserData] = useState(null)

    useEffect(() => {
        //alert(REACT_APP_API_USER_IMG_URI)
    }, [])

    useEffect(() => {
        const endpoint = REACT_APP_API_URL + "?operation=READ_USER&id=" + user?.user_id
        //window.location.href = (endpoint)
        if (user) {
            axios.get(endpoint, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then((res) => {
                    //console.log(res.data)
                    //alert(res.data.data[0])
                    if (res.data.data) {
                        const data = JSON.stringify(res.data.data[0])

                        setUserData(JSON.parse(data))
                    }
                    setLoading(false)
                    //alert(userData)
                })
        }
    }, [user])




    const isLoggedIn = () => {
        if (user && userData && user?.email) {
            return (
                <div>


                    <button
                        onClick={() => !isMenu ? setIsMenu(true) : setIsMenu(false)}
                        onBlur={() => {
                            setTimeout(function () {
                                !isMenu ? setIsMenu(true) : setIsMenu(false)
                            }, 200)
                        }}
                        className='flex items-center gap-1 hover:underline cursor-pointer'
                    >
                        <div className=' relative w-[30px] h-[30px] rounded-full overflow-hidden border-[1px] border-black'>
                            {userData !== null ? <img className=' object-cover w-full h-full' src={REACT_APP_API_USER_IMG_URI + userData?.img} alt="img" />
                                :
                                <img className=' object-cover w-full h-full' src={REACT_APP_IMG_PATH + "/img/st.png"} alt="img" />}
                        </div>
                        <div className='text-[15px] font-bold w-[50px] truncate text-left'>{user.first_name}</div>
                        {/* <BsChevronDown className=' text-[14px] relative top-[2px]' /> */}
                    </button>
                </div>

            )
        }

        return (
            <Link to={"/login"} className='flex items-center hover:underline cursor-pointer'>
                <div className='text-[17px] font-bold'>Login</div>
                {/* <BsChevronRight className=' text-[10px]' /> */}
            </Link>
        )
    }

    function getImage(userData) {


        if (userData?.img) {
            return REACT_APP_API_USER_IMG_URI + userData?.img
        }
        return REACT_APP_IMG_PATH + "/img/st.png"
    }

    return (
        <div>
            <ul
                id={"TopMenuLeft"}
                className={"flex items-center text-[11px] text-[#333333] relative "}

            >
                <li className='relative px-1'>
                    {isLoggedIn()}
                    <div
                        id='AuthDropDown'
                        className={`absolute bg-[#4b0d96] w-[200px] 
                            pt-4 pb-4 rounded-md top-10
                            text-[#333333] z-40  right-0 shadow-xl
                            ${isMenu ? 'visible' : 'hidden'}`}
                    >
                        {/* <div>
                            <div className='flex items-center justify-start gap-1 p-2'>
                                <div className=' relative w-[40px] h-[40px] rounded-full overflow-hidden'>
                                    <img className=' object-cover w-full h-full' src={REACT_APP_API_USER_IMG_URI + user?.img} alt="img" />
                                </div>
                                <div className='font-bold text-[14px]'>Hello, {user?.first_name}</div>
                            </div>
                        </div> */}



                        <div className='bg-white'>
                            {
                                dropdown.map((item) => {
                                    return (
                                        <div key={item.id} className='text-[14px] hover:bg-gray-200 border-b px-4 w-full text-black hover:font-bold cursor-pointer'>
                                            <div className=' py-2 flex space-x-1 place-items-center' >
                                                <div className=' w-5 flex place-content-center'>
                                                    {item.icon}
                                                </div>
                                                <div className=' inline-block w-full  text-left'>
                                                    <a className='' href={item.link}>
                                                        <div>
                                                            {item.txt}
                                                        </div>
                                                    </a>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className=' text-[14px] hover:bg-gray-200  px-4 w-full text-black hover:font-bold cursor-pointer'>
                                <div className=' py-2 flex space-x-1 place-items-center' onClick={() => { setIsMenu(false); logoutUser() }}>
                                    <div className=' w-5 flex place-content-center'>
                                        <LogoutIcon width={18} height={18} />
                                    </div>
                                    <div>
                                        Logout
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </li>

            </ul>

        </div >
    )
}

export default UserDropDown
