import React, { useContext } from 'react'
import H32 from '../../components/body/H32'
import H16 from '../../components/body/H16'
import H24 from '../../components/body/H24'
import { BusinessContext } from './BusinessContext'
import { IoInformation } from 'react-icons/io5'
import { GrCircleInformation } from 'react-icons/gr'
import { FaBuilding } from 'react-icons/fa'
import { GiScaleMail } from 'react-icons/gi'
import { MdEmail, MdOutlineBusinessCenter, MdOutlinePayments, MdPayment } from 'react-icons/md'
import { BiBuildingHouse, BiCategory, BiSolidInstitution, BiStreetView } from 'react-icons/bi'
import { CgWebsite } from 'react-icons/cg'
import { BsBank2, BsBuildingFill } from 'react-icons/bs'

const BusinessDetails = () => {
    const { businessData } = useContext(BusinessContext)

    return (
        <div>
            <div className='font-bold text-[21px] tracking-tighter'>
                Business Details
            </div>
            <H16 />

            <div>
                <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                    <div className=' font-bold lg:col-span-10 flex place-items-center gap-2'>
                        <FaBuilding /> General Info
                    </div>
                    <div className=' lg:col-span-10 whitespace-pre-line'>
                        {businessData?.intro}
                    </div>
                </div>
            </div>
            <H24 />
            <div className='grid grid-cols-1'>
                <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                    <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                        <MdEmail /> Email
                    </div>
                    <div className=' lg:col-span-9'>
                        {businessData?.email}
                    </div>
                </div>
                <H24 />
                <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                    <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                        <BsBank2 /> Year Founded
                    </div>
                    <div className=' lg:col-span-9'>
                        {businessData?.established}
                    </div>
                </div>
                <H24 />
                <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                    <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                        <MdOutlinePayments /> Payment Method
                    </div>
                    <div className=' lg:col-span-9'>
                        {businessData?.payment_method}
                    </div>
                </div>
                <H24 />
                <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                    <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                        <BiStreetView />
                        Neighbourhood
                    </div>
                    <div className=' lg:col-span-9'>
                        {businessData?.neighbourhood}
                    </div>
                </div>
                <H24 />
                <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                    <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                        <CgWebsite />
                        Website
                    </div>
                    <div className=' lg:col-span-9'>
                        {businessData?.website}
                    </div>
                </div>
                <H24 />
                <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                    <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                        <MdOutlineBusinessCenter />
                        Business Phrases
                    </div>
                    <div className=' lg:col-span-9'>
                        {businessData?.business_phrases}
                    </div>
                </div>
                <H24 />
                <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                    <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                        <BiCategory />
                        Category
                    </div>
                    <div className=' lg:col-span-9'>
                        {businessData?.business_category}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessDetails
