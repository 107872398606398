import React, { useState } from "react";


export default function ClipBoardCopy() {
    const [copied, setCopied] = useState(false);

    async function copy() {
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setCopied(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setCopied(false)
    }

    return (
        <div className=' text-black w-full '>
            <a href="#" className=" w-full inline-block border-b-blue-300" onClick={copy}>
                <div className=" w-full">
                    {!copied ? "Copy and Share" : "Copied!"}
                </div>
            </a>

        </div>
    );
}