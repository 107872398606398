import React from 'react'


const BusinessCategory = ({ register, changeHandler, jsondata, controlName, error, labelTop, labelBottom, placeholder }) => {
    return (
        <div>
            <div className='flex flex-col w-[100%] mb-6'>
                <label className='font-bold mb-[8px]' htmlFor="title">{labelTop}</label>
                <select name="business_category" id=""
                    {...register((controlName) ? controlName : 'default', {
                        onChange: changeHandler
                    })}
                    className='border-[1px] outline-none 
                    border-gray-500/40 bg-gray-500/5 
                    shadow-md rounded-md px-[10px] 
                    py-[10px] text-[15px] form-width-50'
                    placeholder='Select Business Category'

                >
                    <option value="" data-countryCode="Select" Selected>Select Business Category</option>
                    {
                        jsondata?.map((item) => {
                            return (
                                <option value={item.profession} Selected>{item.profession}</option>
                            )
                        })
                    }

                </select>
                {error && (<div className=' text-red-400 mt-[5px] text-[15px] '><span className=' text-red-500 font-bold'>{error.message}</span></div>)}

                <label className='font-normal 
                text-[14px] mt-[8px] leading-[18px]
}'>
                    {labelBottom}
                </label>
            </div>
        </div>


    )
}

export default BusinessCategory
