import { zodResolver } from '@hookform/resolvers/zod'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import OtherSettingsSchema from './OperatingTimeSchema'
import GrayButton from '../../components/GrayButton'
import GreenButton from '../../components/GreenButton'
import InputCustom from '../../components/InputCustom'
import TimeDropDown from '../../components/TimeDropDown'
import FacilitiesSchema from './FacilitiesSchema'
import AlertContext from '../../../../context/AlertContext'
import { GlobalContext } from '../../../../context/GlobalContext'
import axios from 'axios'
import InputCustomWithCheckBox from '../../components/InputCustomWithCheckBox'


const days = [
    {
        day: "Monday",
        from: "mondayFrom",
        to: "mondayTo",
    },
    {
        day: "Tuesday",
        from: "tuesdayFrom",
        to: "tuesdayTo",
    },
    {
        day: "Wednesday",
        from: "wednesdayFrom",
        to: "wednesdayTo",
    },
    {
        day: "Thursday",
        from: "thursdayFrom",
        to: "thursdayTo",
    },
    {
        day: "Friday",
        from: "fridayFrom",
        to: "fridayTo",
    },
    {
        day: "Saturday",
        from: "saturdayFrom",
        to: "saturdayTo",
    },
    {
        day: "Sunday",
        from: "sundayFrom",
        to: "sundayTo",
    },
]
const Faciliites = ({ businessData, facilitiesData }) => {
    //alert(JSON.stringify(facilitiesData))



    console.log(facilitiesData)

    const parsedPreloadedValue = ((facilitiesData))
    const [formData, setFormData] = useState(parsedPreloadedValue)
    const [working, setWorking] = useState(false)
    const { showAlert, timerRef, closeAlert, fadeOutAlert } = useContext(AlertContext)
    const { REACT_APP_API_URL }
        = useContext(GlobalContext)

    function getFormValues() {

        console.log(parsedPreloadedValue)
        return parsedPreloadedValue
    }

    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const updateFacilities = async (data) => {
        setWorking(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        let xdata = JSON.stringify(data)
        let xobj = JSON.parse(xdata)
        xobj["id"] = businessData?.business_id
        //alert(JSON.stringify(xobj))
        //return false
        const endpoint = REACT_APP_API_URL + "?operation=UPDATE_FACILITIES"
        //window.location.href = endpoint
        //return false
        //alert(JSON.stringify(xobj))
        //return false
        const res = await axios.post(endpoint, xobj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((rsp) => {
            console.log(rsp)
            showAlert("success", "Update Successful.")
            setTimeout(() => {
                showAlert("info", "Reloading...")
                window.location.href = "/account/business-profile/settings/" + businessData?.business_id
            }, 1000)

        }).catch((rsp) => {
            let err = (rsp.response.data.data[0].error)
            showAlert("danger", "Error during update")
            fadeOutAlert(timerRef)
            setWorking(false)
            console.log(rsp)
        })
    }

    const onSubmit = async (data) => {
        showAlert("info", "Working...")
        let status = await updateFacilities(data)


    }

    const {
        register,
        handleSubmit,
        watch,
        setError,
        setValue,
        getValues,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: getFormValues(),
        resolver: zodResolver(FacilitiesSchema)
    })


    return (
        <div>
            <form id="upf" onSubmit={handleSubmit(onSubmit)} action={REACT_APP_API_URL + "?operation=UPDATE_FACILITIES"}>
                <div className=' border-[1px] border-gray-300 rounded-[8px]'>
                    <div className='m-5'>

                        <div className=' bg-blue-100 p-3 rounded-[8px] font-bold grid grid-cols-12'>
                            Facilities
                        </div>
                        <div className='h-[24px]'></div>



                        <div className=' w-full md:w-[60%]'>
                            {/* <input type="hidden"
                                name='id'
                                value={businessData?.business_id}
                            />
                            <input type="hidden"
                                name='operation'
                                value="UPDATE_FACILITIES"
                            /> */}
                            <InputCustomWithCheckBox
                                register={register}
                                changeHandler={changeHandler}
                                getValues={getValues}
                                controlName={"bar"}
                                checkboxControlName={"has_bar"}
                                checkboxHeight={24}
                                maximumLength={200}
                                width={100}
                                labelTop={"Bar"}
                                labelBottom={"If the business offers this facility, please enter the details of this facilities."}
                                placeholder={"Enter Bar Details"}
                                error={errors?.bar}
                            />

                            <InputCustomWithCheckBox
                                register={register}
                                changeHandler={changeHandler}
                                getValues={getValues}
                                controlName={"cafetaria"}
                                checkboxControlName={"has_cafetaria"}
                                checkboxHeight={24}
                                maximumLength={200}
                                width={100}
                                labelTop={"Cafetaria"}
                                labelBottom={"If the business offers this facility, please enter the details in the box."}
                                placeholder={"Enter Cafetaria Details"}
                                error={errors?.cafetaria}
                            />

                            <InputCustomWithCheckBox
                                register={register}
                                changeHandler={changeHandler}
                                getValues={getValues}
                                controlName={"library"}
                                checkboxControlName={"has_library"}
                                checkboxHeight={24}
                                maximumLength={200}
                                width={100}
                                labelTop={"Library"}
                                labelBottom={"If the business offers this facility, please enter the details in the box."}
                                placeholder={"Enter Library Details"}
                                error={errors?.library}
                            />

                            <InputCustomWithCheckBox
                                register={register}
                                changeHandler={changeHandler}
                                getValues={getValues}
                                controlName={"lounge"}
                                checkboxControlName={"has_lounge"}
                                checkboxHeight={24}
                                maximumLength={200}
                                width={100}
                                labelTop={"Lounge"}
                                labelBottom={"If the business offers this facility, please enter the details in the box."}
                                placeholder={"Enter Lounge Details"}
                                error={errors?.lounge}
                            />


                            <InputCustomWithCheckBox
                                register={register}
                                changeHandler={changeHandler}
                                getValues={getValues}
                                controlName={"music"}
                                checkboxControlName={"has_music"}
                                checkboxHeight={24}
                                maximumLength={200}
                                width={100}
                                labelTop={"Music"}
                                labelBottom={"If the business offers this facility, please enter the details in the box."}
                                placeholder={"Enter Library Details"}
                                error={errors?.music}
                            />

                            <InputCustomWithCheckBox
                                register={register}
                                changeHandler={changeHandler}
                                getValues={getValues}
                                controlName={"mini_mart"}
                                checkboxControlName={"has_mini_mart"}
                                checkboxHeight={24}
                                maximumLength={200}
                                width={100}
                                labelTop={"Mini mart"}
                                labelBottom={"If the business offers this facility, please enter the details in the box."}
                                placeholder={"Enter Library Details"}
                                error={errors?.mini_mart}
                            />

                            <InputCustomWithCheckBox
                                register={register}
                                changeHandler={changeHandler}
                                getValues={getValues}
                                controlName={"business_center"}
                                checkboxControlName={"has_business_center"}
                                checkboxHeight={24}
                                maximumLength={200}
                                width={100}
                                labelTop={"Business center"}
                                labelBottom={"If the business offers this facility, please enter the details in the box."}
                                placeholder={"Enter Business Center Details"}
                                error={errors?.business_center}
                            />
                        </div>

                        <div className='mt-3'>
                            <GreenButton
                                working={working}
                                title={"Update facilities"}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Faciliites
