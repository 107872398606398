import React from 'react'

function H24() {
    return (
        <div>
            <div className=' h-[24px]'></div>
        </div>
    )
}

export default H24
