import React, { useContext } from 'react'
import H32 from '../../components/body/H32'
import H16 from '../../components/body/H16'
import { BusinessContext } from './BusinessContext'
import { LocationMarkerIcon, MapIcon, PhoneIcon } from '@heroicons/react/outline'
import { BiMapPin } from 'react-icons/bi'
import { CgWebsite } from 'react-icons/cg'

const ContactDetails = () => {
    const { businessData } = useContext(BusinessContext)

    return (
        <div className='border-b-[2px] border-gray-200 pb-10 border-t-[2px] pt-10'>
            <div className='font-bold text-[21px] tracking-tighter'>
                Contact Details
            </div>
            <H16 />
            <div className=' '>
                <ul className=' text-[14.3px] space-y-[12px] text-black'>
                    <li className='flex gap-2 place-items-center'>
                        <div>
                            <PhoneIcon className=' h-[17px]' />
                        </div>
                        <div>
                            <b>Phone: </b>+{businessData?.country_code} {businessData?.mobile}
                        </div>

                    </li>
                    <li className='flex gap-2 place-items-center'>
                        <div>
                            <LocationMarkerIcon className=' h-[17px]' />
                        </div>
                        <div>
                            <b>Address: </b>{businessData?.address_one}, {businessData?.address_two}
                        </div>
                    </li>
                    <li className='flex gap-2 place-items-center'>
                        <div>
                            <CgWebsite className=' h-[17px]' />
                        </div>
                        <div>
                            <b>Website: </b><span className=' text-blue-500'>{businessData?.website}</span>
                        </div>
                    </li>
                    <li>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ContactDetails
