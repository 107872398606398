import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'




const phoneRegex = new RegExp(/^(?:\+1)?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/);

const FacilitiesSchema = z.object({
    bar: z.any(),
    cafetaria: z.any(),
    library: z.any(),
    lounge: z.any(),
    music: z.any(),
    mini_mart: z.any(),
    business_center: z.any(),
    has_bar: z.any(),
    has_cafetaria: z.any(),
    has_library: z.any(),
    has_lounge: z.any(),
    has_music: z.any(),
    has_mini_mart: z.any(),
    has_business_center: z.any(),
})

export default FacilitiesSchema
