import React, { useState } from 'react'

const InputCustomWithCheckBox = ({ register, changeHandler, getValues, checkboxControlName, checkboxHeight, controlName, type, maximumLength, width, labelTop, labelBottom, placeholder, error }) => {

    //const [disabled, setDisabled] = useState(getValues(checkboxControlName) == 1 ? false : true)
    if (checkboxHeight <= 24) {
        checkboxHeight = 24
    }
    return (
        <div>
            <div className='flex flex-col w-[100%] mb-6 '>
                <label className='font-bold mb-[8px]' htmlFor="title">{labelTop}</label>
                <div className=' flex'>
                    <div className=' w-[50px] flex items-center place-items-center border-[1px] border-gray-400/50 rounded-[4px] mr-1'>
                        <input
                            {...register(checkboxControlName, {
                                onChange: changeHandler
                            })}
                            className={`bg-gray-100 border-[1px]  border-gray-400/50 h-[${checkboxHeight}px] rounded-[8px] px-4 font-normal text-[17px] w-full bg-red-200`}
                            type={"checkbox"}
                            name={checkboxControlName}
                            placeholder={`${placeholder}`}

                        />
                    </div>
                    <div className=' relative w-full'>
                        <input
                            {...register(controlName, {
                                onChange: changeHandler
                            })}
                            className={`bg-gray-100 border-[1px]  border-gray-400/50 h-[35px] rounded-[8px] px-4 font-normal text-[17px] w-[${width}%]`}
                            type={"text"}
                            placeholder={`${placeholder}`}
                            maxLength={maximumLength}

                        />
                    </div>
                </div>

                {error && (<div className=' text-red-400 mt-[5px] text-[15px]'><span className=' text-red-500 font-bold'>{error.message}</span></div>)}

                <label className='font-normal text-[14px] mt-[8px] leading-[18px]'>
                    {labelBottom}
                </label>


            </div>
        </div>
    )
}

export default InputCustomWithCheckBox
