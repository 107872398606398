import { MapIcon, StarIcon } from '@heroicons/react/outline'
import React, { useContext } from 'react'
import { BsCopy, BsStar } from 'react-icons/bs'
import { CgWebsite } from 'react-icons/cg'
import { IoMapSharp } from 'react-icons/io5'
import { MdWebStories } from 'react-icons/md'
import { PiMapPinSimple, PiPhoneBold, PiPhoneCallBold, PiPhoneCallFill, PiPhoneFill, PiPhoneIncomingBold, PiWebcamSlashThin, PiWebhooksLogoFill } from 'react-icons/pi'
import { SiGooglemaps } from 'react-icons/si'
import { BusinessContext } from './BusinessContext'
import ClipBoardCopy2 from './ClipBoardCopy2'

const ContactIcons = () => {
    const { businessData } = useContext(BusinessContext)
    return (
        <div>
            <div className=' flex place-content-around lg:hidden'>
                <div>
                    <a href={`+tel:${businessData?.country_code} ${businessData?.mobile}`}>
                        <div className=' flex flex-col place-items-center w-fit'>
                            <div className=' w-fit p-[12px] border-[1px] border-gray-400 rounded-full'>
                                <PiPhoneCallFill
                                    className=' text-[20px]'
                                />

                            </div>
                            <span className=' text-[13px] text-blue-900'>Call</span>
                        </div>
                    </a>
                </div>

                <div>
                    <a href={businessData?.website ? `tel:${businessData?.website}` : '#'}>
                        <div className=' flex flex-col place-items-center w-fit hover:cursor-pointer'>
                            <div className=' w-fit p-[12px] border-[1px] border-gray-400 rounded-full'>
                                <PiWebhooksLogoFill
                                    className=' text-[20px]'
                                />

                            </div>
                            <span className=' text-[13px] text-blue-900'>Visit Website</span>
                        </div>
                    </a>
                </div>

                <div>
                    <a href={`https://www.google.com/maps/search/${businessData?.address_one}, ${businessData?.address_two}`}
                        className='text-[17px]   inline-block w-full hover:text-gray-400'
                    >
                        <div className=' flex flex-col place-items-center w-fit'>
                            <div className=' w-fit p-[12px] border-[1px] border-gray-400 rounded-full'>
                                <SiGooglemaps
                                    className=' text-[20px]'
                                />

                            </div>
                            <span className=' text-[13px] text-blue-900'>Map</span>
                        </div>
                    </a>
                </div>

                <div>
                    <a href="">
                        <div className=' flex flex-col place-items-center w-fit'>

                            <span className=' text-[13px] text-blue-900'><ClipBoardCopy2 /></span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ContactIcons
