import React from 'react'
import { BsArrowRight } from 'react-icons/bs'

function Subtitle({ title }) {
    return (
        <div className=' px-5 py-10'>
            <div className=' max-w-[1200px] w-full mx-auto'>
                <div className="flex items-center gap-2">
                    <hr className="flex-grow border-t-[1px] border-gray-900/60" />
                    <span className="text-3xl font-black text-center mx-1 leading-tight">{title}</span>
                    <hr className="flex-grow border-t-[1px] border-gray-900/60" />
                </div>
            </div>
        </div >
    )
}

export default Subtitle
