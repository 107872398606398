import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../../../context/GlobalContext"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import SettingsLeftNav from "../../components/SettingsLeftNav"
import SubHeaderNav from "../../components/SubHeaderNav"
import { ProfileContent } from "./ProfileContent"
import HeaderLightSearch from "../../../../components/header/headerLight/HeaderLightSearch"
import HeaderSpace from "../../../../components/header/HeaderSpace"
import Prefooter from "../../../../components/footer/Prefooter"
import Footer from "../../../../components/footer/Footer"

const Profile = () => {
    const { user, REACT_APP_API_URL, gcolors } = useContext(GlobalContext)
    const [loading, setLoading] = useState(true)
    const [userData, setUserData] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        const endpoint = REACT_APP_API_URL + "?operation=READ_USER&id=" + user.user_id
        //window.location.href = (endpoint)
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                console.log(res.data)
                //alert(res.data.data[0])
                const data = JSON.stringify(res.data.data[0])

                setUserData(data)
                setLoading(false)
                //alert(userData)
            })
    }, [setUserData])

    return (
        <div>
            <HeaderLightSearch
                bgColor={gcolors.headerMain}
                bgColorOnScroll={gcolors.headerMainScroll}
                color={"text-black"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />

            <HeaderSpace />

            <div className="mx-5">
                <div className=' max-w-[1200px] mx-auto w-full grid lg:grid-cols-12 gap-8'>
                    <div className='hidden lg:block lg:col-span-3'>
                        <SettingsLeftNav />
                    </div>
                    <div className=' lg:col-span-9'>

                        {
                            (userData) ?
                                <ProfileContent
                                    preloadedValues={userData}
                                    user={user}
                                /> : 'Waiting for profile data...'
                        }
                    </div>
                </div>
            </div>

            <HeaderSpace />

            <Prefooter />

            <Footer />
        </div>
    )
}

export default Profile