import React, { useEffect } from 'react'
import { useState, useContext } from 'react'
import { SearchIcon } from '@heroicons/react/outline'

import SignupSchema from './SignupSchema'
import { GlobalContext, GlobalSetterContext } from '../../context/GlobalContext'
import { useNavigate } from 'react-router-dom'
import { BiLogIn } from 'react-icons/bi'
import { IoSettingsOutline } from 'react-icons/io5'
import { MdOutlinePassword } from 'react-icons/md'
import { CgPassword, CgSpinner } from 'react-icons/cg'
import { GiPadlock } from 'react-icons/gi'
import AlertContext from '../../context/AlertContext'
import { useForm, input } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { UtilContext } from '../../context/UtilContext'


const initialValues = {
    email: "",
    password: ""
}

function SignupForm() {
    let { globalNav, toggler, authTokens, loginError,
        REACT_APP_API_URL
    } = useContext(GlobalContext)
    const { loginUser, loginUser2, setLoginError } = useContext(GlobalSetterContext)
    const [loading, setLoading] = useState(false)
    const { showAlert, closeAlert, timerRef, fadeOutAlertWithParam } = useContext(AlertContext)
    const [signupError, setSignupError] = useState(null)
    const [msg, setMsg] = useState(null)
    const [signedUp, setSignedUp] = useState(false)
    const LOCAL_FORM_STORE = 'XSIGNUPSTORE'
    const { getHostPortProtocol } = useContext(UtilContext)

    const getFormValues = () => {
        const storedValues = JSON.parse(localStorage.getItem(LOCAL_FORM_STORE))
        if (storedValues) {
            return storedValues
        } else {
            return initialValues
        }
    }

    const [formData, setFormData] = useState(getFormValues)

    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: (getFormValues()),
        resolver: zodResolver(SignupSchema)
    })


    const onSubmit = async (data) => {
        setLoading(true)
        setSignupError(null)
        let xdata = JSON.stringify(data)
        let xobj = JSON.parse(xdata)
        xobj["app_host"] = getHostPortProtocol(window)
        let errorReturned = ""

        //alert(xobj)
        let endpoint = REACT_APP_API_URL + '?operation=SIGNUP_USER'
        //console.log(JSON.stringify(xobj))
        endpoint += '&email=' + xobj["email"];
        endpoint += '&password=' + xobj["password"];
        endpoint += '&first_name=' + xobj["first_name"];
        endpoint += "&app_host=" + xobj["app_host"]
        //window.location.href = endpoint
        //alert(endpoint)
        //return false
        await new Promise((resolve) => setTimeout(resolve, 1000));

        let rst = axios.post(endpoint, xobj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(function (result) {

                setTimeout(function () {
                    setMsg("Success signup");

                    setLoading(false)
                    setSignedUp(true)
                }, 1000)
            })
            .catch(function (e) {
                console.log(e)
                //alert(e.response.data.data[0].error)

                setSignupError(e.response.data.data[0].error)
                setLoading(false)
            })
    }

    useEffect(() => {
        if (loginError) {
            showAlert("danger", "Invalid ID and/or password.")
            fadeOutAlertWithParam(timerRef, 5000)
            setLoading(false)
        }
    }, [loginError])

    useEffect(() => {
        let formState = null;

        if (localStorage.getItem(LOCAL_FORM_STORE)) {
            formState = JSON.parse(localStorage.getItem(LOCAL_FORM_STORE))
        }
        setFormData((prev) => ({ ...prev, ...formState }))
    }, [])

    useEffect(() => {
        localStorage.setItem(LOCAL_FORM_STORE, JSON.stringify(formData))
    }, [formData, LOCAL_FORM_STORE])



    return (
        <div>
            <form id='signup-form' onSubmit={handleSubmit(onSubmit)}>
                <div className=' flex flex-col place-content-center bg-white h-[100vh]'>



                    {
                        !signedUp &&
                        <div id='signup-div' className=' w-full'>
                            <div className=' text-center 
                        font-black text-black text-[35px]
                        mt-[20px] mb-[0px] tracking-tighter
                        '>
                                Signup
                            </div>
                            <div className=' text-center'>
                                Don't have an account? <Link to={"/login"} className=' underline font-bold'>Login</Link>
                            </div>
                            <div className=' text-center'>
                                <Link to={"/resetpw"} className=' underline font-bold'>Forgot Password?</Link>
                            </div>

                            <div className=' max-w-[1200px] mx-auto w-full mt-4
                         flex place-content-center place-items-center
                        '>

                                <div className=' 
                            flex justify-center place-items-center
                            gap-1 flex-col w-[250px]
                            
                            '>
                                    <input
                                        {...register("email", {
                                            onChange: changeHandler
                                        })}
                                        type="text"
                                        placeholder='Enter Email Address'
                                        className=' text-[15px] h-[40px] w-full px-3 
                                    outline-none  border-[1px] rounded-[4px]
                                    border-gray-500/30  bg-blue-50
                                    '

                                    />
                                    {errors.email && (<div className=' text-black mt-[-2px] leading-[12px] text-[15px] mb-2 p-1'>
                                        <small className=' text-black font-normal'>
                                            {errors.email.message}
                                        </small>
                                    </div>)}


                                    <input
                                        {...register("password", {
                                            onChange: changeHandler
                                        })}
                                        type="password"
                                        placeholder='Enter Password'
                                        className=' 
                                    text-[15px] h-[40px] w-full px-3 
                                    outline-none  border-[1px] rounded-[4px]
                                    border-gray-500/30  bg-blue-50
                                    '
                                    />
                                    {errors.password && (
                                        <div className=' text-black mt-[-2px] leading-[12px] text-[15px] mb-2 p-1'>
                                            <small className=' text-black font-normal'>
                                                {errors.password.message}
                                            </small>
                                        </div>)}


                                    <input
                                        {...register("first_name", {
                                            onChange: changeHandler
                                        })}
                                        type="text"

                                        placeholder='Enter First Name'
                                        className=' 
                                    text-[15px] h-[40px] w-full px-3 
                                    outline-none  border-[1px] rounded-[4px]
                                    border-gray-500/30  bg-blue-50
                                    '
                                    />
                                    {errors.first_name && (
                                        <div className=' text-black mt-[-2px] leading-[12px] text-[15px] mb-2 p-1'>
                                            <small className=' text-black font-normal'>
                                                {errors.first_name.message}
                                            </small>
                                        </div>)}


                                    <button
                                        className=' 
                                    bg-green-800 py-[8px] w-full
                                    flex justify-center 
                                    place-items-center
                                    rounded-[4px]
                                    '
                                        type='submit'
                                    >
                                        {
                                            loading ?
                                                <CgSpinner className=' w-8 h-7 text-white animate-spin' />
                                                : <GiPadlock className=' w-8 h-7 text-white' />
                                        }
                                    </button>
                                </div>

                            </div>

                        </div>
                    }

                    {signedUp && <div className=' flex flex-col place-items-center'>

                        <div className=' bg-white w-[500px] rounded-[8px]'>
                            <div className='text-[30px] text-center font-black text-gray-700 border-b px-5 pt-6 pb-4'>
                                Success!
                            </div>
                            <div className=' px-5 pt-4 pb-5'>
                                <div className=' leading-4 text-sm'>
                                    You have successfully signed up. Please check your email to continue.
                                </div>
                                <div className=' mt-2'>
                                    Go to &nbsp;
                                    <a href="/login" className=' underline'>
                                        <span>
                                            Login
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>}

                    {
                        signupError && <div className=' flex flex-col place-items-center  mt-[2px]'>
                            <div className=' flex bg-red-600 pt-3 pb-4 px-3 rounded-md gap-1 w-[250px] text-white place-items-center'>

                                <div className='flex flex-col place-content-center'>
                                    <span>{signupError}</span>
                                </div>
                            </div>
                        </div>
                    }


                </div>


            </form>

        </div >
    )
}

export default SignupForm
