import React from 'react'
import LeftTop from './LeftTop'

const LeftColumn = () => {
    return (
        <div>
            <LeftTop />
        </div>
    )
}

export default LeftColumn
