import React, { useEffect } from 'react'
import { useState, useContext } from 'react'
import { SearchIcon } from '@heroicons/react/outline'

import LoginSchema from './LoginSchema'
import { GlobalContext, GlobalSetterContext } from '../../context/GlobalContext'
import { useNavigate } from 'react-router-dom'
import { BiLogIn } from 'react-icons/bi'
import { IoSettingsOutline } from 'react-icons/io5'
import { MdOutlinePassword } from 'react-icons/md'
import { CgPassword, CgSpinner } from 'react-icons/cg'
import { GiPadlock } from 'react-icons/gi'
import AlertContext from '../../context/AlertContext'
import { useForm, input } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link } from 'react-router-dom'


const initialValues = {
    email: "",
    password: ""
}


function LoginForm() {
    let { globalNav, toggler, authTokens, loginError } = useContext(GlobalContext)
    const { loginUser, loginUser2, setLoginError } = useContext(GlobalSetterContext)
    const [loading, setLoading] = useState(false)
    const { showAlert, closeAlert, timerRef, fadeOutAlertWithParam } = useContext(AlertContext)
    const [formData, setFormData] = useState(initialValues)
    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const getFormValues = () => {
        return initialValues
    }

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: (getFormValues()),
        resolver: zodResolver(LoginSchema)
    })


    const doLogin = async (values) => {

        showAlert("info", "Working...")
        setLoginError(null)
        setLoading(true)

        let email = values.email
        let password = values.password
        //await new Promise((resolve) => setTimeout(resolve, 1000));
        //loginUser(email, password)
        //alert("here")

        setTimeout(function () {
            loginUser2(values)
        }, 1000)

    }

    useEffect(() => {
        if (loginError) {
            showAlert("danger", "Invalid ID and/or password.")
            fadeOutAlertWithParam(timerRef, 5000)
            setLoading(false)
        }
    }, [loginError])



    return (
        <div>
            <div className=' flex flex-col place-content-center bg-white h-[100vh]'>



                <div className=' w-full'>
                    <div className=' text-center 
                        font-black text-black text-[35px]
                        mt-[20px] mb-[0px] tracking-tighter
                        '>
                        Login
                    </div>
                    <div className=' text-center'>
                        Don't have an account? <Link to={"/signup"} className=' underline font-bold'>Sign up</Link>
                    </div>
                    <div className=' text-center'>
                        <Link to={"/resetpw"} className=' underline font-bold'>Forgot Password?</Link>
                    </div>
                    <form onSubmit={handleSubmit(doLogin)}>
                        <div className=' max-w-[1200px] mx-auto w-full mt-4
                         flex place-content-center place-items-center
                        '>

                            <div className=' 
                            flex justify-center place-items-center
                            gap-1 flex-col w-[250px]
                            
                            '>
                                <input
                                    {...register("email", {
                                        onChange: changeHandler
                                    })}
                                    type="text"
                                    placeholder='Enter Email Address'
                                    className=' text-[17px] w-full px-3 
                                    outline-none py-[5px] border-[1px] rounded-[8px]
                                    border-gray-500/30  bg-blue-50
                                    '

                                />

                                <input
                                    {...register("password", {
                                        onChange: changeHandler
                                    })}
                                    type="password"
                                    placeholder='Enter Password'
                                    className=' 
                                    text-[17px] w-full px-3 
                                    outline-none py-[5px] border-[1px] rounded-[8px]
                                    border-gray-500/30  bg-blue-50
                                    '
                                />


                                <button
                                    className=' 
                                    bg-green-800 py-[8px] w-full
                                    flex justify-center 
                                    place-items-center
                                    rounded-[8px]
                                    '
                                    type='submit'
                                >
                                    {
                                        loading ?
                                            <CgSpinner className=' w-8 h-7 text-white animate-spin' />
                                            : <GiPadlock className=' w-8 h-7 text-white' />
                                    }
                                </button>

                                {errors.email && <div className='
                                text-[15px] text-red-700
                                text-center
                                '>
                                    {errors.email.message}
                                </div>}

                                {errors.password && <div className='
                                leading-4 text-[14px] 
                                text-red-700 text-center
                                '>
                                    {errors.password.message}
                                </div>}
                            </div>

                        </div>
                    </form>
                </div>



            </div>

        </div>
    )
}

export default LoginForm
