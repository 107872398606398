import { useContext, useState } from "react"
import { UtilContext } from "../../../../context/UtilContext"
import { GlobalContext } from "../../../../context/GlobalContext"
import AlertContext from "../../../../context/AlertContext"
import axios from "axios"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import ChangeEmailSchema from "./ChangeEmailSchema"
import { CgSpinner } from "react-icons/cg"
import WorkingButton from "../../components/WorkingButton"
import GreenButton from "../../components/GreenButton"
import GalleryPicUpload from "./GalleryPicUpload"
import DisplayBusinessGallery from "./DisplayBusinessGallery"
import GrayButton from "../../components/GrayButton"
import GalleryPreview from "./GalleryPreview"
import PageTitle from "../../components/PageTitle"

export const PhotoGalleryUpload = ({ user, userData, businessData, businessGalleryData }) => {
    const parsedPreloadedValue = (JSON.parse(userData))
    const [formData, setFormData] = useState(parsedPreloadedValue)
    const [loading, setLoading] = useState(false)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const [working, setWorking] = useState(false)
    const [successMsg, setSuccessMsg] = useState(null)

    const { getSubstr } = useContext(UtilContext)

    const { showAlert, timerRef, closeAlert, fadeOutAlert,
        fadeOutAlertWithParam
    } = useContext(AlertContext)

    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const changeEmail = async (data) => {
        setWorking(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));

        let endpoint = REACT_APP_API_URL + "?operation=EMAIL_CHANGE_REQUEST"


        const res = await axios.post(endpoint, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(function (rsp) {

            //let err = (rsp.response)
            //console.log(rsp.data.data[0].request_status)
            showAlert("success", "Success: Check your email")
            fadeOutAlertWithParam(timerRef, 5000)
            setWorking(false)
            setSuccessMsg("An email has been sent to the email provided. Click on the link in the email to continue.")
            //setMsg("An email has been sent to the email provided. Click on the link on the email to continue.")
            //setWorking(false)
        }).catch((rsp) => {
            console.log(rsp)

            let err = (rsp.response.data.data[0].error)
            showAlert("danger", getSubstr(err, 40))
            fadeOutAlertWithParam(timerRef, 20000)
            setWorking(false)
            setError("root", {
                type: "manual",
                message: err,
            })

        })
    }
    const onSubmit = async (data) => {
        setSuccessMsg(null)
        showAlert("info", "Working...")
        changeEmail(data)


    }



    const {
        register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors, isSubmitting, value },
    } = useForm({
        defaultValues: (parsedPreloadedValue),
        resolver: zodResolver(ChangeEmailSchema)
    })
    return (
        <div className=' mt-6'>

            <div className=" border-b-[1px] border-b-gray-500/50 pb-[16px] grid grid-cols-2">
                <PageTitle title={"Photo Gallery"} />

                <div className="flex place-content-end place-items-center space-x-1
                flex-wrap leading-9
                ">



                    <GrayButton
                        title={"Business Profile"}
                        url={"/account/business-profile/" + JSON.parse(businessData)?.business_id}
                    />


                    <GalleryPreview imagex={businessGalleryData} />
                </div>
            </div>



            <div className=' h-[32px]'></div>

            <div className='bg-[#D0D9CD]/60 border-[1px] border-gray-500/30 
            rounded-[10px] px-[16px] py-[22px] shadow-md'>

                {parsedPreloadedValue !== null &&
                    <GalleryPicUpload
                        businessData={JSON.parse(businessData)}
                        userData={userData}

                    />
                }
            </div>

            <div className=' mt-8'>
                {
                    businessGalleryData &&
                    <DisplayBusinessGallery
                        businessGalleryData={businessGalleryData}
                    />
                }
            </div>
        </div>
    )
}