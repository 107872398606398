import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../../../context/GlobalContext'
import axios from 'axios'
import { CgSpinner } from 'react-icons/cg'

const UploadPic = ({ businessData }) => {

    const [xfile, setxFile] = useState(null)
    const [myFile, setMyFile] = useState(null)
    const [msg, setMsg] = useState('')
    const { gcolors } = useContext(GlobalContext)
    const { user,
        REACT_APP_API_UPLOADS_URI,
        REACT_APP_API_URL,
        REACT_APP_IMG_PATH,
        REACT_APP_IMG_API_URL
    } = useContext(GlobalContext)
    const [working, setWorking] = useState(false)

    function selectFile() {
        document.getElementById('mfile').click()
    }

    async function uploadFile() {
        setWorking(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const url = REACT_APP_IMG_API_URL + '?operation=UPLOAD_BUSINESS_PICTURE'

        const data = new FormData()
        data.append('file', myFile)
        data.append('user_id', user.user_id)
        data.append('business_id', businessData.business_id)

        try {
            let rst = axios.post(url, data)
                .then(function (response) {
                    setMsg(response.data);
                    setWorking(false)
                    setMyFile(null)
                })
                .catch(function (e) {
                    setMsg(e.response.data)
                    setWorking(false)
                })


        } catch (e) {

        }


    }

    function setFile(event) {

        if (event.target.files[0]) {
            setMyFile(event.target.files[0])
            setxFile(URL.createObjectURL(event.target.files[0]))

        }



    }

    function getImage2() {

    }

    function getImage() {

        if (xfile) {
            return xfile
        }
        if (businessData?.img) {
            return REACT_APP_API_UPLOADS_URI + businessData?.img
        }
        return REACT_APP_IMG_PATH + "/img/st.png"
    }


    useEffect(() => {

    })


    return (
        <div className={`flex flex-col place-content-center 
        place-items-center bg-[#D0D9CD]/40 py-[20px] rounded-md
        border-[1px] border-gray-500/30 shadow-lg`}>
            <div className=' relative rounded-full overflow-hidden w-[200px] h-[200px] border-[1px] border-gray-500/60 shadow-lg'>
                <img
                    className=' object-cover w-full h-full'
                    src={getImage()} alt="" />
            </div>

            <div className='flex gap-2 mt-5'>
                <input type="file" name='mfile' id='mfile' className='hidden w-[200px]' onChange={(e) => setFile(e)} />

                <div>
                    <button
                        type='button'
                        className='
                        bg-gray-300/70 px-5 
                        py-[4px] border-[1px] border-gray-500/50 
                        rounded-[5px]
                         hover:bg-gray-300/40
                        '
                        onClick={() => selectFile()}
                    >
                        Select
                    </button>
                </div>
                <div>
                    <button
                        type='button'
                        onClick={() => uploadFile()}
                        className=' bg-gray-300/70 px-5 
                        py-[4px] border-[1px] border-gray-500/50 
                        rounded-[5px]
                         hover:bg-gray-300/40'>
                        {
                            working ? <CgSpinner className=' w-7 h-6 text-black animate-spin' /> : "Upload"
                        }
                    </button>
                </div>
            </div>
            <div className=' mt-[10px]  text-center'>
                <p className=' w-[100%] leading-4'><b>{msg}</b></p>
            </div>
        </div>
    )
}

export default UploadPic
