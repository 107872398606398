import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'




const phoneRegex = new RegExp(/^(?:\+1)?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/);

const OperatingTimeSchema = z.object({
    monday_from: z.any(),
    tuesday_from: z.any(),
    wednesday_from: z.any(),
    thursday_from: z.any(),
    friday_from: z.any(),
    saturday_from: z.any(),
    sunday_from: z.any(),
    monday_to: z.any(),
    tuesday_to: z.any(),
    wednesday_to: z.any(),
    thursday_to: z.any(),
    friday_to: z.any(),
    saturday_to: z.any(),
    sunday_to: z.any(),
    /* rating_comment: z.string()
        .min(10, { message: "Comment must not be less than 10 characters" })
        .max(400, { message: "Intro message must not be more than 400 characters" }), */
    /* rating: z.string()
        .min(1, { message: "Please select a rating" }), */
})

export default OperatingTimeSchema
