import React, { useContext, useEffect, useState } from 'react'
import { BsEyeFill } from 'react-icons/bs'
import { GlobalContext } from '../../context/GlobalContext'
import axios from 'axios'
import { UtilContext } from '../../context/UtilContext'

const VisitorViewCount = ({ businessId, size }) => {

    const [visitorViews, setVisitorViews] = useState(null)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const { shortenNumbers } = useContext(UtilContext)

    const getBusinessData = (username) => {
        const endpoint = REACT_APP_API_URL + "?operation=GET_HITRATE_BY_BUSINESS_ID&business_id=" + businessId
        //window.location.href = (endpoint)
        //return false

        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                console.log(res)
                const data = JSON.stringify(res.data.data[0])
                //alert(data)
                setVisitorViews((JSON.parse(data)))

                //alert(businessData)
            }).catch((res) => {
                console.log(res)
                //alert("here")
            })
    }

    useEffect(() => {
        getBusinessData(businessId)
    }, [])

    useEffect(() => {
        if (visitorViews) {
            //console.log(visitorViews.hits)
        }
    }, [visitorViews])



    return (
        <div className=' flex place-items-center gap-1'>
            <div className='text-green-900'>
                <BsEyeFill size={size ? size : 35} />
            </div>
            <div>
                {shortenNumbers(visitorViews?.hits)}
            </div>
        </div>
    )
}

export default VisitorViewCount
