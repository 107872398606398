import { useContext, useEffect, useState } from "react"
import { UtilContext } from "../../../../context/UtilContext"
import { GlobalContext } from "../../../../context/GlobalContext"
import AlertContext from "../../../../context/AlertContext"
import axios from "axios"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import ChangeEmailSchema from "./ChangeEmailSchema"
import { CgSpinner } from "react-icons/cg"
import WorkingButton from "../../components/WorkingButton"
import GreenButton from "../../components/GreenButton"
import GrayButton from "../../components/GrayButton"
import PageTitle from "../../components/PageTitle"

export const EmailForm = ({ user, preloadedValues }) => {
    const parsedPreloadedValue = (JSON.parse(preloadedValues))
    const [formData, setFormData] = useState(parsedPreloadedValue)
    const [loading, setLoading] = useState(true)
    const { REACT_APP_API_URL,
    } = useContext(GlobalContext)
    const [userData, setUserData] = useState(null)
    const [working, setWorking] = useState(false)
    const [successMsg, setSuccessMsg] = useState(null)

    const { getSubstr, getHostPortProtocol } = useContext(UtilContext)

    const { showAlert, timerRef, closeAlert, fadeOutAlert,
        fadeOutAlertWithParam
    } = useContext(AlertContext)

    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const changeEmail = async (data) => {
        setWorking(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));

        let endpoint = REACT_APP_API_URL + "?operation=EMAIL_CHANGE_REQUEST"

        data["host"] = getHostPortProtocol(window)
        //alert(JSON.stringify(data))
        const res = await axios.post(endpoint, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(function (rsp) {

            //let err = (rsp.response)
            //console.log(rsp.data.data[0].request_status)
            showAlert("success", "Success: Check your email")
            fadeOutAlertWithParam(timerRef, 5000)
            setWorking(false)
            setSuccessMsg("An email has been sent to the email provided. Click on the link in the email to continue.")
            //setMsg("An email has been sent to the email provided. Click on the link on the email to continue.")
            //setWorking(false)
        }).catch((rsp) => {
            console.log(rsp)

            let err = (rsp.response.data.data[0].error)
            showAlert("danger", getSubstr(err, 40))
            fadeOutAlertWithParam(timerRef, 20000)
            setWorking(false)
            setError("root", {
                type: "manual",
                message: err,
            })

        })
    }
    const onSubmit = async (data) => {

        setSuccessMsg(null)
        showAlert("info", "Working...")
        changeEmail(data)


    }



    const {
        register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors, isSubmitting, value },
    } = useForm({
        defaultValues: (parsedPreloadedValue),
        resolver: zodResolver(ChangeEmailSchema)
    })


    useEffect(() => {
        setLoading(false)
    }, [])



    return (
        <div className=' mt-6'>
            <div className=" border-b-[1px] border-b-gray-500/50 pb-[16px] grid grid-cols-2">
                <PageTitle title={"Email"} />

                <div className="flex place-content-end place-items-center space-x-1 ">



                    <GrayButton
                        title="Go to home"
                        url="/account/home" />



                </div>
            </div>

            <div className=' h-[32px]'></div>

            <div className=' border-[1px] border-gray-500/30 rounded-[10px] px-[16px] py-[22px]'>

                <div className=' flex gap-3 font-bold text-xl'>
                    <div>
                        {parsedPreloadedValue.email}
                    </div>
                    <div>-</div>
                    <div className=' text-orange-300'>
                        Primary
                    </div>
                </div>
                <div className=' text-sm mt-2'>

                    This email will be used for account-related notifications and can also be used for password resets
                </div>

                <div className=' mt-5'>
                    <ul className=' list-outside list-disc ml-5 space-y-2'>
                        <li>
                            <div className=' font-semibold'>
                                Visible in emails
                            </div>
                            <ul className=' list-outside'>
                                <li>
                                    This email may be used as the 'author' or 'committer' of changes to this account and businesses and assets owned by this account.
                                </li>
                            </ul>
                        </li>

                        <li>
                            <div className=' font-semibold'>
                                Receives notifications
                            </div>
                            <ul className=' list-outside'>
                                <li>
                                    This email address is the default used for Skyepages notifications, i.e., replies to issues, etc.                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <div className=' mt-8'>
                <form id='cef' action={REACT_APP_API_URL + "?operation=EMAIL_CHANGE_REQUEST"} onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className='flex flex-col w-[100%] mb-6'>
                            <label className='font-bold mb-[8px]' htmlFor="title">Primary Email Address</label>
                            <div className=' mb-2'>
                                {parsedPreloadedValue.email} will be used for account-related notifications and can be used for password resets.
                            </div>
                            <input
                                {...register("email", {
                                    onChange: changeHandler
                                })}
                                className=' bg-gray-100 border-[1px] border-gray-400/50 
                                h-[35px] rounded-[8px] px-4 font-normal 
                                text-[17px] w-[50%]'
                                type="text"
                            />
                            {errors.email && <div className='text-red-400 mt-[5px] text-[15px] font-bold'>{errors.email.message}</div>}
                            {errors.root && <div className='text-red-400 mt-[5px] text-[15px] font-bold'>{errors.root.message}</div>}
                            {successMsg &&
                                <div className='text-green-700 mt-[5px] text-[15px] font-bold border-[1px] border-green-800/40 p-[8px] rounded-[8px] w-[50%] bg-green-500/20 leading-5 '>
                                    {successMsg}
                                </div>}

                            <div className='font-normal text-[14px] mt-[8px] leading-[18px] w-[70%]'>
                                Ensure that your new email is in use before attempting to initiate a change. You will a link on your new email. Click on the link to continue.
                            </div>
                        </div>


                    </div>

                    {/* <input type="hidden" name="operation" value="EMAIL_CHANGE_REQUEST" /> */}

                    <div className='flex flex-col w-[100%] mb-6'>

                        <div className=''>

                            <GreenButton title="Update email" working={working} />

                        </div>
                    </div>


                </form>
            </div>
        </div>
    )
}