import React, { useContext } from 'react'
import Header from '../../components/header/Header'
import HeroAnimated from '../../components/body/HeroAnimated'
import Hero from '../../components/body/Hero'
import Prefooter from '../../components/footer/Prefooter'
import Footer from '../../components/footer/Footer'
import Subtitle from '../../components/body/Subtitle'
import TopIntro from '../../components/body/TopIntro'
import ClientResult from '../../components/body/ClientResult'
import WhatWeDo from '../../components/body/WhatWeDo'
import HowWeCanHelp from '../../components/body/HowWeCanHelp'
import Impact from '../../components/body/Impact'
import Insights from '../../components/body/Insights'
import HorizontalSpce from '../../components/body/HorizontalSpace'

import PostHeroBar from '../../components/body/PostHeroBar'
import H16 from '../../components/body/H16'
import H32 from '../../components/body/H32'
import H64 from '../../components/body/H64'
import Products from '../../components/body/Products'
import PostHero from '../../components/body/PostHero'
import SearchResult from './SearchResult'
import SearchForm from '../../components/body/SearchForm'
import HeaderLight from '../../components/header/headerLight/HeaderLight'
import HeaderLightSearch from '../../components/header/headerLight/HeaderLightSearch'
import HeaderSpace from '../../components/header/HeaderSpace'
import SubNav from '../business/SubNav'
import SubNavFlex from '../../components/body/subnavflex/SubNavFlex'
import TopBanner from '../../components/ads/TopBanner'
import { GlobalContext } from '../../context/GlobalContext'

function Search() {
    const { gcolors } = useContext(GlobalContext)
    const insights = [
        {
            id: 1,
            category: "Mergers and Acquisitions",
            title: "How Companies Got So Good at M&A",
            description: "What 20 years in the trenches have taught us about deal success",
            ftrcategory: "Minutes",
            img: "img/baien.jpg"
        },
        {
            id: 2,
            category: "Telecommunications",
            title: "Generative AI in M&A: Where Hope Meets Hype",
            description: "Are you already behind the curve in artificial intelligence?",
            ftrcategory: "M&A Report",
            img: "img/runner.jpg"
        },
        {
            id: 3,
            category: "Blockchain",
            title: "Telecom M&A: Here Are the Latest Deal Trends Worldwide",
            description: "Telecommunications M&A deal value spiked in the first quarter, as some telcos increase scale and others expand in adjacent sectors such as finance and insurance.",
            ftrcategory: "Interactive",
            img: "img/ladyb.jpg"
        },
        {
            id: 4,
            category: "Artificial Intelligence",
            title: "A UK Perspective on Bain’s 2024 Global M&A Report",
            description: "Bain experts Peter Horsley and Suzanne Kumar delve into key global and UK industry insights from our latest M&A Report.",
            ftrcategory: "Webinar",
            img: "img/ccar.jpg"
        },
    ]

    const topIntros = [
        {
            title: "Customer AI Design Needs",
            description: "A proven way to transform the customer experience at scale. Focus on earning the passionate loyalty of customers.",
        },
        {
            title: "AI Conventional Systems",
            description: "A proven way to transform the customer experience at scale. Focus on earning the passionate loyalty of customers, while inspiring the energy, enthusiasm and creativity of employees to accelerate desirable profit.",
        },
        {
            title: "Machine Learning Algos",
            description: "A proven way to transform the customer experience at scale. Focus on earning the passionate loyalty of customers, while inspiring the energy.",
        },
    ]
    const experience = [
        {
            id: 1,
            url: "https://www.bain.com/contentassets/6af90607571a41709dc053527225e622/23071-cruiseco-2-1_1.jpg?width=350&height=350&mode=crop",
            category: "Retail",
            title: "With Sophisticated Customer Segmentation, a Travel Company Sets Sail",
            description: "Identifying a target customer group and brand positioning helped our client to disrupt the cruising industry with a bold new offering.",
        },
        {
            id: 2,
            url: "https://www.bain.com/contentassets/10a46e696c6a49b5b58eb86dda32301c/24928-insuranceco-1_1.jpg?width=350&height=350&mode=crop",
            category: "Insurance",
            title: "Cost & Transparency Helps Insurer Strengthen Tech Expense Management Capability",
            description: "With Sophisticated Customer Segmentation, a Travel Company Sets Sail Identifying a target customer group and brand",
        },
    ]
    const ourhelp = [
        {
            id: 1,
            title: "Artificial Intelligence Strategy"
        },
        {
            id: 2,
            title: "Machine Learning Capability"
        },
        {
            id: 3,
            title: "Development"
        },
        {
            id: 4,
            title: "Post-Golive Integration"
        },
    ]
    const whatwedo = "Storcs understand the seismic forces reshaping business for defense contractors today. Amid extraordinary budgetary pressures, new entrants are emerging around the globe as corporate consolidation continues across the industry. While capital is sometimes abundant, it is harder than ever to find and harness the right talent. To thrive, established companies must become more agile, getting products to market faster than ever—customers increasingly expect this, and at lower cost. We can help you adapt to these trends, with a proven track record built on successful engagements with virtually every A&D prime and dozens of Tier I/II suppliers.";

    const impacts = [
        {
            id: 1,
            figure: "10,000+",
            description: "Number of M&A related projects completed"
        },
        {
            id: 2,
            figure: "25%",
            description: "Average additional value that companies using Bain for integration support realize compared to those that don’t"
        },
    ]

    return (
        <div>
            <HeaderLightSearch
                bgColor={"bg-transparent"}
                bgColorOnScroll={"bg-white"}
                logoColor={"text-[#fff]"}
                logoTextColor={"text-[#4b0d96]"}
                logoTextColorOnScroll={"text-[#4b0d96]"}
                logoColorOnScroll={"text-[#fff]"}
                logoBgColor={"bg-[#4b0d96]"}
                logoBgColorOnScroll={"bg-[#4b0d96]"}
                color={"text-black"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />
            <HeaderSpace />
            <SubNavFlex />

            <H16 />
            <TopBanner
                dataAdSlot={'3148685431'}
                dataAdFormat={'auto'}
                dataFullWidthResponsive={true}
            />

            <div className=' px-4'>
                <SearchResult insightsData={insights} />
                <H64 />
            </div>



            <HeaderSpace />
            <Prefooter />
            <Footer />
        </div>
    )
}

export default Search
