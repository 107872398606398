import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { SearchIcon } from '@heroicons/react/outline'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { BiLogIn } from 'react-icons/bi'
import ResetPwdSchema from './ResetPwdSchema'
import { GlobalContext, GlobalSetterContext } from '../../context/GlobalContext'
import axios from 'axios'
import { IoSettingsOutline } from 'react-icons/io5'
import { CgSpinner } from 'react-icons/cg'


const initialValues = {
    email: "",
    first_name: "",
    password: "",
    password2: ""
}

function ResetPwdForm() {
    const [loading, setLoading] = useState(false)
    const LOCAL_FORM_STORE = 'XSIGNUPSTORE'
    const navigate = useNavigate()
    const [msg, setMsg] = useState(null)
    const [errormsg, setErrorMsg] = useState(null)
    const { REACT_APP_API_URL, user } = useContext(GlobalContext)
    const { logoutUserWithoutRedirect } = useContext(GlobalSetterContext)
    const [loaded, setLoaded] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [userId, setUserId] = useState(null)
    const [requestId, setRequestId] = useState(null)


    const getFormValues = () => {
        return initialValues
    }
    const [formData, setFormData] = useState(getFormValues)

    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: (getFormValues()),
        resolver: zodResolver(ResetPwdSchema)
    })

    const resetPw = (data) => {
        let xdata = JSON.stringify(data)
        let xobj = JSON.parse(xdata)
        xobj["user_id"] = userId
        xobj["request_id"] = requestId



        let endpoint = REACT_APP_API_URL + "?operation=RESET_PASSWORD"
        endpoint += "&user_id=" + userId
        endpoint += "&request_id=" + requestId
        endpoint += "&pwd=" + xobj["password"]


        //window.location.href = endpoint

        //return true

        //return false


        let rst = axios.post(endpoint, xobj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(function (response) {

                setTimeout(function () {
                    setMsg("Your password reset was successful.");
                    setLoading(false)
                    localStorage.setItem(LOCAL_FORM_STORE, null)
                    document.getElementById("add-business-form").reset()
                }, 3000)
            })
            .catch(function (e) {

                setLoading(false)
                setErrorMsg(e.response.data.data[0].error)
            })
    }

    const onSubmit = async (data) => {
        setMsg(null)
        setErrorMsg(null)
        setLoading(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        resetPw(data)
    }

    useEffect(() => {
        let formState = null;

        if (localStorage.getItem(LOCAL_FORM_STORE)) {
            formState = JSON.parse(localStorage.getItem(LOCAL_FORM_STORE))
        }
        setFormData((prev) => ({ ...prev, ...formState }))
    }, [])


    useEffect(() => {
        localStorage.setItem(LOCAL_FORM_STORE, JSON.stringify(formData))
    }, [formData, LOCAL_FORM_STORE])


    useEffect(() => {
        setLoaded(true)
    }, [setLoaded])

    useEffect(() => {
        if (loaded) {
            let userIds = searchParams.get("id")
            let requestIds = searchParams.get("request_id")
            setUserId(userIds)
            setRequestId(requestIds)
        }

    }, [loaded, setUserId, setRequestId])


    useEffect(() => {
        if (user) {

            logoutUserWithoutRedirect()
        }
    }, [user])

    return (
        <div>
            <form id='add-business-form' onSubmit={handleSubmit(onSubmit)}>
                <div className=' flex flex-col justify-center mt-4 place-content-center place-items-center'>
                    <div className=' grid grid-cols-1 justify-center 
                    place-items-center gap-1 w-[250px]'>

                        <input
                            {...register("password", {
                                onChange: changeHandler
                            })}
                            type="password"


                            placeholder='Enter Password'
                            className=' h-[40px] w-[250px] px-3 outline-none rounded-md
                            border-[1px] border-[#327456]/30 text-[16px]
                            '
                        />
                        {errors.password && (<div className=' text-black mt-[-2px]'>
                            <div className=' text-black font-normal leading-[14px]
                            text-[13px] my-[6px] pl-[2px]'>
                                {errors.password.message}
                            </div>
                        </div>)}


                        <input
                            {...register("password2", {
                                onChange: changeHandler
                            })}
                            type="password"


                            placeholder='Confirm Password'
                            className=' h-[40px] w-[250px] px-3 outline-none rounded-md
                            border-[1px] border-[#327456]/30 text-[16px] '
                        />
                        {errors.password2 && (<div className=' text-black mt-[-2px]'>
                            <div className=' text-black font-normal leading-[14px]
                            text-[13px] my-[6px] pl-[2px]'>
                                {errors.password2.message}
                            </div>
                        </div>)}

                        <button
                            className={`${loading && 'bg-red-700 hover:bg-red-700'} hover:bg-green-800 text-white bg-[#327456] h-[40px] w-[250px] rounded-[4px] flex justify-center place-items-center`}
                            type='submit'
                        >
                            {loading ? <CgSpinner className=' w-8 h-7 text-white animate-spin' /> : <BiLogIn className=' w-7 h-7 text-white' />}
                        </button>

                        {

                            msg && <div className=' flex bg-[#327456]/20 text-black mt-[-2px] py-3 px-3 rounded-[4px] gap-2 w-[250px] place-content-center
                            border-[1px] border-[#327456]/20
                            '>

                                <div className=' place-content-center text-[15px]'>
                                    <div className=' flex '>
                                        <span>
                                            {msg} <Link
                                                className=' underline'
                                                to={"/login"}>
                                                Login
                                            </Link>
                                        </span>

                                    </div>

                                </div>
                            </div>
                        }
                        {
                            errormsg && <div className=' flex bg-red-600 mt-[-2px] py-3 px-3 rounded-[4px] gap-1 w-[250px] place-content-center text-white '>

                                <div className='flex flex-col place-content-center text-sm'>
                                    <span>{errormsg}</span>
                                </div>
                            </div>
                        }
                    </div>

                </div>


            </form>

        </div>
    )
}

export default ResetPwdForm
