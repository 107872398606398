import { useContext, useState } from "react"
import { GlobalContext } from "../../../../context/GlobalContext"
import { useNavigate } from "react-router-dom"
import AlertContext from "../../../../context/AlertContext"
import axios from "axios"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import UpdateBusinessSchema from "./UpdateBusinessSchema"
import InputCustom from "../../components/InputCustom"
import UploadPic from "./UploadPic"
import CountryCodeWithMobile from "../../components/CountryCodeWithMobile"
import CountryDropDown from "../../components/CountryDropDown"
import countryjson from '../../../../components/json/country_locale.json'
import TextAreaCustom from '../../components/TextAreaCustom'
import BusinessCategory from "./BusinessCategory"
import categories from '../../json/businessCategories.json'
import SocialMediaInput from "../../components/SocialMediaInput"
import GreenButton from "../../components/GreenButton"
import GrayButton from "../../components/GrayButton"
import PageTitle from "../../components/PageTitle"

export const BusinessProfileContent = ({ preloadedValues, user, businessId }) => {


    const parsedPreloadedValue = (JSON.parse(preloadedValues))
    if (parsedPreloadedValue["email"] == null || parsedPreloadedValue["email"] == '') {
        parsedPreloadedValue["email"] = user?.email
    }
    const [formData, setFormData] = useState(parsedPreloadedValue)
    const [loading, setLoading] = useState(false)
    const [working, setWorking] = useState(false)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const { showAlert, timerRef, closeAlert, fadeOutAlert } = useContext(AlertContext)
    const navigate = useNavigate()
    const [msg, setMsg] = useState()

    function getFormValues() {

        return parsedPreloadedValue
    }

    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const getTimeZone = async (json, country) => {

        let country_zone = 0
        await json.map((item) => {
            if (item.countryName === country) {
                country_zone = item.zone
            }
        })
        return country_zone
    }

    const updateBusiness = async (data) => {

        setWorking(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        let xdata = JSON.stringify(data)
        let xobj = JSON.parse(xdata)
        xobj["user_id"] = user.user_id

        let timezone = await getTimeZone(countryjson, xobj["country"])

        xobj["timezone"] = timezone


        const endpoint = REACT_APP_API_URL + "?operation=EDIT_BUSINESS"

        const res = await axios.post(endpoint, xobj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((rsp) => {

            showAlert("success", "Update Successful.")

            setTimeout(() => {
                showAlert("info", "Reloading...")

                setTimeout(() => {
                    window.location.href = ("/account/business-profile/" + businessId)
                }, 2000)

            }, 1000)


        }).catch((rsp) => {

            let err = (rsp.response.data.data[0].error)
            showAlert("danger", "Error during update")
            fadeOutAlert(timerRef)
            setWorking(false)
            console.log(rsp)
        })
    }

    const onSubmit = async (data) => {
        showAlert("info", "Working...")
        let status = await updateBusiness(data)
        //alert(JSON.stringify(data))

    }



    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: (parsedPreloadedValue),
        resolver: zodResolver(UpdateBusinessSchema)
    })

    function countWords(inputId, outputId) {

        let previousCharacter = ""


        // Get the input text value
        let text = document.getElementById(inputId).value;


        // Initialize the word counter
        let numWords = 0;

        // Loop through the text
        // and count spaces in it 
        for (let i = 0; i < text.length; i++) {
            let currentCharacter = text[i];

            // check previous character
            if (previousCharacter == "" && currentCharacter != " ") {
                numWords += 1
                previousCharacter = currentCharacter
            }

            if (previousCharacter != "" && currentCharacter == " ") {
                // do nothing
                console.log("criteria 1")
                previousCharacter = currentCharacter
            }

            if (currentCharacter == " ") {
                // do nothing
                console.log("criteria 2")
                previousCharacter = currentCharacter
            }

            if (currentCharacter != " ") {
                if (previousCharacter == " ") {

                    console.log("criteria 3")
                    numWords += 1
                    previousCharacter = currentCharacter
                }
            }

            if (currentCharacter == " ") {
            }





        }

        // Display it as output
        document.getElementById(outputId).innerHTML = numWords;
    }

    const [activateText, setActivateText] = useState('Activate')
    const [deactivateText, setDeactivateText] = useState('Settings')



    return (
        <div className=' mt-6'>

            <div className=" border-b-[1px] border-b-gray-500/50 pb-[16px] grid 
            grid-cols-2">
                <PageTitle title={"Business Profile"} />

                <div className="flex place-content-end place-items-center leading-8 space-x-1 flex-wrap">

                    <GrayButton
                        title={"Photo Gallery"}
                        url={"/account/business-profile/photo-gallery/" + parsedPreloadedValue?.business_id}
                    />

                    <GrayButton
                        title={deactivateText}
                        url={"/account/business-profile/settings/" + parsedPreloadedValue?.business_id}
                    />


                    <GrayButton
                        title={"Preview"}
                        url={"/business/" + parsedPreloadedValue?.business_id}
                    />
                </div>
            </div>

            <div className=' mt-8'>
                <form id="upf" action={REACT_APP_API_URL + "?operation=EDIT_BUSINESS"} onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className=' grid lg:grid-cols-12 gap-4'>
                            <div className=' lg:col-span-7'>


                                <InputCustom
                                    register={register}
                                    changeHandler={changeHandler}
                                    controlName={"title"}
                                    type={"text"}
                                    maximumLength={200}
                                    width={100}
                                    labelTop={"Business Name"}
                                    labelBottom={"Your business name may appear around Skyepages sites where you contribute or are mentioned. Your business name is a compulsory field"}
                                    placeholder={"Enter Business Name or Title"}
                                    error={errors?.title}
                                />

                                <InputCustom
                                    register={register}
                                    changeHandler={changeHandler}
                                    controlName={"email"}
                                    type={"text"}
                                    maximumLength={200}
                                    width={100}
                                    labelTop={"Email Address"}
                                    labelBottom={"Enter this business's email address. If it is empty, this account's email address's default email address is automatically selected. Which you can edit."}
                                    placeholder={"abc@domain.com"}
                                    error={errors?.email}
                                />


                                <InputCustom
                                    register={register}
                                    changeHandler={changeHandler}
                                    controlName={"established"}
                                    type={"number"}
                                    maximumLength={200}
                                    width={100}
                                    labelTop={"Year Established"}
                                    labelBottom={<div>Enter the year established for this business. It is not compulsory but it will boost your credibility.</div>}
                                    placeholder={"Year established: E.g. 1945"}
                                    error={errors?.established}
                                />


                            </div>
                            <div className=' lg:col-span-5 order-first lg:order-last'>
                                {
                                    preloadedValues && <UploadPic businessData={JSON.parse(preloadedValues)} />
                                }
                            </div>
                        </div>


                        <div className="w-full md:w-[80%] lg:w-[60%]">
                            {
                                parsedPreloadedValue && <CountryCodeWithMobile
                                    register={register}
                                    changeHandler={changeHandler}
                                    getFormValues={getFormValues}
                                    countryName={"country_code"}
                                    phoneName={"mobile"}
                                    labelTop={"Mobile"}
                                    countryCodeError={errors?.country_code}
                                    mobileError={errors?.mobile}
                                />
                            }
                        </div>



                        <div className=' border-b-[1px] border-gray-700/20 mt-10 mb-[12px]'></div>
                        <div className=' mb-1 text-[17px] font-bold'>Business Address</div>
                        <div className=' text-[15px] tracking-tight font-light mb-6'>See sample entry below. Follow the entry below to add business address.</div>
                        <div className=' mb-6'>
                            <ul className=''>
                                <li className=' text-md'><b>Sample</b></li>
                                <li className=' text-sm'>612 Boulevard Avenue</li>
                                <li className=' text-sm'>New York, NY, 21345</li>
                                <li className=' text-sm'>United States of America</li>
                            </ul>
                        </div>

                        <div className="w-full lg:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"address_one"}
                                type={"text"}
                                maximumLength={200}
                                width={100}
                                labelTop={"Address Line 1"}
                                labelBottom={"Enter anofficial business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"612 Boulevard Avenue"}
                                error={errors?.address_one}
                            />
                        </div>

                        <div className=" w-full lg:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"address_two"}
                                type={"text"}
                                maximumLength={200}
                                width={100}
                                labelTop={"Address Line 2"}
                                labelBottom={"Enter anofficial business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"New York, NY, 21345"}
                                error={errors?.address_two}
                            />
                        </div>

                        <div className="w-full lg:w-[80%]">
                            <CountryDropDown
                                register={register}
                                changeHandler={changeHandler}
                                jsondata={countryjson}
                                controlName={"country"}
                                error={errors?.country}
                                labelTop={"Country"}
                                labelBottom={"Enter country of residence or country your business is registered in. Where a business is situated in more than one country, enter the country that represents the head quarters"}
                            />
                        </div>


                        <div className=' border-b-[1px] border-gray-700/20 mt-[32px] mb-[12px]'></div>
                        <div className=' text-[18px]'>Sample Completed Profile</div>
                        <div className=' text-[16px] tracking-tight font-normal mb-6'>If your entry is complete, your address should look like the following below. To get a complete address like the one below, fill all your details. They will automatically appear.</div>
                        <div className=' mb-6'>
                            <ul className=' text-[17px]'>

                                <li className=''><b>Appler, Inc.</b></li>
                                <li className=' text-sm'>612 Boulevard Avenue</li>
                                <li className=' text-sm'>New York, NY, 21345</li>
                                <li className=' text-sm'>United States of America</li>
                                <li className=' text-sm'>+1 (704) 345 8736</li>
                            </ul>
                        </div>

                        {
                            (
                                parsedPreloadedValue.title &&
                                parsedPreloadedValue.address_one &&
                                parsedPreloadedValue.address_two &&
                                parsedPreloadedValue.country_code &&
                                parsedPreloadedValue.mobile &&
                                parsedPreloadedValue.country
                            ) && <div className=' mb-6 px-6 bg-gray-100 py-5 rounded-[12px] border-[1px] border-gray-500/80 w-[80%]'>
                                <ul className=' text-[17px] '>

                                    <li className=''><b>{parsedPreloadedValue.title}</b></li>
                                    <li className=' text-sm'>{parsedPreloadedValue.address_one}</li>
                                    <li className=' text-sm'>{parsedPreloadedValue.address_two}</li>
                                    <li className=' text-sm'>{parsedPreloadedValue.country}</li>
                                    <li className=' text-sm'>+{parsedPreloadedValue.country_code} {parsedPreloadedValue.mobile}</li>

                                </ul>
                            </div>
                        }




                        <div className=' border-b-[1px] border-gray-700/20 mt-[32px] mb-[32px]'></div>



                        <div className="w-full lg:w-[80%]">
                            <TextAreaCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"intro"}
                                maximumWords={500}
                                height={300}
                                labelTop={"Business Introductory Information"}
                                labelBottom={"Enter the maximum number of words displayed above the text area. Additional texts will be automatically removed."}
                                error={errors?.intro}
                            />
                        </div>



                        <div className='w-full lg:w-[80%] '>

                            <BusinessCategory
                                register={register}
                                changeHandler={changeHandler}
                                jsondata={categories}
                                controlName={"business_category"}
                                labelTop={"Business Category"}
                                error={errors?.business_category}
                                labelBottom={"Enter country of residence or country your business is registered in. Where a business is situated in more than one country, enter the country that represents the head quarters."}
                            />


                        </div>


                        <div className=" w-full lg:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"business_phrases"}
                                type={"text"}
                                maximumLength={300}
                                width={100}
                                labelTop={"Business Phrases"}
                                labelBottom={"Enter anofficial business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"Enter business phrases. E.g. Advocate, Architect, Software Engineer, Plumber"}
                                error={errors?.business_phrases}
                            />
                        </div>

                        <div className=" border-t-[1px] my-[32px] pt-[10px] text-[25px] text-gray-600">
                            Optional Area
                        </div>


                        <div className=" w-full lg:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"products"}
                                type={"text"}
                                maximumLength={300}
                                width={100}
                                labelTop={"Products"}
                                labelBottom={"Enter anofficial business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"Products. E.g. Shoes, Business Manual, Watch, Digital Wallet, Crypto"}
                                error={errors?.products}
                            />
                        </div>

                        <div className="w-full lg:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"services"}
                                type={"text"}
                                maximumLength={300}
                                width={100}
                                labelTop={"Services"}
                                labelBottom={"Enter anofficial business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"Services. E.g. Car wash, Machining and Tooling, Audit, IT Services, Personal Assistant."}
                                error={errors?.products}
                            />
                        </div>


                        <div className=" w-full lg:w-[80%]">
                            <SocialMediaInput
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"xsoc"}
                                controlLabel={"http://x.com/"}
                                error={errors?.xsoc}
                                labelTop={"Twitter"}
                                labelBottom={"Enter country of residence or country your business is registered in. Where a business is situated in more than one country, enter the country that represents the head quarters."}
                            />
                        </div>


                        <div className=" w-full lg:w-[80%]">
                            <SocialMediaInput
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"fbsoc"}
                                controlLabel={"http://facebook.com/"}
                                error={errors?.linksoc}
                                labelTop={"Facebook"}
                                labelBottom={"Enter country of residence or country your business is registered in. Where a business is situated in more than one country, enter the country that represents the head quarters."}
                            />
                        </div>

                        <div className=" w-full lg:w-[80%]">
                            <SocialMediaInput
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"linksoc"}
                                controlLabel={"http://linkedin.com/company/"}
                                error={errors?.linksoc}
                                labelTop={"LinkedIn"}
                                labelBottom={"Enter country of residence or country your business is registered in. Where a business is situated in more than one country, enter the country that represents the head quarters."}
                            />
                        </div>


                        <div className=" w-full lg:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"website"}
                                type={"text"}
                                maximumLength={300}
                                width={100}
                                labelTop={"Website"}
                                labelBottom={"Enter anofficial business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"http://business.com or http://www.business.com"}
                                error={errors?.website}
                            />
                        </div>




                        <div className='flex flex-col w-[80%] mb-6'>


                            <label className='font-normal text-[14px] mt-[8px] leading-[18px]'>
                                By Submitting, I accept the terms and privacy policy.  All of the fields on this page can be deleted at any time, and by filling them out, you're giving us consent to share this data wherever your business profile appears. Please see our <b className="b"><u><a href="/privacy">Privacy Statement</a></u></b> to learn more about how we use this information
                            </label>

                            {/* <input type="hidden" name="operation" value="EDIT_BUSINESS" /> */}

                            <div className=' mt-12'>
                                <GreenButton
                                    working={working}
                                    title={"Update profile"}
                                />


                            </div>
                        </div>


                    </div>
                </form>
            </div >
        </div >
    )
}