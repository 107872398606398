import React from 'react'

function PostHero({ premessage, message1, message2, message3, img }) {

    return (
        <div>
            <div className=' px-5'>
                <div className=' max-w-[1200px] mx-auto w-full space-y-8'>

                    <div className=' text-[28px] font-bold max-w-[800px] leading-tight mx-auto w-full mt-5'>
                        {premessage}
                    </div>

                    <div className=' text-[17px] md:text-[22px] font-serif max-w-[800px] mx-auto w-full leading-[35px]'>
                        {message1}
                    </div>

                    <div className=' grid md:grid-cols-2 gap-8 max-w-[1000px] mx-auto w-full'>
                        <div className=' bg-cover h-72 p-[2px] border-1-[1px] border-black' style={{ backgroundImage: `url('${img}')` }}>

                        </div>
                        <div className=' text-[17px] md:text-[22px] font-serif leading-[35px]'>
                            {message2}
                        </div>
                    </div>

                    <div className=' text-[17px] md:text-[22px] font-serif max-w-[800px] mx-auto w-full leading-[35px]'>
                        {message3}
                    </div>
                </div>


            </div>
        </div>
    )
}

export default PostHero
