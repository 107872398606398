import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const EditProfileSchema = z.object({
    first_name: z.string()
        .min(1, { message: "Name must not be empty" })
        .max(100, { message: "Name must not be more than 100 characters." }),
    last_name: z.any(),
    country_code: z.any(),
    mobile: z.any(),
    address_one: z.any(),
    address_two: z.any(),
    country: z.any(),
    user_id: z.string()
})
export default EditProfileSchema
