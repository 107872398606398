import React from 'react'
import Header from '../../components/header/Header'
import HeroAnimated from '../../components/body/HeroAnimated'
import Hero from '../../components/body/Hero'
import Prefooter from '../../components/footer/Prefooter'
import Footer from '../../components/footer/Footer'
import Subtitle from '../../components/body/Subtitle'
import TopIntro from '../../components/body/TopIntro'
import ClientResult from '../../components/body/ClientResult'
import WhatWeDo from '../../components/body/WhatWeDo'
import HowWeCanHelp from '../../components/body/HowWeCanHelp'
import Impact from '../../components/body/Impact'
import InsightsBox from '../../components/body/InsightsBox'
import Insights from '../../components/body/Insights'
import HorizontalSpce from '../../components/body/HorizontalSpace'
import PostHero from '../../components/body/PostHero'
import PostHeroBar from '../../components/body/PostHeroBar'
import H16 from '../../components/body/H16'
import H32 from '../../components/body/H32'
import H64 from '../../components/body/H64'
import Products from '../../components/body/Products'

function Outsourcing() {
    const insights = [
        {
            id: 1,
            category: "Microservices",
            title: "When To Use Microservices - Sam Newman & Martin Fowler",
            description: "Upgrade your microservices knowledge by listening to a spirited conversation between two living legends: Sam Newman and Martin Fowler.",
            ftrcategory: "Youtube",
            img: "img/img01.jpg",
            link: "https://www.youtube.com/watch?v=GBTdnfD6s5Q&list=PLuglQMPcgxVq3d6tbbj8VgEFuyod4QrNw"
        },
        {
            id: 2,
            category: "Software Development",
            title: "The Next Decade of Software Development - Richard Campbell",
            description: "How will software development evolve in the 2020s? Join Richard Campbell as he explores the landscape of technology.",
            ftrcategory: "Youtube",
            img: "img/sftw.jpg",
            link: "https://www.youtube.com/watch?v=ND_AjF_KTD8"
        },
        {
            id: 3,
            category: "Software Architecture",
            title: "How to Become a Great Software Architect - Eberhard Wolff",
            description: "Software architecture is very simple: You only have to split up one system and use modern approaches such as DDD or Microservices",
            ftrcategory: "Architecture",
            img: "img/archtct.jpg",
            link: "https://www.youtube.com/watch?v=v_nhv6aY1Kg"
        },
        {
            id: 4,
            category: "Software Development",
            title: "The Most Powerful Software Development Process Is The Easiest • Dave Farley",
            description: "What would an ideal software development process look like?",
            ftrcategory: "Youtube",
            img: "img/scrt.jpg",
            link: "https://www.youtube.com/watch?v=HDyeiZimaOU"
        },
    ]

    const topIntros = [
        {
            id: 1,
            title: "Customer Business Design Needs Analysis",
            description: "As a crucial phase in the development of tailored software solutions, we focus on understanding and documenting the specific requirements of the client’s business.",
        },
        {
            id: 2,
            title: "Software Development and Deployment",
            description: "Our work encompass the entire lifecycle of creating and delivering the software solution, ensuring they meet client needs and operate reliably in production environments.",
        },
        {
            id: 3,
            title: "Go-Live, Monitoring & Feedback",
            description: "We assure our client of a smooth transition to a new or updated software system into production, along with maintaining its performance and iterating based on requirements.",
        },
    ]
    const experience = [
        {
            id: 1,
            url: "https://wallpapers.com/images/high/nft-background-578j4vssin7qrryq.webp",
            category: "Smart Contracts",
            title: "With Solidity Smart Contracts, Organizations Can Set Sail",
            description: "We offer invaluable guidance to individuals and organizations navigating the complexities of blockchain development on the Ethereum platform.",
        },
        {
            id: 2,
            url: "https://www.qloudx.com/wp-content/uploads/2021/04/Blog-14-Microchip-Network.jpg",
            category: "CBDC & Blockchain",
            title: "Central Bank Digital Currency, Smart Contracts & Middleware",
            description: "Drawing from extensive experience in CBDC solutions, our consultancy offers a holistic approach to navigating the complexities of modern digital finance ecosystems.",
        },
    ]
    const ourhelp = [
        {
            id: 1,
            title: "Analysis & Design",
            description: "At the design stage of the software development, we help to carefully plan for the successful implementation of a robust solution."
        },
        {
            id: 2,
            title: "Development & Testing",
            description: "Our focus is to ensure that the final product is both functional and reliable using best practice methodologies."
        },
        {
            id: 3,
            title: "Deployment & Monitoring",
            description: "Specialized expertise will help to ensure seamless and secure transition of the software from development to production"

        },
        {
            id: 4,
            title: "Feedback & Support",
            description: "We monitor and utilize feedbacks to maintain smooth continuous operation and ensuring client software functions to meet user needs."

        },
    ]
    const whatwedo = "Storcs understand the seismic forces reshaping business for defense contractors today. Amid extraordinary budgetary pressures, new entrants are emerging around the globe as corporate consolidation continues across the industry. While capital is sometimes abundant, it is harder than ever to find and harness the right talent. To thrive, established companies must become more agile, getting products to market faster than ever—customers increasingly expect this, and at lower cost. We can help you adapt to these trends, with a proven track record built on successful engagements with virtually every A&D prime and dozens of Tier I/II suppliers.";

    const impacts = [
        {
            id: 1,
            figure: "100,000,000+",
            description: "Number of Lines of Codes of Software completed."
        },
        {
            id: 2,
            figure: "53%",
            description: "Average additional value that companies using Storcs for Software Development support realize compared to those that don’t"
        },
    ]

    return (
        <div>
            <Header bg={"light"} />
            <HeroAnimated
                imgLink={"https://aiglobe.wpengine.com/wp-content/uploads/2023/12/service-04.jpg"}
                category={"CBDC & Crypto"}
                title={"Software Development and Maintenance Outsourcing."}
            />

            {/* <div>
                <PostHeroBar />
                <H16 />
            </div> */}

            <div>
                <Prefooter />
            </div>

            <div>
                <PostHero
                    img={'img/camara.webp'}
                    message1={`Our work involves partnering with clients to handle various aspects of their software development lifecycle, from initial planning and design to coding, testing, and maintenance.`}
                    message2={`Our core objective is to help clients to maximise cost efficiency, allocate resources strategically and focus on core business functions. Further more engaging with us give our clients access to a wide range of technical skills and industry knowledge. Working with us often help clients eliminate project delays, technical issues and other challenges.`}
                    message3={`Outsourcing software development is a strategic decision that can help clients achieve innovative solutions, improve efficiency, and provide a competitive edge while maintaining control over core operations.`}
                />
                <H64 />
            </div>

            <div className=' ' style={{ background: "url('img/cloud.jpg')", backgroundPosition: "top -100% -80%", backgroundRepeat: "no-repeat", }}>
                <Subtitle title={"Software Outsourcing"} />
                <TopIntro topIntros={topIntros} />
                <H64 />
            </div>

            {/*  <div>
                <Subtitle title={"What we do"} />
                <WhatWeDo whatwedo={whatwedo} />
                <H64 />
            </div> */}

            {/*  <div>
                <Subtitle title={"Our Experience"} />
                <ClientResult experience={experience} />
                <H64 />
            </div> */}




            <div className=' mt-10'>
                <Subtitle title={"How We Can Help"} />
                <HowWeCanHelp ourhelp={ourhelp} />
                <H64 />
            </div>




            <div className=' mt-10'>
                <Subtitle title={"Impact"} />
            </div>

            <div>
                <Impact impacts={impacts} />
                <H64 />
            </div>

            <div>
                <Subtitle title={"Insights on Smart Contracts"} />
            </div>

            <div>
                <InsightsBox insights={insights} />
                <H64 />
            </div>


            <div>
                <Subtitle title={" Our Top Picks"} />
            </div>

            <div>
                <Products insightsData={insights} />
                <H64 />
            </div>



            <div className=' h-20'></div>


            <Footer />
        </div>
    )
}

export default Outsourcing
