import React, { useContext, useEffect, useRef, useState } from 'react'
import { UtilContext } from '../../../../context/UtilContext'
import { GlobalContext } from '../../../../context/GlobalContext'
import axios from 'axios'
import EditGalleryImage from './EditGalleryImage'
import { GridCloseIcon } from '@mui/x-data-grid'
import { CgClose } from 'react-icons/cg'
import DeleteGalleryImage from './DeleteGalleryImage'

const initVal = [
    {
        id: "",
        image_filename: "",
        user_id: "",
        business_id: "",
        image_guid: "",
        date_created: "",
        created_by: "",
    }
]

const DisplayBusinessGallery = ({ businessGalleryData }) => {
    //alert(businessGalleryData)
    const [galleryData, setGalleryData] = useState([])
    const [id, setId] = useState("")
    const { getUrlId } = useContext(UtilContext)
    const { REACT_APP_API_URL,
        REACT_APP_API_BUSINESS_GALLERY_IMG_URI
    } = useContext(GlobalContext)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
    }, [loading])

    useEffect(() => {
        if (loading === false) {
            setId(getUrlId())
        }
    }, [loading])




    function getBusinessGalleryData(id) {
        const endpoint = REACT_APP_API_URL + "?operation=READ_BUSINESS_GALLERY_DATA&id=" + id
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {

                let m = JSON.stringify(res.data.data)
                let n = JSON.parse(m)
                const data = (n)
                //alert(data)
                setGalleryData(data)
                setLoading(false)
                //alert(businessData)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    useEffect(() => {
        if (id !== "" && loading === false) {
            getBusinessGalleryData(id)
        }
    }, [id])






    return (
        <div>
            <div className=''>
                {
                    (loading === false && id !== "" && galleryData !== null) &&
                    <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-5 '>

                        {
                            galleryData.map((item) => {
                                return (
                                    <div className=' w-full col-span-1 rounded-[12px] overflow-hidden  shadow-xl border-[3px] border-gray-500/20'>

                                        <div className=' h-[200px] bg-blue-100 relative '>
                                            <img
                                                src={REACT_APP_API_BUSINESS_GALLERY_IMG_URI + item?.image_filename}
                                                alt=""
                                                className=' object-cover w-full h-full'
                                            />
                                            <DeleteGalleryImage imageData={item} />
                                        </div>
                                        <div className=' m-3'>
                                            <div className='flex place-content-between'>
                                                <div className='font-bold text-[15px]'>
                                                    Title
                                                </div>
                                                <div className=' text-blue-700 font-bold underline text-[15px]'>
                                                    <EditGalleryImage imageData={item} />
                                                </div>
                                            </div>
                                            <div className=' h-auto leading-tight '>
                                                {item?.title}
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default DisplayBusinessGallery
