import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import axios from 'axios'
import DisplayFacilities from '../business/DisplayFacilities'

const FacilitiesForSearch = ({ id }) => {
    const [facilities, setFacilities] = useState(null)
    const { REACT_APP_API_URL } = useContext(GlobalContext)

    const getFacilities = async (data) => {
        const endpoint = REACT_APP_API_URL + "?operation=GET_FACILITIES&id=" + id
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = JSON.stringify(res.data.data[0])
                const dataObject = JSON.parse(data)

                console.log(dataObject)
                setFacilities(dataObject)
                //alert(businessData)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    useEffect(() => {
        getFacilities(id)
    }, [])

    return (
        <div>
            {facilities !== null &&
                <DisplayFacilities facilities={facilities} />
            }
        </div>
    )
}

export default FacilitiesForSearch
