import React from 'react'
import Header from '../../components/header/Header'
import HeroAnimated from '../../components/body/HeroAnimated'
import Hero from '../../components/body/Hero'
import Prefooter from '../../components/footer/Prefooter'
import Footer from '../../components/footer/Footer'
import Subtitle from '../../components/body/Subtitle'
import TopIntro from '../../components/body/TopIntro'
import ClientResult from '../../components/body/ClientResult'
import WhatWeDo from '../../components/body/WhatWeDo'
import HowWeCanHelp from '../../components/body/HowWeCanHelp'
import Impact from '../../components/body/Impact'
import InsightsBox from '../../components/body/InsightsBox'
import Insights from '../../components/body/Insights'
import HorizontalSpce from '../../components/body/HorizontalSpace'
import PostHero from '../../components/body/PostHero'
import PostHeroBar from '../../components/body/PostHeroBar'
import H16 from '../../components/body/H16'
import H32 from '../../components/body/H32'
import H64 from '../../components/body/H64'
import Products from '../../components/body/Products'

function APIGateway() {
    const insights = [
        {
            id: 1,
            category: "API Gateway Integration",
            title: "KPMG Presents: API Integration with OAuth and Traditional Web Session Management",
            description: "Web applications have moved on from a traditional web protection and authorization model.",
            ftrcategory: "Youtube",
            img: "img/nyc.jpeg",
            link: "https://www.youtube.com/watch?v=DDC0mi0_EtY"
        },
        {
            id: 2,
            category: "Telecommunications",
            title: "Introduction to CAMARA, the Global Telco API Alliance",
            description: "Mahesh Chapalamadugu, Verizon presents the Global Telco API Alliance",
            ftrcategory: "Youtube",
            img: "img/camara.webp",
            link: "https://www.youtube.com/watch?v=uhto19UIS7A"
        },
        {
            id: 3,
            category: "Networking",
            title: "How Open Programmable Infrastructure (OPI) Improves Portability of Cloud & Data Center Applications",
            description: "Open Programmable Infrastructure (OPI) Project recently debuted an end to end demo that features initial provisioning of DPU/IPUs utilizing the sZTP framework.",
            ftrcategory: "Payments",
            img: "img/opip.png",
            link: "https://www.youtube.com/watch?v=TtMsnVS_L4U"
        },
        {
            id: 4,
            category: "Artificial Intelligence",
            title: "APICON 2017: Session with Armagan Amcalar",
            description: "APICON 2017: Session with Armagan Amcalar - Zero-configuration microservices with Node.js and Docker.",
            ftrcategory: "Webinar",
            img: "img/apico2.jpg",
            link: "https://www.youtube.com/watch?v=R2JUgMSAzu8&t=1183s"
        },
    ]

    const topIntros = [
        {
            id: 1,
            title: "API Architecture Design and Security",
            description: "Advise on the best practices for structuring API gateways to ensure scalability, reliability, and security. Selecting the right tools and technologies that align with the client's needs and goals.",
        },
        {
            id: 2,
            title: "Development, Integration, Performance Optimization",
            description: "Assisting with the seamless integration of API gateways. Enhancing the performance of API gateways by optimizing request routing, load balancing, and caching strategies to ensure high availability and low latency.",
        },
        {
            id: 3,
            title: "Deployment, Monitoring and Maintenance",
            description: "Deploying API Gateways in a way that minimizes disruption and maximizes efficiency. Setting up monitoring tools and protocols to track API usage, performance metrics, and potential issues.",
        },
    ]
    const experience = [
        {
            id: 1,
            url: "img/opip.png",
            category: "API Development",
            title: "Client Strategic Direction, API Gateway Design and Development",
            description: "Our expertise borders on API Routing and Load Balancing, Security, Transformation & Aggregation, Monitoring, Logging, Scalability and High Performance.",
        },
        {
            id: 2,
            url: "img/api.webp",
            category: "Legacy Applications",
            title: "Legacy Application Integration with API Gateway",
            description: "Wrapper API Development, Protocol Translation, Data Transformation, Security and Compliance, Incremental Modernization, Monitoring and Logging.",
        },
    ]
    const ourhelp = [
        {
            id: 1,
            title: "API Gateway Design",
            description: "We focus on designing a central gateway to securely manage API requests for scalable communication between clients & backend services"
        },
        {
            id: 2,
            title: "API Gateway Development",
            description: "We focus on building a central gateway to manage and route API requests, ensuring efficient communication between clients and backend services"
        },
        {
            id: 3,
            title: "API Gateway Deployment",
            description: "We focus on setting up and configuring the gateway in a production environment to manage and route API traffic efficiently"

        },
        {
            id: 4,
            title: "API Monitoring & Performance",
            description: "We focus on continuously tracking the performance and health of the API gateway to ensure optimal operation and quick detection of issues."

        },
    ]
    const whatwedo = "Storcs understand the seismic forces reshaping business for defense contractors today. Amid extraordinary budgetary pressures, new entrants are emerging around the globe as corporate consolidation continues across the industry. While capital is sometimes abundant, it is harder than ever to find and harness the right talent. To thrive, established companies must become more agile, getting products to market faster than ever—customers increasingly expect this, and at lower cost. We can help you adapt to these trends, with a proven track record built on successful engagements with virtually every A&D prime and dozens of Tier I/II suppliers.";

    const impacts = [
        {
            id: 1,
            figure: "900,000+",
            description: "Number of Lines of Codes of API Gateway completed."
        },
        {
            id: 2,
            figure: "33%",
            description: "Average additional value that companies using us for API Gateway support realize compared to those that don’t"
        },
    ]

    return (
        <div>
            <Header bg={"light"} />
            <HeroAnimated
                imgLink={"https://unbound.radiantthemes.com/wp-content/uploads/2018/04/banner-five.jpg?id=3113"}
                category={"CBDC & Crypto"}
                title={"Application Programming Interface and Middleware"}
            />



            <div>
                <PostHero
                    img={'img/apico2.jpg'}
                    premessage={"API Gateways enable seemless transactions."}
                    message1={`Organizations need expert guidance and services on the implementation, management, and optimization of API gateways.`}
                    message2={`As a crucial component in modern software architecture,  API gateway acts as a single entry point for managing and directing API requests between clients and backend services. Our core operations involves assisting clients  with the seamless integration of API gateways with existing systems and services, and deploying them in a way that minimizes disruption and maximizes efficiency`}
                    message3={`Furthermore, we advise on the best practices for structuring API gateways to ensure scalability, reliability, and security, including selecting the right tools and technologies to suit client needs and goals.`}
                />
                <H64 />
            </div>

            <div className=' ' style={{ background: "url('img/cloud.jpg')", backgroundPosition: "top -100% -80%", backgroundRepeat: "no-repeat", }}>
                <Subtitle title={"API Gateway Management"} />
                <TopIntro topIntros={topIntros} />
                <H64 />
            </div>

            {/*  <div>
                <Subtitle title={"What we do"} />
                <WhatWeDo whatwedo={whatwedo} />
                <H64 />
            </div> */}

            <div>
                <Subtitle title={"Our Experience"} />
                <ClientResult experience={experience} />
                <H64 />
            </div>




            <div className=' mt-10'>
                <Subtitle title={"How We Can Help"} />
                <HowWeCanHelp ourhelp={ourhelp} />
                <H64 />
            </div>




            <div className=' mt-10'>
                <Subtitle title={"Impact"} />
            </div>

            <div>
                <Impact impacts={impacts} />
                <H64 />
            </div>

            <div>
                <Subtitle title={"Insights"} />
            </div>

            <div>
                <InsightsBox insights={insights} />
                <H64 />
            </div>


            <div>
                <Subtitle title={" Top Picks"} />
            </div>

            <div>
                <Products insightsData={insights} />
                <H64 />
            </div>



            <div className=' h-20'></div>
            <div>
                <Prefooter />
            </div>

            <Footer />
        </div>
    )
}

export default APIGateway
