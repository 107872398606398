import React, { useEffect, useState } from 'react'
import { BsArrowBarRight, BsPlay, BsPlayFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

function InsightsBox({ insights }) {
    const defaultData = [
        {
            id: 1,
            category: "Mergers and Acquisitions",
            title: "How Companies Got So Good at M&A",
            description: "What 20 years in the trenches have taught us about deal success",
            ftrcategory: "Brief",
            img: "img/baien.jpg",
            title: "/",
            link: "http://google.com"
        },
        {
            id: 2,
            category: "Telecommunications",
            title: "Generative AI in M&A: Where Hope Meets Hype",
            description: "Are you already behind the curve in artificial intelligence?",
            ftrcategory: "M&A Report",
            img: "img/runner.jpg",
            link: "/",
        },
        {
            id: 3,
            category: "Blockchain",
            title: "Telecom M&A: Here Are the Latest Deal Trends Worldwide",
            description: "Telecommunications M&A deal value spiked in the first quarter, as some telcos increase scale and others expand in adjacent sectors such as finance and insurance.",
            ftrcategory: "Interactive",
            img: "img/ladyb.jpg",
            link: "/"
        },
        {
            id: 4,
            category: "Artificial Intelligence",
            title: "A UK Perspective on Bain’s 2024 Global M&A Report",
            description: "Bain experts Peter Horsley and Suzanne Kumar delve into key global and UK industry insights from our latest M&A Report.",
            ftrcategory: "Webinar",
            img: "img/ccar.jpg",
            link: "/"
        },
    ]

    const [insightsMap, setInsightsMap] = useState(defaultData)

    useEffect(() => {
        if (insights) {
            setInsightsMap(insights)
        }
        console.log(insightsMap)
    }, [insights])


    return (
        <>
            <div className=' max-w-[1230px] w-full mx-auto'>
                <div className=' grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 divide-x divide-gray-300 gap-y-5 '>
                    {
                        insightsMap?.map((item) => {
                            return (
                                <div key={item.id} className='relative hover:bg-slate-100 py-5 '>
                                    <NavLink to={item.link}>
                                        <div className=' h-full mx-4'>
                                            <div className=' h-[180px] w-full'>
                                                <img src={item.img} alt="" className=' w-full h-full  object-cover' />
                                            </div>
                                            <div className=' font-semibold text-sm mt-2 '>
                                                {item.category}
                                            </div>
                                            <div className=' text-2xl leading-tight md:text-[17px] font-bold md:leading-tight mt-3 min-h-[44px]'>
                                                {item.title}
                                            </div>
                                            <div className='  mt-4 relative '>
                                                <div className='font-normal font-sans text-[17px] md:text-[14px] leading-tight pb-2'>
                                                    {item.description}
                                                </div>

                                                <div className=' relative grid grid-cols-2 mt-3 border-t-[1px] border-gray-300 pt-2'>
                                                    <div>{item.ftrcategory}</div>
                                                    <div className='flex place-content-end'>
                                                        <BsPlayFill className=' relative top-[5px]' />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </NavLink>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default InsightsBox
