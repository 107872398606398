import React from 'react'

const SocialMediaInput = ({ register, changeHandler, controlName, controlLabel, error, labelTop, labelBottom }) => {
    return (
        <div className='flex flex-col w-[100%] mb-6'>
            <label className='font-bold mb-[8px]' htmlFor="title">{labelTop}</label>
            <div className=" border-[1px] border-gray-500/40 rounded-[8px]">
                <div className=" flex place-content-start place-items-center ">
                    <div className=" px-2 font-bold 
                    bg-gray-200 py-[7px] rounded-l-[8px] 
                    border-r-[1px] border-gray-500/40 
                    min-w-[10px]
                    ">
                        {controlLabel}
                    </div>
                    <div className=" min-w-[50%]  grow">
                        <input
                            {...register(controlName, {
                                onChange: changeHandler
                            })}

                            type='text'
                            className=' w-[100%] py-[0px] pl-[2px] outline-none text-[17px]'
                            placeholder='businesshandle'
                        />
                    </div>
                </div>
            </div>
            {error && (<div className=' text-red-400 mt-[5px] text-[14px]'><span className=' text-red-500 font-bold'>{error.message}</span></div>)}
            <label className='font-normal text-[14px] mt-[8px] leading-[18px] form-width-80'>
                {labelBottom}
            </label>
        </div>
    )
}

export default SocialMediaInput
