import React, { useState } from "react";
import { BsCopy } from "react-icons/bs";


export default function ClipBoardCopy2() {
    const [copied, setCopied] = useState(false);

    async function copy() {
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setCopied(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setCopied(false)
    }

    return (
        <div className=' text-blue-700'>
            <a href="#" className=" w-full inline-block border-b-blue-300" onClick={copy}>
                <div className=" flex flex-col place-content-center place-items-center">
                    <div className=' w-fit p-[12px] border-[1px] border-gray-400 rounded-full'>
                        <BsCopy
                            className=' text-[20px]'
                        />

                    </div>
                    <div className=" w-full">
                        {!copied ? "Copy  Link" : "Copied!"}
                    </div>
                </div>
            </a>
        </div>
    );
}