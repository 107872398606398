import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'




const phoneRegex = new RegExp(/^(?:\+1)?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/);

const RatingReviewSchema = z.object({
    fullname: z.string()
        .min(1, { message: "Enter your full name" })
        .min(2, { message: "Your name must not be less than 2 characters" })
        .max(100, { message: "Business name must not be more than 100 characters." }),
    rating_comment: z.string()
        .min(10, { message: "Comment must not be less than 10 characters" })
        .max(400, { message: "Intro message must not be more than 400 characters" }),
    /* rating: z.string()
        .min(1, { message: "Please select a rating" }), */
    //rating: z.string({ message: "Please select a rating" })
})

export default RatingReviewSchema
