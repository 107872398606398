import React, { useContext, useEffect } from 'react'
import { GlobalContext, GlobalSetterContext } from '../../context/GlobalContext'

const VerticalBanner = ({ dataAdSlot, dataAdFormat, dataFullWidthResponsive }) => {
    const { isAdsense } = useContext(GlobalContext)

    useEffect(() => {

        if (isAdsense === "OK") {

            try {
                ((window).adsbygoogle = (window).adsbygoogle || []).push({})
            } catch (error) {
                console.log(error.message)
            }
        }
    }, [isAdsense])

    return (
        <div className='flex place-content-center relative place-items-center rounded-[6px] overflow-hidden'>

            {isAdsense == "OK" ?
                <div className='min-h-[250px] w-[100%] bg-blue-500/5 flex flex-col place-content-center place-items-center text-black'>
                    <ins
                        className="adsbygoogle"
                        style={{ display: "block" }}
                        data-ad-client={"ca-pub-1440945780925835"}
                        data-ad-slot={dataAdSlot}
                        data-ad-format={dataAdFormat}
                        data-full-width-responsive={dataFullWidthResponsive.toString()}
                    ></ins>
                    ad by google
                </div> :
                <div className=' flex relative flex-col place-content-center place-items-center w-full'>
                    <div className='min-h-[250px] w-[100%] bg-blue-500/5 flex flex-col place-content-center place-items-center text-black'>
                        ads by Google
                    </div>
                </div>

            }
        </div>

    )
}

export default VerticalBanner
