import React, { useEffect, useState } from 'react'
import { BsPlayBtnFill, BsPlayFill } from 'react-icons/bs'
import { SlAnchor } from 'react-icons/sl'

function ClientResult({ experience }) {
    const defaulData = [
        {
            id: 1,
            url: "https://www.bain.com/contentassets/6af90607571a41709dc053527225e622/23071-cruiseco-2-1_1.jpg?width=350&height=350&mode=crop",
            category: "Retail",
            title: "With Sophisticated Customer Segmentation, a Travel Company Sets Sail",
            description: "Identifying a target customer group and brand positioning helped our client to disrupt the cruising industry with a bold new offering.",
        },
        {
            id: 2,
            url: "https://www.bain.com/contentassets/10a46e696c6a49b5b58eb86dda32301c/24928-insuranceco-1_1.jpg?width=350&height=350&mode=crop",
            category: "Insurance",
            title: "Cost Transparency Helps Insurer Strengthen Tech Expense Management Capability",
            description: "With Sophisticated Customer Segmentation, a Travel Company Sets Sail Identifying a target customer group and brand",
        },
    ]

    const [experienceMap, setExperienceMap] = useState(defaulData)

    useEffect(() => {
        if (experience) {
            setExperienceMap(experience)
        }
    }, [experience])

    return (
        <div>
            <div className=' px-5'>
                <div className=' max-w-[1200px] mx-auto w-full '>
                    <div className=' grid md:grid-cols-2 '>
                        {
                            experienceMap.map((item) => {

                                return (
                                    <div key={item.id} className=' px-5'>
                                        <div className='   grid lg:grid-cols-2 gap-8 h-full '>
                                            <div className=' h-[300px] bg-center bg-cover' style={{ backgroundImage: `url('${item.url}')` }}></div>
                                            <div className='border-b-[1px] border-gray-500/30 pb-5'>
                                                <div className=' relative  '>
                                                    <div className=' font-bold text-sm text-red-500'>
                                                        {item.category}
                                                    </div>

                                                    <div className=' font-bold mt-4 text-lg leading-6'>
                                                        {item.title}                                                </div>

                                                    <div className=' mt-4 text-md font-serif leading-6'>
                                                        {item.description}                                                </div>

                                                    <div className=' relative bg-blue-100 bottom-0 mt-4 '>
                                                        <BsPlayFill size={20} className=' float-end' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>


                </div>
            </div>
        </div>
    )
}

export default ClientResult
