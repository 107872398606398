import zIndex from "@mui/material/styles/zIndex";
import def from "ajv/dist/vocabularies/applicator/additionalItems";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { GrClose, GrStatusCritical, GrStatusGood } from "react-icons/gr";

const AlertContext = createContext()

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState(null)
    const timerRef = useRef(null)

    useEffect(() => {
        /*  if (timerRef.current) {
             clearTimeout(timerRef.current)
         } */
        /* timerRef.current = setTimeout(() => {
            setAlert(null)
        }, 5000) */
    }, [alert])

    const Alert = () => {

        const { alert } = useContext(AlertContext)


        const alertStyles = {
            padding: "8px 16px 8px 16px",
            borderRadius: "50px",
            fontSize: "15px",
            fontWeight: 400,
            position: "fixed",
            minWidth: "400px",
            maxWidth: "450px",
            top: "5%",
            right: "50%",
            transform: 'translate(50%, -50%)',
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            zIndex: 20000000,
            backgroundColor: "#ffffff"

        };

        const severityStyles = {
            success: {
                color: "#0f5132",
                background: "#d1e7dd",
            },
            info: {
                color: "#000000",
                background: "ffffff",
            },
            warning: {
                color: "#664d03",
                background: "fff3cd",
            },
            danger: {
                color: "#fff",
                background: "red",
            },
        };

        if (!alert) {
            return null
        }
        const fullStyles = {
            ...alertStyles,
            ...severityStyles[alert.type]
        }

        function getSuccess() {
            return <div style={fullStyles} className=" flex place-content-center place-items-center">
                <div><GrStatusGood size={15} className=" font-bold" /></div>
                <div className=" flex grow place-content-center font-bold px-2 w-auto">{alert.text}</div>
                <div><GrClose size={12} /></div>
            </div>
        }

        function getWorking() {
            return <div style={fullStyles} className=" flex border-[1px] border-gray-500/20 place-content-center place-items-center">
                <div><CgSpinner size={15} className=" font-bold animate-spin" /></div>
                <div className=" flex grow place-content-center font-bold px-2 w-auto">{alert.text}</div>
                <div><GrClose size={12} /></div>
            </div>
        }

        function getFail() {
            return <div style={fullStyles} className=" flex place-content-center place-items-center">
                <div><GrStatusCritical size={15} className=" font-bold" /></div>
                <div className=" flex grow place-content-center font-bold px-2 w-auto">{alert.text}</div>
                <div><GrClose size={12} /></div>
            </div>
        }
        return (
            <div>
                {
                    alert.type == 'info' && getWorking()
                }

                {
                    alert.type == 'success' && getSuccess()
                }
                {
                    alert.type == 'danger' && getFail()
                }
            </div>
        )
    }

    const showAlert = (type, text) => {
        setAlert({
            type,
            text
        })
    }

    const closeAlert = () => {
        setAlert(null)
    }

    const fadeOutAlert = (timerRef) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = setTimeout(() => {
            setAlert(null)
        }, 5000)
    }

    const fadeOutAlertWithParam = (timerRef, timeOption) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = setTimeout(() => {
            setAlert(null)
        }, timeOption)
    }



    let val = {
        alert,
        setAlert,
        Alert,
        showAlert,
        closeAlert,
        fadeOutAlert,
        timerRef,
        fadeOutAlertWithParam
    }
    return (
        <AlertContext.Provider value={val}>
            {children}
        </AlertContext.Provider>
    )
}



export default AlertContext