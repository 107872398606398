import React, { useContext, useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import HeroAnimated from '../../components/body/HeroAnimated'
import SliderBox from '../../components/body/Slider'
import Hero from '../../components/body/Hero'
import Prefooter from '../../components/footer/Prefooter'
import Footer from '../../components/footer/Footer'
import Datatable from '../../components/body/Datatable'
import H64 from '../../components/body/H64'
import HeaderLightSearch from '../../components/header/headerLight/HeaderLightSearch'
import HeaderLight from '../../components/header/headerLight/HeaderLight'
import CustomPrefooter from '../../components/footer/CustomPrefooter'
import { UtilContext } from '../../context/UtilContext'
import { GlobalContext } from '../../context/GlobalContext'
import axios from 'axios'

const ClaimBusiness = () => {
    const { getUrlId } = useContext(UtilContext)
    const { REACT_APP_API_URL } = useContext(GlobalContext)

    const [businessData, setBusinessData] = useState(null)
    const [id, setId] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let id = getUrlId()
        setId(id)
    }, [getUrlId])

    const getBusinessData = (id) => {

        const endpoint = REACT_APP_API_URL + "?operation=READ_BUSINESS&id=" + id
        //window.location.href = (endpoint)
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = JSON.stringify(res.data.data[0])
                //alert(data)
                setBusinessData((JSON.parse(data)))
                setLoading(false)
                //alert(businessData)
            })

    }
    useEffect(() => {
        if (id) {
            getBusinessData(id)
        }
    }, [setBusinessData, id])
    return (
        <div>



            <HeaderLight
                bgColor={"bg-transparent"}
                bgColorOnScroll={"bg-white"}
                logoColor={"text-[#4b0d96]"}
                logoColorOnScroll={"text-[#fff]"}
                logoBgColor={"bg-white"}
                logoBgColorOnScroll={"bg-[#4b0d96]"}
                color={"text-[#fff]"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />

            <HeroAnimated
                imgLink={"https://unbound.radiantthemes.com/wp-content/uploads/2018/06/business-agency-3-banner.jpg"}
                title={"Claim this business? Send us an email."}
            />

            <div className=' mx-5'>
                <div className=' max-w-[1200px] mx-auto w-full pt-[80px] pb-[100px]'>
                    <div className=' text-[32px]'>
                        {
                            businessData?.title
                        }
                    </div>
                    <div className=' mt-5  text-[16px]'>
                        Do you wish to claim this business, {businessData?.title}? Send us an email at <a href="mailto:claim@skyepages.com" className=' text-blue-800 font-bold'>claim@skyepages.com</a>
                    </div>
                </div>
            </div>

            <div>
                <CustomPrefooter
                    subHeader={"Claim your business?"}
                    content={"Email: claim@skyepages.com"}
                />
            </div>

            <Footer />
        </div>
    )
}

export default ClaimBusiness
