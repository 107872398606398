import React, { createContext, useContext, useEffect, useState } from 'react'
import Home from '../pages/home/Home';
import Contact from '../pages/contact/Contact';
import About from '../pages/about/About';
import OurWork from '../pages/our-work/OurWork';
import CBDCSmartContracts from '../pages/cbdc-smartcontracts/CBDCSmartContracts';
import APIGateway from '../pages/api-gateway/APIGateway';
import Outsourcing from '../pages/outsourcing/Outsourcing';
import Search from '../pages/search/Search';
import AlertContext from './AlertContext';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import Account from '../pages/account/Account'
import Login from '../pages/login/Login';
import ResetPw from '../pages/resetpw/ResetPw';
import Signup from '../pages/signup/Signup';
import AccountHome from '../pages/account/account/home/Home';
import AllBusinesses from '../pages/account/account/allbusinesses/AllBusinesses';
import Email from '../pages/account/settings/email/Email';
import Profile from '../pages/account/settings/profile/Profile';
import ChangePassword from '../pages/account/settings/password/ChangePassword';
import ResetPwd from '../pages/account/settings/resetpwd/ResetPwd';
import BusinessProfile from '../pages/account/account/businessprofile/BusinessProfile';
import BusinessSettings from '../pages/account/account/businessprofilesettings/BusinessSettings';
import AddBusiness from '../pages/account/account/addbusiness/AddBusiness';
import Business from '../pages/business/Business';
import HomeAlt from '../pages/homealt/HomeAlt';
import Advertise from '../pages/advertise/Advertise';
import Privacy from '../pages/privacy/Privacy'
import PhotoGallery from '../pages/account/account/businessprofile/PhotoGallery';
import ClaimBusiness from '../pages/claim-business/ClaimBusiness';
import error404 from '../pages/404/error404';
import Error404 from '../pages/404/error404';
import Interceptor from '../pages/interceptor/Interceptor';
import Intercept from '../pages/interceptor/Intercept';
import ResetPwdLanding from '../pages/resetpwdlanding/ResetPwdLanding';
import SignupLanding from '../pages/signuplanding/SignupLanding';
import ChangeEmailLanding from '../pages/changeemaillanding/ChangeEmailLanding';

const navjson = [
    {
        id: 1,
        title: "Home",
        img: "/img/english.jpg",
        link: "/",
        page: <Home />
    },
    {
        id: 2,
        title: "Search",
        img: "/img/english.jpg",
        link: "/search",
        page: <Search />
    },
    {
        id: 3,
        title: "CBDC Contracts",
        link: "/cbdc-smartcontracts",
        page: <CBDCSmartContracts />
    },
    {
        id: 4,
        title: "API Gateway",
        link: "/api-gateway",
        page: <APIGateway />
    },
    {
        id: 5,
        title: "Outsourcing",
        link: "/outsourcing",
        page: <Outsourcing />
    },
    {
        id: 6,
        title: "Contact us",
        link: "/contact",
        page: <Contact />
    },

]

const features = [
    {
        id: 1,
        link: "/console/list-businesses",
        title: "My Businesses",
        page: null
    },
    {
        id: 2,
        link: "/console/add-business",
        title: "Add Business",
        page: null
    },
    {
        id: 3,
        link: "/console/settings",
        title: "Settings",
        page: null
    },
    {
        id: 4,
        link: "/console/edit-business",
        title: "Edit Business",
        page: null
    },


]

const mobileMenuJson = [
    {
        title: "Home",
        img: "/img/english.jpg",
        link: "/"
    },
    {
        title: "Our Work",
        link: "/our-work"
    },
    {
        title: "Contact Us",
        link: "/contact"
    },



]


const protectedAccount = [
    {
        id: 1,
        link: "/account/home",
        title: "Account",
        page: <AccountHome />
    },
    {
        id: 2,
        link: "/account/settings/*",
        title: "Settings",
        page: null
    },
    {
        id: 3,
        link: "/account/settings/profile",
        title: "Account profile",
        page: <Profile />
    },
    {
        id: 4,
        link: "/account/settings/email",
        title: "Email",
        page: <Email />
    },
    {
        id: 5,
        link: "/account/settings/change-password",
        title: "Passwords",
        page: <ChangePassword />
    },
    {
        id: 6,
        link: "/account/settings/reset-password",
        title: "Reset Password",
        page: <ResetPwd />
    },
    {
        id: 7,
        link: "/account/add-business/*",
        title: "Add a Business",
        page: <AddBusiness />
    },
    {
        id: 7,
        link: "/account/business-profile/*",
        title: "Business Profile",
        page: <BusinessProfile />
    },
    {
        id: 8,
        link: "/account/all-businesses/*",
        title: "All Businesses",
        page: <AllBusinesses />
    },
    {
        id: 9,
        link: "/account/business-profile/settings/*",
        title: "Business Profile Settings",
        page: <BusinessSettings />
    },
    {
        id: 10,
        link: "/account",
        title: "Account Proxy",
        page: <Account />
    },
    {
        id: 11,
        link: "/account/business-profile/photo-gallery/*",
        title: "Account Proxy",
        page: <PhotoGallery />
    },
]

const publicRouteLinks = [
    {
        id: 1,
        title: "Home",
        link: "/",
        page: <Home />
    },
    {
        id: 2,
        title: "Advertise",
        link: "/advertise",
        page: <Advertise />
    },
    {
        id: 3,
        title: "Business Search",
        link: "/search",
        page: <Search />
    },
    {
        id: 4,
        title: "Find People",
        link: "/find-people",
        page: null
    },
    {
        id: 5,
        title: "Privacy",
        link: "/privacy",
        page: <Privacy />
    },
    {
        id: 6,
        title: "Login",
        link: "/login",
        page: <Login />
    },
    {
        id: 7,
        title: "Signup",
        link: "/signup",
        page: <Signup />
    },
    {
        id: 8,
        title: "Business",
        link: "/business/*",
        page: <Business />
    },
    {
        id: 9,
        link: "/change-email-landing/*",
        title: "Change Email Landing",
        page: <ChangeEmailLanding />
    },
    {
        id: 10,
        link: "/resetpw/*",
        title: "Reset Password",
        page: <ResetPw />
    },
    {
        id: 11,
        link: "/reset-pw-landing/*",
        title: "Reset Password Landing",
        page: <ResetPwdLanding />
    },
    {
        id: 12,
        link: "/signup-landing/*",
        title: "Complete Signup",
        page: <SignupLanding />
    },
    {
        id: 13,
        link: "/home-alt/*",
        title: "Home",
        page: <HomeAlt />
    },
    {
        id: 14,
        title: "Contact us",
        link: "/contact",
        page: <Contact />
    },
    {
        id: 15,
        title: "CBDC Contracts",
        link: "/cbdc-smartcontracts",
        page: <CBDCSmartContracts />
    },
    {
        id: 16,
        title: "API Gateway",
        link: "/api-gateway",
        page: <APIGateway />
    },
    {
        id: 17,
        title: "Outsourcing",
        link: "/outsourcing",
        page: <Outsourcing />
    },
    {
        id: 18,
        title: "Claim Business",
        link: "/claim-business/*",
        page: <ClaimBusiness />
    },
    {
        id: 19,
        title: "Home",
        link: "/*",
        page: <Interceptor />
    },
]

const togglerState = false;
const accessTokenPair = {
    access: "",
    refresh: ""
}

const imgPath = process.env.PUBLIC_URL

const GlobalContext = createContext();
const GlobalSetterContext = createContext();

const colors = {}
colors.headerMain = "bg-[#ffffff]"
colors.headerMainScroll = "bg-[#ffffff]"
colors.button = "bg-[#327456]"



function GlobalProvider({ children }) {
    const [globalNav, setGlobalNav] = useState(navjson);
    const [featuresLinks, setFeaturesLinks] = useState(features);
    let [toggler, setToggler] = useState(togglerState);
    const [REACT_APP_IMG_PATH, setImagePath] = useState(imgPath)
    const [publicRoutes, setPublicRoutes] = useState(publicRouteLinks)
    const [loginError, setLoginError] = useState(null)
    const [isAdsense] = useState(process.env.REACT_APP_IS_ADSENSE)

    const [REACT_APP_API_URL] = useState(process.env.REACT_APP_API_URL)
    const [REACT_APP_IMG_API_URL] = useState(process.env.REACT_APP_IMG_API_URL)
    const [REACT_APP_API_UPLOADS_URI] = useState(process.env.REACT_APP_API_UPLOADS_URI)
    const [REACT_APP_API_USER_IMG_URI] = useState(process.env.REACT_APP_API_USER_IMG_URI)
    const [REACT_APP_API_BUSINESS_GALLERY_IMG_URI] = useState(process.env.REACT_APP_API_BUSINESS_GALLERY_IMG_URI)

    const [REACT_APP_DOMAIN] = window.location.hostname
    const [REACT_APP_PROTOCOL] = window.location.protocol
    const [REACT_APP_PORT] = window.location.port

    const alertContext = useContext(AlertContext)
    const [businessProfile, setBusinessProfile] = useState([])

    let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null);
    let navigate = useNavigate();

    let [horizontalNav, setHorizontalNav] = useState(navjson)
    let [mobileMenu, setMobileMenu] = useState(mobileMenuJson)

    const [gcolors] = useState(colors)

    useEffect(() => {
        if (localStorage.getItem['authTokens'] != null) {
            console.log(JSON.parse(localStorage.getItem['authTokens']))
            console.log('ahaa')
        }
    })

    const loginUser2 = async (data) => {
        alertContext.showAlert("info", "Working...")
        let xdata = JSON.stringify(data)
        let xobj = JSON.parse(xdata)
        let errorReturned = ""

        //alert(xobj)
        let endpoint = REACT_APP_API_URL + '?operation=LOGIN_USER'
        console.log(JSON.stringify(xobj))
        //endpoint += '&email=' + xobj["email"];
        //endpoint += '&password=' + xobj["password"];

        //window.location.href = endpoint
        //alert(endpoint)
        //return false

        let rst = axios.post(endpoint, xobj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(function (result) {

                setAuthTokens(result.data.data[0])
                setUser(jwtDecode(result.data.data[0].access))
                localStorage.setItem('authTokens', JSON.stringify(result.data.data[0]))

                navigate("/search")
                alertContext.fadeOutAlertWithParam(alertContext.timerRef, 1000)
            })
            .catch(function (e) {
                console.log(e)
                setLoginError(e.response.data.data[0].error)
                //setLoginError(e.response.data.data[0].error)
            })
    }

    const loginUser = async (emailStr, passwordStr) => {
        let endpoint = REACT_APP_API_URL + '?operation=LOGIN_USER'
        endpoint += '&email=' + emailStr;
        endpoint += '&password=' + passwordStr;

        const payload = {
            email: emailStr,
            password: passwordStr
        };


        let response = await fetch(
            endpoint,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },

            }
        );

        const result = await response.json();

        if (response.status === 200) {
            setAuthTokens(result.data[0])
            setUser(jwtDecode(result.data[0].access))
            localStorage.setItem('authTokens', JSON.stringify(result.data[0]))
            //console.log(localStorage.getItem('authTokens'))
            //let datad = jwtDecode(result.data[0].access)
            //console.log(datad)

        } else {
            alert("Something went wrong")
        }



        navigate("/")
    }

    const logoutUserWithoutRedirect = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')

    }

    let logoutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        window.location.href = "/login"
    }

    let value = {
        horizontalNav,
        toggler,
        mobileMenu,
        globalNav,
        toggler,
        authTokens,
        user,
        featuresLinks,
        REACT_APP_IMG_PATH,
        publicRoutes,
        protectedAccount,
        REACT_APP_API_URL,
        REACT_APP_IMG_API_URL,
        REACT_APP_API_UPLOADS_URI,
        REACT_APP_API_USER_IMG_URI,
        REACT_APP_API_BUSINESS_GALLERY_IMG_URI,
        REACT_APP_DOMAIN,
        REACT_APP_PROTOCOL,
        REACT_APP_PORT,
        loginError,
        isAdsense,
        businessProfile,
        gcolors
    }

    let valueSetters = {
        setHorizontalNav,
        setToggler,
        setMobileMenu,
        setGlobalNav,
        setToggler,
        setAuthTokens,
        setUser,
        setFeaturesLinks,
        loginUser,
        loginUser2,
        logoutUser,
        setImagePath,
        setPublicRoutes,
        logoutUserWithoutRedirect,
        setLoginError,
        setBusinessProfile
    }



    return (
        <GlobalContext.Provider value={value}>
            <GlobalSetterContext.Provider value={valueSetters}>
                {children}
            </GlobalSetterContext.Provider>
        </GlobalContext.Provider >
    )
}

export { GlobalProvider, GlobalContext, GlobalSetterContext }