import React from 'react'

function CompOne() {
    return (
        <div>
            <div className=' max-w-[1200px] mx-auto w-full'>
                <div className=' grid grid-cols-1 md:grid-cols-2 gap-20 '>
                    <div className=' flex items-center'>
                        <div className=' px-3'>
                            <div className=' text-[80px] sm:text-[110px] tracking-tight text-slate-500/50 font-normal'>
                                8,506K+
                            </div>
                            <div className=' text-[30px] w-full sm:w-[70%] leading-tight'>
                                Over 8.5 <i className=' font-serif'>million</i> lines of prod. source codes

                            </div>
                            <div className=' text-[17px] mt-[10px] w-full sm:w-[70%]'>
                                Why work with us? We ensure our clients sleep peacefully while we do the rest.

                            </div>
                            <div className=' mt-4'>
                                <button className=' bg-black px-5 rounded-full py-2 text-white'>
                                    View More
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='flex place-content-end place-items-center'>
                        <div className=' relative w-full h-[500px] bg-slate-800 '>
                            <img
                                className=' flex object-cover h-full'
                                src="https://leroux.qodeinteractive.com/wp-content/uploads/2023/02/services-img-3.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CompOne
