import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const ChangeEmailSchema = z.object({
    email: z.string()
        .email({ message: "Please enter a valid email" }),
    user_id: z.string()
})
export default ChangeEmailSchema
