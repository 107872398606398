import React, { useEffect } from 'react'
import { useState, useContext } from 'react'
import { SearchIcon } from '@heroicons/react/outline'

import LoginSchema from './LoginSchema'
import { GlobalContext, GlobalSetterContext } from '../../context/GlobalContext'
import { useNavigate } from 'react-router-dom'
import { BiLogIn } from 'react-icons/bi'
import { IoSettingsOutline } from 'react-icons/io5'
import { MdOutlinePassword } from 'react-icons/md'
import { CgPassword, CgSpinner } from 'react-icons/cg'
import { GiPadlock } from 'react-icons/gi'
import AlertContext from '../../context/AlertContext'
import { useForm, input } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link } from 'react-router-dom'


const initialValues = {
    email: "",
    password: ""
}


function Error404content() {
    let { globalNav, toggler, authTokens, loginError } = useContext(GlobalContext)
    const { loginUser, loginUser2, setLoginError } = useContext(GlobalSetterContext)
    const [loading, setLoading] = useState(false)
    const { showAlert, closeAlert, timerRef, fadeOutAlertWithParam } = useContext(AlertContext)
    const [formData, setFormData] = useState(initialValues)
    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const getFormValues = () => {
        return initialValues
    }

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: (getFormValues()),
        resolver: zodResolver(LoginSchema)
    })


    const doLogin = async (values) => {

        showAlert("info", "Working...")
        setLoginError(null)
        setLoading(true)

        let email = values.email
        let password = values.password
        //await new Promise((resolve) => setTimeout(resolve, 1000));
        //loginUser(email, password)
        //alert("here")

        setTimeout(function () {
            loginUser2(values)
        }, 1000)

    }

    useEffect(() => {
        if (loginError) {
            showAlert("danger", "Invalid ID and/or password.")
            fadeOutAlertWithParam(timerRef, 5000)
            setLoading(false)
        }
    }, [loginError])

    useEffect(() => {
        const wkr = document.getElementById("working")
        const wkrStatus = document.getElementById("working-status")
        setTimeout(() => {
            if (wkr && wkrStatus) {
                wkr.innerHTML = "404"
                wkrStatus.innerHTML = "So sorry, we tried but could not locate the page."
            }
        }, 10000)
    }, [])



    return (
        <div>
            <div className=' flex flex-col place-content-center bg-white h-[100vh]'>



                <div className=' w-full'>
                    <div id='working' className=' text-center 
                        font-black text-black text-[25px]
                        mt-[20px] mb-[0px] tracking-tighter
                        '>
                        <div className=' flex place-content-center place-items-center '>
                            <span>
                                <CgSpinner size={25} className=" animate-spin" />
                            </span>
                            <span>Working...</span>
                        </div>
                    </div>
                    <div id='working-status' className=' text-center'>
                        If the page is taking too long, please reload the page.
                    </div>
                    <div className=' text-center flex gap-2 place-content-center'>
                        <Link to={"#"} onClick={() => { window.location.reload() }} className=' underline font-bold'>Refresh Page</Link>
                        <Link to={"/"} className=' underline font-bold'>Go to site</Link>

                    </div>

                </div>



            </div>

        </div>
    )
}

export default Error404content
