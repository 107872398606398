import React, { useContext, useEffect, useRef, useState } from 'react'
import GrayButton from '../../components/GrayButton'
import GalleryButton from '../../components/GalleryButton'
import ReactImageGallery from 'react-image-gallery'
import { GlobalContext } from '../../../../context/GlobalContext';

const images = [
    {
        original: "https://picsum.photos/id/1018/1000/600/",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
        original: "https://picsum.photos/id/1015/1000/600/",
        thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
        original: "https://picsum.photos/id/1019/1000/600/",
        thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
    {
        original: "https://picsum.photos/id/1018/1000/600/",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
        original: "https://picsum.photos/id/1015/1000/600/",
        thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
        original: "https://picsum.photos/id/1019/1000/600/",
        thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
];



const GalleryPreview = ({ imagex }) => {
    const [imgs, setImgs] = useState(imagex)
    const [galleryImg, setGalleryImg] = useState([])
    const refImg = useRef(null)
    const renderCustomControls = () => <span>refImg.current.getCurrentIndex()</span>
    const {
        REACT_APP_API_BUSINESS_GALLERY_IMG_URI
    } = useContext(GlobalContext)

    const [imgO, setImgO] = useState(null)
    const [reconstructedImgJson, setReconstructedImgJson] = useState([])


    const onClickHandler = () => {
        refImg.current.fullScreen();
        document.getElementsByClassName("image-gallery-slide-wrapper")[0].style.display = 'block'
    };
    const onScreenChangeHandler = () => {
        document.getElementsByClassName("image-gallery-slide-wrapper")[0].style.display = 'none'
    }

    useEffect(() => {
        let imgObj = null
        if (imgs !== null) {
            imgObj = JSON.parse(imgs)
        }
        if (imgObj !== null) {
            setImgO(imgObj)
            console.log(imgObj)
        }
    }, [imgs])



    useEffect(() => {
        let mapper = []
        if (imgO !== null) {
            imgO.data.map((item) => {
                let mapItem = {}
                mapItem.original = REACT_APP_API_BUSINESS_GALLERY_IMG_URI + item.image_filename
                mapItem.thumbnail = REACT_APP_API_BUSINESS_GALLERY_IMG_URI + item.image_filename
                mapper.push(mapItem)
            })
            let stringified = JSON.stringify(mapper)
            setReconstructedImgJson(mapper)
            console.log(stringified)
            console.log(images)
        }
    }, [imgO])

    useEffect(() => {
        if (reconstructedImgJson !== null) {
            //alert(reconstructedImgJson)
        }
    }, [reconstructedImgJson])
    return (
        <div className=' h-fit'>
            <style>
                {`
                .fullscreen-modal{
                    z-index: 10000 !important;
                }
                .image-gallery-thumbnails-wrapper{
                width:100%;
                display:none;
                }
                .image-gallery-slide-wrapper{
                    display: none;
                }
                .image-gallery-thumbnail{
                    width: 150px;
                    
                }
                .image-gallery-thumbnail-image{
                    height: 140px;
                }
                .image-gallery-thumbnails-container{
                    position:relative;
                    float:left;
                }
                `}
            </style>
            <div onClick={onClickHandler}>
                <GrayButton
                    title={"Preview Gallery"}
                    url={"#"}
                />
            </div>
            {
                reconstructedImgJson &&
                < ReactImageGallery
                    items={reconstructedImgJson}
                    useBrowserFullscreen={false}
                    onThumbnailClick={() => {
                        onClickHandler()

                    }}
                    ref={refImg}
                    onScreenChange={() => {
                        onScreenChangeHandler()
                    }}

                    disableThumbnailScroll={false}

                />
            }
        </div>
    )
}

export default GalleryPreview
