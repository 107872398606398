import { CheckIcon } from '@heroicons/react/outline'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { CgClose } from 'react-icons/cg'
import { GlobalContext } from '../../../../context/GlobalContext'

const DeleteGalleryImage = ({ imageData }) => {

    const [image, setImage] = useState(imageData)
    const [open, setOpen] = useState()
    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)
    const [maxWidth, setMaxWidth] = useState('sm');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [msg, setMsg] = useState("")
    const { REACT_APP_API_URL } = useContext(GlobalContext)

    const handleClickOpen = () => {
        setOpen(true)
        setComplete(false)
        setLoading(false)
    }

    const handleClose = () => {
        setOpen(false)

    }

    useEffect(() => {
        if (image) {
            //console.log(image)
        }
    }, [image])

    const deleteImage = async (formData) => {
        //alert(JSON.stringify(formData))
        setLoading(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        let url = REACT_APP_API_URL + '?operation=DELETE_BUSINESS_GALLERY_IMAGE'
        url += "&image_guid=" + formData["image_guid"]
        url += "&business_id=" + formData["business_id"]
        url += "&title=" + formData["title"]
        url += "&image_filename=" + formData["image_filename"]

        //alert(url)

        //window.location.href = url
        //return false
        try {
            let rst = axios.post(url, formData)
                .then(function (response) {
                    setMsg(response.data);
                    setComplete(true)
                    //setLoading(false)
                    window.location.reload();
                })
                .catch(function (e) {
                    setMsg(e.response.data)
                    setComplete(true)
                    setLoading(false)
                })


        } catch (e) {

        }
    }

    return (
        <div>
            <div
                onClick={handleClickOpen}
                className=' w-fit bg-white absolute right-[10px] top-[10px] cursor-pointer'>
                <CgClose size={20} />
            </div>

            <Dialog
                open={open}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: async (event) => {
                        event.preventDefault()
                        setLoading(true)
                        const formData = new FormData(event.currentTarget)
                        const formJson = Object.fromEntries(formData.entries())
                        formData.image_filename = image.image_filename
                        formData.title = image.title
                        formData.image_guid = image.image_guid
                        formData.business_id = image.business_id
                        await deleteImage(formData)
                        //await new Promise((resolve) => setTimeout(resolve, 2000));
                        //setComplete(true)
                        //setLoading(false)
                        //await new Promise((resolve) => setTimeout(resolve, 3000));
                        //setComplete(false)
                        //handleClose()

                    },
                }}
            >
                <DialogTitle>
                    <div className=' font-bold text-red-900'>
                        Delete Picture
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {complete ? 'This picture was successfully deleted.' : 'Do you wish to delete this picture. It will be permanently removed from our systems. This cannot be undone. Do you wish to proceed?'}
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{complete ? 'Close' : 'Cancel'}</Button>
                    <Button type="submit" disabled={complete || loading ? "disabled" : ''}>
                        {loading ? <CircularProgress size={20} /> :

                            complete ? <CheckIcon /> : 'Proceed'

                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DeleteGalleryImage
