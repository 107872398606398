import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { UtilContext } from '../../context/UtilContext'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { CgSpinner } from 'react-icons/cg'
import AdBanner from '../../components/ads/Adbanner'
import { Link, NavLink } from 'react-router-dom'
import { EyeIcon, MusicNoteIcon, StarIcon } from '@heroicons/react/outline'
import { BsBank, BsChatDots, BsDot, BsEyeFill, BsStarFill } from 'react-icons/bs'
import { Fa500Px, FaGoodreads, FaGoodreadsG, FaMusic, FaShoePrints, FaShoppingCart, FaWineGlass } from 'react-icons/fa'
import { GrStatusGood } from 'react-icons/gr'
import { MdFmdGood } from 'react-icons/md'
import { GiBalloonDog, GiBullets, GiSilverBullet } from 'react-icons/gi'
import { HiOutlineStatusOnline } from 'react-icons/hi'
import { BiSolidDockTop, BiSolidLogOutCircle } from 'react-icons/bi'
import { Fa0, Fa1, FaAnglesRight, FaShop } from 'react-icons/fa6'
import { PiBulldozerThin, PiListBulletsDuotone } from 'react-icons/pi'
import { HiListBullet } from 'react-icons/hi2'
import { RxListBullet } from 'react-icons/rx'
import { RiDoubleQuotesL } from 'react-icons/ri'
import axios from 'axios'
import GetDates from '../business/GetDates'
import GetDatesForSearch from '../business/GetDatesForSearch'
import RatingForSearch from '../home/RatingForSearch'
import FacilitiesForSearch from '../home/FacilitiesForSearch'
import VisitorViewCount from '../business/VisitorViewCount'

const SearchResultGrid = ({ data }) => {


    const { REACT_APP_IMG_PATH, REACT_APP_API_UPLOADS_URI } = useContext(GlobalContext)
    const [loaded, setLoaded] = useState(false)
    const { getSubstr } = useContext(UtilContext)

    const [workDays, setWorkDays] = useState(null)
    const { REACT_APP_API_URL } = useContext(GlobalContext)

    function getSubstring(inputStr, strlen) {

        let substr = "";

        if (inputStr.length > strlen) {
            substr = inputStr.substring(0, strlen) + "...";
        } else {
            substr = inputStr
        }
        return substr;
    }

    function getRowId(rowId) {
        return rowId
    }

    function GetRows(item) {

        return (
            <div className=' mb-4'>
                <div className=' flex gap-3  
                border-gray-300 border-b-[1px] pb-4'>
                    <div className=' w-[120px] min-w-[120px] 
                    h-[140px] relative border-[1px]
                    border-gray-500/50 shadow-md
                    '>
                        <a href={"/business/" + item?.hash}>
                            <img
                                className=' object-cover w-full h-full'
                                src={item?.img ? REACT_APP_API_UPLOADS_URI + item?.img : REACT_APP_IMG_PATH + "/img/imgbus.png"}
                                alt=""
                            />
                        </a>
                    </div>
                    <div className=' w-full h-auto flex  gap-3'>
                        <div className='w-full grow '>
                            <div>
                                <div className=' mb-1'>
                                    <span className=' text-[11px] 
                            font-bold
                         pt-[8px] uppercase  rounded-[5px]
                        '>
                                        {item?.business_category}
                                    </span>
                                </div>

                                <div className=' font-bold text-[17px]
                        leading-tight inline-block w-full
                        '>
                                    <a href={"/business/" + item?.hash}>
                                        <div>{item?.title}</div>
                                    </a>
                                </div>

                                <div className=' leading-tight mt-[8px]'>
                                    {getSubstr(item?.intro, 200)}
                                </div>
                                <div className=' capitalize leading-tight font-bold 
                        mt-[5px] text-[12px]
                        '>
                                    {item?.business_phrases}
                                </div>

                                <div className=' mt-[5px] text-[13px] tracking-tighter'>
                                    {item?.country}
                                </div>
                                <div className=' mt-[10px] inline-block w-full'>
                                    <a href={"/business/" + item?.hash}>
                                        <div
                                            className='
                                font-bold bg-gray-200 border-[1px] w-[50px]
                                text-black rounded-[8px] py-[2px]
                                text-center border-gray-500'
                                        >
                                            Go
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='hidden w-[250px]   md:block'>
                            Hello
                        </div>

                    </div>
                </div>
                {/* <div key={item?.id} className=' border-[1px] border-gray-600/20  rounded-md p-4 gap-3 grid grid-cols-12 mb-4 hover:bg-gray-400/5'>

                    <div className=' w-[130px] h-auto col-span-2 flex place-content-left'>
                        <div className=' w-[130px] h-[130px] relative  border-[1px] border-gray-600/10 overflow-hidden flex place-items-center place-content-center'>
                            <img
                                src={item?.img ? REACT_APP_API_UPLOADS_URI + item?.img : REACT_APP_IMG_PATH + "/img/imgbus.png"}
                                alt=""
                                className={`${!item?.img ? 'opacity-55 w-[70%] h-[70%]' : 'w-full h-full'} object-cover   border-[1px] border-none `}
                            />
                        </div>
                    </div>
                    <div className='  col-span-10 relative '>
                        <div className=' grid grid-cols-12 gap-1'>
                            <div className=' col-span-8'>
                                <div className='text-[22px] font-normal text-purple-800/70 tracking-tight'>
                                    <Link to={"/business/" + item.hash}>
                                        {item.title}
                                    </Link>
                                </div>
                                <div className=' text-[13px] font-semibold text-black/80'>
                                    {getSubstring(item.business_phrases, 70)}
                                </div>

                                <div className='flex place-items-center space-x-2 text-[14px] mt-2 mb-0 font-normal'>
                                    {item?.website && <span><a href={item?.website} className=' text-blue-800'>Website</a></span>}
                                    {item?.xsoc && <div className='  flex place-content-center place-items-center  '>
                                        <a href={`http://x.com/` + item?.xsoc} className=' text-orange-800 font-bold'>
                                            Twitter
                                        </a>
                                    </div>}

                                    {item?.fbsoc && <div className='  flex place-content-center place-items-center'>
                                        <a href={`http://facebook.com/` + item?.fbsoc} className=' text-orange-800 font-bold'>
                                            Facebook
                                        </a>
                                    </div>}

                                    {item?.linksoc && <div className='  flex place-content-center place-items-center  '>
                                        <a href={`http://linkedin.com/company/` + item?.linksoc} className=' text-orange-800 font-bold'>
                                            LinkedIn
                                        </a>
                                    </div>}


                                </div>

                                <div className=' mt-[2px] text-gray-600'>
                                    <div className='flex place-items-center space-x-2'>
                                        <BsBank size={20} className='' />
                                        <div className=' space-y-[-2px]'>
                                            <div className=' relative top-[3px] font-black text-[12px] text-black'>{item.established}</div>
                                            <div className=' relative text-[12px]'>
                                                In Business
                                            </div>
                                        </div>
                                        <div className=' border-l border-gray-500 pl-2 ml-2 pt-1'>
                                            CATEGORY:
                                            <a className='text-green-900 font-semibold ml-1' href={`/search?what=` + item.business_category.toLowerCase()}>
                                                {item.business_category}
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className=' col-span-4 '>
                                <div className=' pt-2  pb-3'>
                                    <div className=' font-semibold tracking-tighter text-right text-[16px]'>
                                        +{item.country_code}{" "}{item.mobile}
                                    </div>
                                    <div className='formatTexts font-normal tracking-tight text-right text-[14px]'>
                                        {getSubstring(item.address_one, 25)}
                                    </div>
                                    <div className=' font-normal tracking-tight text-right text-[14px]'>
                                        {item.address_two}
                                    </div>
                                    <div className='relative font-normal tracking-tight text-right text-[14px]'>

                                        {item.country}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='relative flex text-[13px] mt-2 tracking-tight'>
                            <div>
                                <RiDoubleQuotesL
                                    className=' flex text-gray-900'
                                    size={20}
                                />
                            </div>
                            <div>{getSubstring(item.intro, 250)}</div>
                        </div>
                    </div>


                </div> */}
            </div>

        )
    }

    function computeRating(totalx) {
        return Math.round(totalx);
    }

    function getStars(rating) {
        let i = 0;
        let limit = computeRating(rating);
        let stars = [];
        for (i = 0; i < limit; i++) {
            stars.push(<BsStarFill key={i} className=' text-yellow-400' />)
        }
        return stars;
    }

    function getDots(visits) {
        let i = 0;
        let limit = computeRating(visits);
        let stars = [];
        for (i = 0; i < limit; i++) {
            stars.push(<div key={i} className=' bg-green-800 h-[15px] w-[15px] rounded-full' />)
        }
        return stars;
    }

    function getRow(item) {
        item.price = 30
        return (
            <div key={item.id} className=' hover:bg-slate-100 
            mb-4 border-[1px] border-gray-400/50 pb-2 pt-2
rounded-[8px] shadow-md px-2
            '>
                <a href={"/business/" + item.hash}>
                    <div className=' grid grid-cols-12 gap-4 py-3 '>
                        <div className='  
                        col-span-3 
                        sm:col-span-3 relative 
                        h-[30%] md:h-[50%] w-full  flex 
                        border-[1px] border-gray-300 
                        place-items-start rounded-[8px]
                        overflow-hidden
                         '>
                            <img
                                src={item?.img ? REACT_APP_API_UPLOADS_URI + item?.img : REACT_APP_IMG_PATH + "/img/dummy.jpeg"}

                                alt=""
                                className=' object-cover h-full w-full' />
                        </div>
                        <div className=' col-span-9 sm:col-span-9'>
                            <div className=' font-bold text-black text-[12px] tracking-tight leading-tight pr-3'>
                                {item.business_category}
                            </div>
                            <div className=' font-bold text-black text-[22px] 
                            tracking-tight leading-tight pr-3
                            border-b-[1px] border-gray-500/20
                            pb-[5px]
                            '>
                                {item.title}
                            </div>
                            <div className=' text-[14px] mt-2 leading-[17px] font-sans pr-3'>
                                {item.intro.substring(0, 150)}
                            </div>
                            {/* <div className=' text-[20px] font-black tracking-tighter mt-1 '>
                                <span>+{item.country_code}</span> <span>{(item.mobile)}</span>
                            </div> */}
                            {/* <div className=' flex mt-4'>
                                {getStars(3)}
                                <div className=' relative top-[-2px] ml-2 mr-[-3px] h-[20px] border-r-[1px] border-gray-400'></div>
                                <span className=' text-[15px] font-bold relative top-[-3.5px] text-gray-500 ml-2'>{3}</span>
                            </div> */}
                            <div className=' mt-[5px] text-gray-700'>
                                <div className=' 
                                grid grid-cols-1 gap-0
                                 place-content-center
                                place-items-start
                                md:grid-cols-12 
                                '>
                                    <div className=' 
                                    flex place-items-center gap-2
                                     w-full
                                    md:col-span-3
                                    '>
                                        <div className=''>
                                            <BsBank size={20} className='' />
                                        </div>
                                        <div className=''>
                                            <div className=' space-y-[-2px]'>
                                                <div className=' relative top-[3px] font-black text-[12px] text-black'>{item.established}</div>
                                                <div className=' relative text-[12px]'>
                                                    In Business
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='
                                    flex place-content-start
                                     w-full pt-1
                                    md:col-span-9
                                    '>
                                        <span className=' text-[15px]'>
                                            Contact:
                                        </span>
                                        <a className='text-[15px] font-bold ml-1' href={`/search?what=` + item.business_category.toLowerCase()}>
                                            <span>+{item.country_code}</span> <span>{(item.mobile)}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className=' text-sm mt-1'>
                                {item.business_phrases}
                            </div>

                            <div className=' mt-3'>

                                <a
                                    href={item.link}
                                    className=' bg-red-700 text-white 
                                    px-5 pt-[3px] pb-[6px] rounded-full
                                    mt-2 ml-[-3px] hover:bg-blue-700
                                    text-[15px]
                                    '>
                                    Continue</a>

                            </div>
                        </div>
                    </div>
                </a>
            </div>
        )
    }

    function getLink(item) {
        let lnk = ""
        if (item?.username !== "" && item?.username !== null) {
            lnk = "/" + item?.username
        } else {
            lnk = "/business/" + item?.business_id
        }
        return lnk
    }

    function getImg(item) {
        return item?.img ? REACT_APP_API_UPLOADS_URI + item?.img : REACT_APP_IMG_PATH + "/img/dummy.jpeg"
    }

    function displayRow(item) {
        item.rating = 4
        return (
            <div key={item.gid} className=' mb-5 hover:opacity-90 group'>
                <div className=' flex space-x-4 '>

                    <div className='relative h-[180px] w-[150px] '>
                        <Link href={getLink(item.gid)} id={item.id}>
                            <img
                                src={item?.img ? REACT_APP_API_UPLOADS_URI + item?.img : REACT_APP_IMG_PATH + "/img/dummy.jpeg"}

                                className='rounded-md object-cover w-full h-full' />
                        </Link>
                        <div className=' absolute bottom-0 w-full h-[40%] bg-gradient-to-t from-black/55 to-transparent rounded-lg'></div>
                    </div>
                    <div className='w-auto '>
                        <Link href={getLink(item.gid)}>
                            <div className='font-bold text-lg leading-5 text-blue-800 w-full group-hover:underline'>{item.title}</div>
                        </Link>
                        <div className=' flex mt-2 space-x-10'>
                            <div className=' w-full'>
                                <div className='text-sm text-blue-800 font-bold underline'>{item?.country}</div>
                                <div className=' text-gray-500 mt-3 '>{item?.tour_duration} {item?.tour_duration_category}</div>
                                <div className=' text-gray-500 mt-3  text-[13px]'>{item?.intro && getSubstring(item?.intro, 100)}</div>
                                <div className=' capitalize mt-2 text-[14px] font-bold'>{item?.established}</div>
                                <div className=' capitalize mt-[3px] text-sm text-gray-500'>{item?.business_phrases}</div>
                                <div className=' capitalize mt-1 text-gray-500 flex ml-[-3px] gap-[2px] text-[17px]'>


                                    {
                                        (
                                            () => {

                                                let i = 0;
                                                let limit = computeRating(item.rating);
                                                let stars = [];
                                                for (i = 0; i < limit; i++) {
                                                    stars.push(<BsStarFill className=' text-yellow-400' />)
                                                }
                                                return stars;
                                            }
                                        )()
                                    }
                                    <div className=' relative top-[-2px] ml-2 mr-[-3px] h-[20px] border-r-[1px] border-gray-400'></div>
                                    <span className=' text-[15px] font-bold relative  text-gray-500 ml-2'>{item.rating}</span>
                                </div>
                            </div>

                            <div className='min-w-[150px]  flex flex-col place-items-end '>
                                <div className='font-bold text-[15px]'>+{item.country_code} {item.mobile}</div>
                                <div className='text-right text-[13px]'>{item?.address_one}</div>
                                <div className='text-right text-[13px]'>{item?.address_two}</div>
                                <div>
                                    <Link href={getLink(item.gid)}>
                                        <button className=' bg-orange-300 px-5 py-2 h-10 rounded-full mt-2 ml-[-3px] hover:bg-orange-200'>Reserve</button>
                                    </Link>
                                </div>

                            </div>
                        </div>


                    </div>
                </div >
            </div >
        )
    }

    function dispRow(item) {
        item.rating = 4
        item.visits = 5
        let openHours = <GetDatesForSearch businessData={item} />
        return (
            <div className=' border-[1px] border-blue-700/25 my-3 rounded-[8px] h-auto overflow-hidden'>
                <div className=' flex flex-wrap w-full'>
                    <div className=' w-[27%] md:w-[20%] bg-gradient-to-r from-blue-50/50 to-blue-0 '>
                        <a href={getLink(item)}>
                            <div className=' m-2 relative h-[120px] rounded-[0px] overflow-hidden flex place-content-center place-items-center'>
                                <img
                                    src={getImg(item)}
                                    alt=""
                                    className=' object-cover h-full w-auto'
                                />
                            </div>
                        </a>
                    </div>
                    <div className='  w-[73%] bg-gradient-to-l from-blue-50/50 to-blue-0 md:w-[55%] md:bg-none'>
                        <div className=' mx-2 my-2'>
                            <div className=' text-black text-[25px] font-black tracking-tighter leading-tight'>
                                <a href={getLink(item)} className=' w-full inline-block'>
                                    {item?.title}
                                </a>
                            </div>
                            <div className=' truncate text-[13px] font-medium text-black'>
                                {item?.business_phrases}
                            </div>
                            {/* <div className=' flex place-items-center mt-1'>
                                <div className='flex text-[17px] place-items-center'>
                                    <div className='flex text-[19px]'>
                                        {
                                            item?.business_id &&
                                            <RatingForSearch id={item?.business_id} />
                                        }
                                    </div>
                                    <div className='border-r-[1px] border-gray-400 mr-[6px]'>&nbsp;</div>
                                    <div className='relative text-[14px]  mr-[3px]'>Visitors: </div>
                                    <div className=' flex place-items-center text-[14px] ml-[3px]'>
                                        <div className=''>
                                            {
                                                item?.business_id &&
                                                <VisitorViewCount size={20} businessId={item?.business_id} />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div> */}
                            <div className=' mt-1 flex place-content-start place-items-center gap-2'>
                                <div>
                                    <a href={item?.website ? item?.website : '#'} className=' text-blue-800 font-semibold underline'>Website</a>
                                </div>
                                <div>
                                    <a href={`http://www.google.com/maps/search/${item?.address_one}, ${item?.address_two}}`} className=' text-blue-800 underline'>Visit Map</a>
                                </div>
                                <div className=' flex place-items-center font-bold md:hidden'>
                                    {item !== null
                                        && openHours
                                    }
                                </div>

                            </div>
                            <div className=' mt-1'>
                                <div className=' hidden md:gap-2 md:flex'>
                                    <div>Amenities: </div>
                                    <div className=' flex-wrap text-[13px] gap-3 place-content-start place-items-center'>

                                        {
                                            item?.business_id &&
                                            <FacilitiesForSearch id={item?.business_id} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className=' flex mt-1 place-items-center gap-2 font-bold md:hidden'>
                                <div className=' '>
                                    Mobile:
                                </div>
                                <div className=' flex place-items-center text-[15px]'>
                                    +{item?.country_code} {item?.mobile}
                                </div>
                            </div>
                            <div className='flex mt-2'>
                                <div className=' text-[17px]'>
                                    <RiDoubleQuotesL />
                                </div>
                                <div className=' text-[13.5px]'>
                                    {getSubstr(item?.intro, 150)}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className=' bg-[#327456]/15   w-[100%] md:w-[25%] hidden md:block'>
                        <div className=' m-3 h-full'>
                            <div className=' text-[15px] font-bold text-right truncate'>
                                +{item?.country_code} {item?.mobile}
                            </div>
                            <div className=' text-right text-[14px] truncate'>
                                {item?.address_one}
                            </div>
                            <div className=' text-right text-[14px] truncate'>
                                {item?.address_two}
                            </div>
                            <div className=' text-right text-[14px] mt-2 font-bold'>
                                {item !== null
                                    && openHours
                                }
                            </div>
                            <div className='flex place-content-end place-items-end h-[40%]'>
                                <a href={getLink(item)}>
                                    <div className=' px-5 bg-[#327456]/80 shadow-md text-white rounded-[4px] h-[30px] mr-1 mb-3 flex place-items-center'>
                                        Visit
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
    const columns = [
        {
            field: 'title',
            flex: 1,
            renderHeader: () => {
                return (
                    <div>Search Results</div>
                )
            },
            renderCell: (params) => {


                return (
                    <div className=''>
                        {
                            (params.api.getAllRowIds().indexOf(params.id) + 1) % 3 ?
                                <div>
                                    {dispRow(params.row)}
                                    {/* {params.api.getRowIndexRelativeToVisibleRows(params.row.id)} */}
                                </div> :
                                <div>
                                    {dispRow(params.row)}

                                    <div className='mb-1 mt-4'>
                                        <AdBanner
                                            dataAdSlot={'3148685431'}
                                            dataAdFormat={'auto'}
                                            dataFullWidthResponsive={true}
                                        />
                                    </div>



                                </div>
                        }

                    </div>
                )
            }
        },
    ];

    const datax = [
        {
            id: 1,
            title: "LemonBuyBack.com-Law Offices of Jon Jacobs",
            category: "Services",
            phone: "(818) 626-4164",
            address_one: "100 N Brand Blvd Ste 200",
            address_two: "Glendale, CA 91230",
            established: "Since 1907",
            img: "https://i.pinimg.com/736x/76/b9/ec/76b9eca569211813052630d50450af3b.jpg",
            intro: "We are the best in services law firm in all the United States and China. We are the best in services law firm in all the United States and China."
        },
        {
            id: 2,
            title: "Sefyan Law Firm P.C.",
            category: "Services",
            phone: "(818) 626-4164",
            address_one: "100 N Brand Blvd Ste 200",
            address_two: "Glendale, CA 91230",
            established: "Since 1907",
            img: "https://i.pinimg.com/236x/0c/44/fe/0c44fe03f0176bda6744562648c10b89.jpg",
            intro: "We are the best in services law firm in all the United States and China. We are the best in services law firm in all the United States and China."
        },
        {
            id: 3,
            title: "LA Injury Group",
            category: "Services",
            phone: "(818) 626-4164",
            address_one: "100 N Brand Blvd Ste 200",
            address_two: "Glendale, CA 91230",
            established: "Since 1907",
            img: "https://i.pinimg.com/736x/6c/5b/cd/6c5bcd50a4fffeef2c461d7d2c2d73bd.jpg",
            intro: "We are the best in services law firm in all the United States and China. We are the best in services law firm in all the United States and China."
        },
        {
            id: 4,
            title: "JT Legal Group",
            category: "Services",
            phone: "(818) 626-4164",
            address_one: "100 N Brand Blvd Ste 200",
            address_two: "Glendale, CA 91230",
            established: "Since 1907",
            img: "https://i.pinimg.com/236x/40/e7/fc/40e7fceaf336803963a07ff72995aa95.jpg",
            intro: "We are the best in services law firm in all the United States and China. We are the best in services law firm in all the United States and China."
        },
        {
            id: 5,
            title: "Law Offices of Arthur S. Charchian",
            category: "Services",
            phone: "(818) 626-4164",
            address_one: "100 N Brand Blvd Ste 200",
            address_two: "Glendale, CA 91230",
            established: "Since 1907",
            img: "https://40plusstyle.com/wp-content/uploads/2023/08/kimdenim.jpg",
            intro: "We are the best in services law firm in all the United States and China. We are the best in services law firm in all the United States and China."
        },
        {
            id: 6,
            title: "Glendale Law Group, APC",
            category: "Services",
            phone: "(818) 626-4164",
            address_one: "100 N Brand Blvd Ste 200",
            address_two: "Glendale, CA 91230",
            established: "Since 1907",
            img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnM4V6ZSV3e7V-_ojrJ6Re8ugspDeR7sdMqw&s",
            intro: "We are the best in services law firm in all the United States and China. We are the best in services law firm in all the United States and China."
        },
        {
            id: 7,
            title: "Law Office of Noelle Halaby",
            category: "Services",
            phone: "(818) 626-4164",
            address_one: "100 N Brand Blvd Ste 200",
            address_two: "Glendale, CA 91230",
            established: "Since 1907",
            img: "https://i.pinimg.com/564x/4d/04/2c/4d042c0dc0adfedcd54f01f776a63dae.jpg",
            intro: "We are the best in services law firm in all the United States and China. We are the best in services law firm in all the United States and China."
        },
        {
            id: 8,
            title: "RJB Law Offices (Ray Bulaon)",
            category: "Services",
            phone: "(818) 626-4164",
            address_one: "100 N Brand Blvd Ste 200",
            address_two: "Glendale, CA 91230",
            established: "Since 1907",
            img: "https://preview.redd.it/modest-but-still-aesthetic-trendy-outfit-inspo-from-v0-ez63fajmnbbc1.jpg?width=736&format=pjpg&auto=webp&s=4381e0fc51306367bea279a4f54987cbec2338e2",
            intro: "We are the best in services law firm in all the United States and China. We are the best in services law firm in all the United States and China."
        },

    ]

    const autosizeOptions = {
        includeOutliers: true,
    };

    const apiRef = useGridApiRef();

    useEffect(() => {
        setTimeout(() => {
            if (data) {
                setLoaded(true)
            }
        }, 100)
    })

    return (
        <div className=''>
            {
                data ? <DataGrid
                    sx={{
                        '&, [class^=MuiDataGrid]': { border: 'none !important', padding: '0px' },
                        '& .MuiDataGrid-row:hover': {
                            backgroundColor: 'transparent',

                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: ' none !important',

                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: ' none',
                            backgroundColor: 'transparent'
                        },
                        '& .MuiDataGrid-cell:mouseover': {
                            backgroundColor: 'red'
                        },

                    }}
                    apiRef={apiRef}
                    rows={data}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 15,
                            },
                        },
                    }}
                    onPaginationModelChange={() => {
                        window.scrollTo(0, 0)
                    }}
                    pageSizeOptions={[15]}
                    getRowHeight={() => 'auto'}
                    autoHeight
                    disableRowSelectionOnClick
                    autosizeOptions={autosizeOptions}
                    disableColumnSorting
                    disableColumnMenu
                    disableColumnSelector
                    columnHeaderHeight={0}


                /> : <div className=' relative top-2'>
                    <div className=' flex flex-col place-content-center
                    place-items-center h-[200px] font-semibold
                    text-[15px]
                    '>
                        No Data Found
                    </div>
                </div>

            }
            {/* {
                data ? data.map((item, index) => {

                    return (
                        <div>
                            {
                                (index + 1) % 3 ? dispRow(item) :

                                    <div>
                                        {dispRow(item)}
                                        <div className='mb-2'>
                                            <AdBanner
                                                dataAdSlot={'3148685431'}
                                                dataAdFormat={'auto'}
                                                dataFullWidthResponsive={true}
                                            />
                                        </div>
                                    </div>
                            }
                        </div>
                    )
                }) :
                    'No Data Found'
            } */}
        </div>
    )
}

export default SearchResultGrid
