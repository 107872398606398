import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const SearchSchema = z.object({
    what: z.string(),
    where: z.string(),
})

export default SearchSchema
