import React, { useContext, useEffect, useState } from 'react'
import HeaderLightSearch from '../../components/header/headerLight/HeaderLightSearch'
import { useSearchParams } from 'react-router-dom'
import { GlobalContext } from '../../context/GlobalContext'
import axios from 'axios'
import H32 from '../../components/body/H32'
import HeaderSpace from '../../components/header/HeaderSpace'
import SubNavFlex from '../../components/body/subnavflex/SubNavFlex'

const SignupLanding = () => {
    let [searchParams] = useSearchParams()
    const id = searchParams.get("guid")

    const [signupData, setSignUpData] = useState(null)
    const [signupError, setSignupError] = useState(null)
    const [guid, setGuid] = useState(id)

    let { REACT_APP_API_URL, gcolors } = useContext(GlobalContext)

    const getSignupStatus = async () => {
        let endpoint = REACT_APP_API_URL + "?operation=WESIG"
        endpoint += "&guid=" + guid


        //window.location.href = (endpoint)
        //return false;

        let dat = await axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((resp) => {
            //alert(resp.data.data[0])
            setSignUpData(resp.data.data[0])
        }).catch(function (e) {
            let error = e.response.data?.data[0]
            let errorInfo = error.error

            errorInfo && setSignupError(errorInfo)
        })


        /* if (res.status === 200) {
            setData(res.data.data[0])
            return true
        } else {
            setChangeEmailError("Sorry, we could not complete the request this time.")
            return false
        } */
    }

    useEffect(() => {
        getSignupStatus()
    })

    return (
        <div>
            <HeaderLightSearch
                bgColor={"bg-transparent"}
                bgColorOnScroll={"bg-white"}
                logoColor={"text-[#fff]"}
                logoTextColor={"text-[#4b0d96]"}
                logoTextColorOnScroll={"text-[#4b0d96]"}
                logoColorOnScroll={"text-[#fff]"}
                logoBgColor={"bg-[#4b0d96]"}
                logoBgColorOnScroll={"bg-[#4b0d96]"}
                color={"text-[#fff]"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />



            <div className='
            max-w-[600px] mx-auto w-full flex flex-col place-content-center
            h-screen px-5
            '>

                <div className='
                py-2 px-6 border-[1px] border-gray-300
                rounded-[12px] shadow-md
                '>
                    <div className='my-5'>
                        <div className=' text-[22px] font-black tracking-tight border-b-[1px] 
                    border-black/30 px-[2px] py-1'>
                            {signupData ? <div>Welcome, {signupData?.first_name}</div> : 'Link Expired'}
                        </div>
                    </div>

                    <div className=' pt-0 pb-12'>
                        <div className=' grid grid-cols-1 gap-4'>
                            {
                                signupData !== null ? <div className='  space-y-4'>
                                    <div>
                                        You have completed your signup.
                                    </div>
                                    <div className=' border-[1px] border-gray-600/30 rounded-md p-4 text-[16px] font-semibold'>
                                        Your login email is <b>{signupData?.email}</b>
                                    </div>

                                    <div className=' space-x-2'>
                                        <span>Click here to sign into your account.</span>
                                        <button
                                            onClick={() => {
                                                window.location.href = "/login"
                                            }}
                                            className=' bg-green-700 rounded-[4px] hover:bg-green-800 text-white px-3 border-[1px] border-blue-900/30 pt-[0px] pb-[2px]'
                                        >
                                            Login</button>
                                    </div>
                                </div> :
                                    <div>
                                        <div className=' border-[1px] border-gray-600/30 rounded-md p-4 text-[14px] font-semibold'>
                                            <b>{signupError && signupError}</b>
                                        </div>
                                    </div>

                            }



                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignupLanding
