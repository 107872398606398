import React, { useEffect, useState } from 'react'

function Impact({ impacts }) {
    const defaulData = [
        {
            id: 1,
            figure: "10,000+",
            description: "Number of M&A related projects completed"
        },
        {
            id: 2,
            figure: "18%",
            description: "Average additional value that companies using Bain for integration support realize compared to those that don’t"
        },
    ]

    const [impactsMap, setImpactsMap] = useState(defaulData)

    useEffect(() => {
        if (impacts) {
            setImpactsMap(impacts)
        }
    }, [impacts])
    return (
        <div>
            <div className=' px-5'>
                <div className=' max-w-[1200px] mx-auto w-full'>
                    <div className=' grid grid-cols-1 md:grid-cols-2 gap-10'>
                        {
                            impactsMap.map((item) => {
                                return (<div key={item.id}>
                                    <div className=' grid grid-cols-1 place-items-center space-y-5 place-content-between'>
                                        <div className=' text-6xl font-bold'>
                                            {item.figure}
                                        </div>
                                        <div className=' text-[17px] px-8 text-center'>
                                            {item.description}
                                        </div>
                                    </div>
                                </div>)
                            })
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Impact
