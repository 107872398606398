import React, { useContext, useEffect, useRef, useState } from 'react'
import { BsStarFill } from 'react-icons/bs'
import TextAreaCustom from '../../account/components/TextAreaCustom'
import { GlobalContext } from '../../../context/GlobalContext'
import AlertContext from '../../../context/AlertContext'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import RatingReviewSchema from './RatingReviewSchema'
import { UtilContext } from '../../../context/UtilContext'
import { useCookies } from 'react-cookie'
import { sha256 } from 'js-sha256'
import { Rating, styled } from '@mui/material'
import { Box } from '@mui/system'
import { FaStar } from 'react-icons/fa'
import StyledRating from './StyledRating'
import H32 from '../../../components/body/H32'
import H16 from '../../../components/body/H16'

const ratinglevels = [
    {
        rating: 1,
        title: "Poor"
    },
    {
        rating: 2,
        title: "Fair"
    },
    {
        rating: 3,
        title: "Good"
    },
    {
        rating: 4,
        title: "Very Good"
    },
    {
        rating: 5,
        title: "Exceptional"
    },
]
const initialValues = {
    fullname: "",
    rating_comment: ''
}



const labels = {
    1: 'Poor',
    2: 'Fair',
    3: 'Good',
    4: 'Very Good',
    5: 'Exceptional',
};

function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

function RatingReview({ rating, setRating, businessId }) {
    const LOCAL_FORM_STORE = 'RATE_BUSINESS'
    const { user, REACT_APP_API_URL } = useContext(GlobalContext)
    const getFormValues = () => {
        const storedValues = JSON.parse(localStorage.getItem(LOCAL_FORM_STORE))

        if (storedValues) {
            return storedValues
        } else {
            return initialValues
        }
    }
    const [formData, setFormData] = useState(getFormValues)
    const [working, setWorking] = useState(false)
    const { showAlert, setAlert, timerRef, closeAlert, fadeOutAlert } = useContext(AlertContext)
    const [optionBackground, setOptionBackground] = useState()
    const [rate, setRate] = useState(0)
    const [hover, setHover] = useState(-1)
    const [value, setVal] = useState(2)
    const [ratingValue, setRatingValue] = useState(0)
    const [ratingError, setRatingError] = useState("")
    const { getCookie, setCookie } = useContext(UtilContext)
    const changeHandler = async (e) => {
        let value = e.target.value
        let name = await e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const rateBusiness = async (data) => {

        setWorking(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));

        let xdata = JSON.stringify(data)
        let xobj = JSON.parse(xdata)
        xobj["user_id"] = (user?.user_id) ? user?.user_id : getCookie("rating-review")
        xobj["business_id"] = businessId
        xobj["rating"] = ratingValue
        xobj["cookie_hash"] = getCookie("rating-review")

        //alert(JSON.stringify(xobj) + 'a')
        //return false

        if (ratingValue == 0) {
            setRatingError("Please select a rating.")
            setAlert(null)
            return false
        }

        let endpoint = REACT_APP_API_URL + "?operation=RATE_BUSINESS"
        if (user?.user_id) {
            endpoint += "&user_id=" + user?.user_id
        } else {
            endpoint += "&user_id=" + getCookie("rating-review")
        }

        endpoint += "&business_id=" + businessId
        endpoint += "&fullname=" + xobj["fullname"]
        endpoint += "&rating_comment=" + xobj["rating_comment"]
        endpoint += "&rating=" + xobj["rating"]
        endpoint += "&cookie_hash=" + getCookie("rating-review")

        //alert(endpoint)
        //window.location.href = endpoint
        //return false

        const res = await axios.post(endpoint, xobj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((rsp) => {

            showAlert("success", "Successful.")

            setTimeout(() => {
                localStorage.setItem(LOCAL_FORM_STORE, null)
                showAlert("info", "Reloading...")

                setTimeout(() => {
                    window.location.reload()
                }, 2000)

            }, 1000)


        }).catch((rsp) => {

            let err = (rsp.response.data.data[0].error)
            showAlert("info", "Error Encountered")
            console.log(rsp)
            fadeOutAlert(timerRef)
            setWorking(false)
        })
    }

    const onSubmit = async (data) => {
        //alert(JSON.stringify(data))
        setRatingError("")
        showAlert("info", "Working...")

        //alert(JSON.stringify(data))
        let status = await rateBusiness(data)
        //alert(JSON.stringify(data))
    }

    const {
        register,
        handleSubmit,
        watch,
        setError,
        setValue,
        getValues,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: getFormValues(),
        resolver: zodResolver(RatingReviewSchema)
    })

    useEffect(() => {
        let tmpFormState = null;

        if (localStorage.getItem(LOCAL_FORM_STORE)) {
            tmpFormState = JSON.parse(localStorage.getItem(LOCAL_FORM_STORE))
        }
        setFormData((prev) => ({ ...prev, ...tmpFormState }))
    }, [])

    useEffect(() => {
        localStorage.setItem(LOCAL_FORM_STORE, JSON.stringify(formData))
    }, [formData, LOCAL_FORM_STORE])
    const [xrating, setxRating] = useState()
    useEffect(() => {
        let xrate = getValues("rating")
        setxRating(xrate)
    }, [])

    const createCookie = () => {
        const cookie = getCookie("rating-review")

        if (cookie === null) {
            let cookieVal = "RATED"
            let days = 1
            let valueHash = sha256(cookieVal.toString())
            setCookie("rating-review", valueHash, days)
        }
    }

    useEffect(() => {
        createCookie()
    }, [])
    return (
        <div className=' px-0 py-4 lg:w-[70%]'>
            <div className=' flex  font-bold text-[26px]   rounded-[8px]'>
                How would you rate this business?
            </div>
            <H16 />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div className=' relative flex flex-col place-content-start place-items-start'>
                        <StyledRating value={ratingValue} setValue={setRatingValue} />
                        {ratingError && (<div className=' text-red-400  text-[15px] w-full text-center'><span className=' text-red-500 font-bold'>{ratingError}</span></div>)}
                    </div>
                    {errors.rating && (<div className=' text-red-400  text-[15px] w-full text-left'><span className=' text-red-500 font-bold'>{errors.rating.message}</span></div>)}

                </div >


                <div className=' mt-2 flex flex-col '>
                    <div className=' text-black text-[17px]  font-semibold'>
                        Review Title
                    </div>

                    <input
                        {...register("fullname", {
                            onChange: changeHandler
                        })}

                        type="text"
                        placeholder='Enter full names'
                        className=' border-[1px] border-gray-500/60 outline-none rounded-[3px] w-full py-1 px-[12px] h-fit text-[14px]  mt-[5px] '
                    />
                    {errors.fullname && (<div className=' text-red-400  text-[15px] w-full text-left'><span className=' text-red-500 font-bold'>{errors.fullname.message}</span></div>)}

                    <H16 />
                    <div className=' text-black text-[17px]  font-semibold'>
                        Write Review
                    </div>

                    <textarea
                        {...register("rating_comment", {
                            onChange: changeHandler
                        })}
                        className=' border-[1px] border-gray-500/60 outline-none rounded-[3px] w-full px-[12px] py-[15px] min-h-[150px] h-fit text-[14px] mt-[5px] '
                        placeholder='Enter your rating'
                    >
                    </textarea>
                    {errors.rating_comment && (<div className=' text-red-400 mb-[5px] text-[15px] w-full text-left'><span className=' text-red-500 font-bold'>{errors.rating_comment.message}</span></div>)}

                    <button className=' bg-green-800 px-5 py-2 text-white rounded-[5px]  mt-3'>
                        Submit Rating
                    </button>
                </div>
            </form >
        </div >
    )
}



export default RatingReview
