import { useContext, useState } from "react"
import { UtilContext } from "../../../../context/UtilContext"
import AlertContext from "../../../../context/AlertContext"
import { GlobalContext, GlobalSetterContext } from "../../../../context/GlobalContext"
import axios from "axios"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { CgSpinner } from "react-icons/cg"
import ChangePasswordSchema from "./ChangePasswordSchema"
import InputCustom from "../../components/InputCustom"
import GreenButton from "../../components/GreenButton"
import GrayButton from "../../components/GrayButton"
import PageTitle from "../../components/PageTitle"

export const ChangePasswordForm = ({ user, preloadedValues }) => {

    const parsedPreloadedValue = (JSON.parse(preloadedValues))
    const [formData, setFormData] = useState(parsedPreloadedValue)
    const [loading, setLoading] = useState(false)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const { logoutUser } = useContext(GlobalSetterContext)

    const [userData, setUserData] = useState(null)
    const [successMsg, setSuccessMsg] = useState(null)
    const [working, setWorking] = useState(false)
    const { showAlert, timerRef, closeAlert, fadeOutAlert,
        fadeOutAlertWithParam
    } = useContext(AlertContext)

    const { getSubstr, getHostPortProtocol } = useContext(UtilContext)
    const [errorMsg, setErrorMsg] = useState(null)


    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const changePassword = async (data) => {
        //alert(JSON.stringify(data))

        //return false
        setWorking(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        //return false
        let endpoint = REACT_APP_API_URL + "?operation=CHANGE_PASSWORD"


        const res = await axios.post(endpoint, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(async function (result) {

            showAlert("success", "Success: Password Updated")
            setSuccessMsg("Success: Password Updated")
            setWorking(false)
            await new Promise((resolve) => setTimeout(resolve, 1000));
            logoutUser()
            //setMsg("Your password has been changed successfully. You will be automatically logged out in 15 seconds.")
            //setWorking(false)
            //await new Promise((resolve) => setTimeout(resolve, 15000));
            //logoutUserWithoutRedirect()

        }).catch(function (e) {
            showAlert("danger", "Not Completed")
            console.log(e)
            if (e.response) {
                setErrorMsg(e.response.data.data[0].error)
            }
            else {
                console.log(e)
            }
            setWorking(false)
        })

    }

    const onSubmit = async (data) => {
        setSuccessMsg(null)
        setErrorMsg(null)
        showAlert("info", "Working...")
        changePassword(data)
    }



    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: (parsedPreloadedValue),
        resolver: zodResolver(ChangePasswordSchema)
    })
    return (
        <div className=' mt-6'>
            <div className=" border-b-[1px] border-b-gray-500/50 pb-[16px] grid grid-cols-2">
                <PageTitle title={"Password"} />

                <div className="flex place-content-end place-items-center space-x-1 ">



                    <GrayButton
                        title="Go to home"
                        url="/account/home" />



                </div>
            </div>

            <div className=' h-[32px]'></div>

            <div className=' border-[1px] border-gray-500/30 rounded-[10px] px-[16px] py-[22px]'>

                <div className=' flex gap-3 font-bold text-xl mb-3'>

                    <div className=' text-red-700 '>
                        Password Change
                    </div>
                </div>
                <div className=' text-[17px]'>

                    Ensure sure your password has at least 8 alphanumeric characters including any <b><u>uppercase</u></b> letter ( starting from A to Z), any <b><u>lowercase</u></b> letter (starting from a to z), any number (0 to 9) and any and/or combination of a special character including $ % ^ & * ( ) $ £ @ !.
                </div>


            </div>

            <div className=' mt-8'>
                <form id="cpf" action={REACT_APP_API_URL} onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className='flex flex-col w-[100%] mb-6'>

                            <div className=" w-[100%]">
                                <InputCustom
                                    register={register}
                                    changeHandler={changeHandler}
                                    controlName={"oldpwd"}
                                    type={"password"}
                                    maximumLength={200}
                                    width={50}
                                    labelTop={"Old Password"}
                                    labelBottom={"Please enter your old password."}
                                    placeholder={"Enter Old password"}
                                    error={errors.oldpwd}
                                />

                            </div>

                            <div className=" w-[100%]">
                                <InputCustom
                                    register={register}
                                    changeHandler={changeHandler}
                                    controlName={"newpwd"}
                                    type={"password"}
                                    maximumLength={200}
                                    width={50}
                                    labelTop={"New Password"}
                                    labelBottom={"Please enter your new password. Password must be alphanumeric with speial characters."}
                                    placeholder={"Enter your old password"}
                                    error={errors.newpwd}
                                />
                            </div>

                            <div className=" w-[100%]">
                                <InputCustom
                                    register={register}
                                    changeHandler={changeHandler}
                                    controlName={"newpd2"}
                                    type={"password"}
                                    maximumLength={200}
                                    width={50}
                                    labelTop={"Confirm Password"}
                                    labelBottom={"Please confirm your new password. Password must be alphanumeric with special characters."}
                                    placeholder={"Enter your old password"}
                                    error={errors.newpd2}
                                />

                            </div>
                        </div>





                        <input type="hidden"
                            {
                            ...register("user_id", {
                                onChange: changeHandler
                            })
                            }
                        />

                        <input type="hidden" name="operation" value={"CHANGE_PASSWORD"}
                        />

                        <input type="hidden" name="first_name" value={user?.first_name}
                        />

                        <input type="hidden" name="email" value={user?.email}
                        />
                    </div>

                    <div className='form-inputs-wrapper'>



                        <div className=' mt-0'>
                            <GreenButton
                                title={"Change password"}
                                working={working}
                            />
                        </div>
                    </div>
                    {successMsg &&
                        <div className='text-green-700 mt-[5px] text-[15px] font-bold border-[1px] border-green-800/40 p-[8px] rounded-[8px] w-[50%] bg-green-500/20 leading-5 '>
                            {successMsg}
                        </div>}

                    {errorMsg &&
                        <div className='text-red-700 mt-[5px] text-[15px] font-bold border-[1px] border-red-800/40 p-[8px] rounded-[8px] w-[50%] bg-red-500/20 leading-5 '>
                            {errorMsg}
                        </div>}


                </form>
            </div>
        </div>
    )
}