import React from 'react'

function H64() {
    return (
        <div>
            <div className=' h-[64px]'></div>
        </div>
    )
}

export default H64
