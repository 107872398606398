import React, { useEffect, useState } from 'react'
import countries from '../../../components/json/country.json'
import { MdCancel } from 'react-icons/md'
import { FcCancel } from 'react-icons/fc'
import { GiCancel } from 'react-icons/gi'
import { GrStatusGood } from 'react-icons/gr'



const CountryCodeWithMobile = ({ register, changeHandler, getFormValues, countryName, phoneName, labelTop, countryCodeError, mobileError }) => {
    const [phoneCountry, setPhoneCountry] = useState(null)
    const [phoneMobile, setPhoneMobile] = useState(null)
    const [countryValue, setCountryValue] = useState(getFormValues().country_code)
    const [mobileValue, setMobileValue] = useState(getFormValues().mobile)

    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    useEffect(() => {
        let country = document.getElementById(countryName)
        let mobile = document.getElementById(phoneName)
        setPhoneCountry(country)
        setPhoneMobile(mobile)
    }, [])


    return (
        <div className='flex flex-col w-[100%] mb-6'>
            <div className='flex flex-col w-[100%]'>
                <div className='font-bold  mb-3' htmlFor="title">{labelTop}</div>
                <div className='  w-[100%] mb-[-8px] pb-4 text-[14px] '>
                    <div>
                        <div className=' font-normal mb-0'>Guideline: Follow the guide below when entering mobile. Leave the first zero in your mobile.</div>
                        <ul className=' text-[14px] space-y-[0px]'>
                            <li className=' flex place-items-center space-x-2'>
                                <div>(070) 4804-8394 <b>: <span className='text-red-500'>Wrong  </span></b></div>
                                <GiCancel size={20} />
                            </li>
                            <li className=' flex place-items-center space-x-2'>
                                <div>(704) 804-8394 <b>: <span className='text-green-600'>Right</span></b></div>
                                <GrStatusGood size={20} />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className=' grid grid-cols-2 gap-1 w-[100%]'>
                    <div className=' relative '>
                        <select name={countryName} id="phoneCountry"
                            {...register(countryName, {
                                onChange: ((e) => {
                                    changeHandler(e)
                                    e.target.blur()
                                    setCountryValue(e.target.value)
                                })
                            })}
                            className=' w-full border-[1px] 
                            outline-none border-gray-500/40 
                            bg-white shadow-md rounded-md 
                            px-[10px] py-[12px] text-[15px] 
                            min-h-[50px] absolute'
                            placeholder='Select Country Code'
                        >
                            <option value="" data-countryCode="Select" Selected>
                                Select Country Code
                            </option>
                            {countries.map((item) => {
                                return (
                                    <option value={item.callCode} Selected>{item.countryName} +{item.callCode}</option>
                                )
                            })}
                        </select>
                    </div>

                    <div className=' relative'>
                        <input
                            {...register(phoneName)}

                            type='text'

                            value={mobileValue}
                            onChange={(e) => {

                                if (e.target.value.length <= 20) {
                                    let val = normalizeInput(e.target.value, mobileValue)
                                    setMobileValue(val)
                                    changeHandler(e)
                                }

                            }}
                            className={`w-full ${countryValue ? `
                                border-[1px] outline-none
                                border-gray-500/40
                                bg-gray-500/5 shadow-md
                                rounded-md px-[10px]
                                py-[10px]
                                text-[15px]
                                ` :
                                `border-[1px] outline-none
                                border-gray-500/40
                                bg-gray-500/20
                                shadow-md rounded-md
                                px-[10px] py-[10px] text-[15px]
                                `
                                } h-[50px]`}
                            placeholder='Enter Phone: +2349045872020'
                            disabled={countryValue ? "" : "disabled"}
                        />


                    </div>

                </div>
                {countryCodeError && (<div className=' text-red-400 mt-[5px] text-[15px]'><span className=' text-red-500 font-bold'>{countryCodeError.message}</span></div>)}
                {mobileError && (<div className=' text-red-400 mt-[4px] text-[15px]'><span className=' text-red-500 font-bold'>{mobileError.message}</span></div>)}

                <div className='font-normal text-[14px] mt-[8px] leading-[18px]'>
                    Select the country national calling code. E.g. +1, +009. Contact us if you do not find your country's code.
                </div>
            </div>
        </div>

    )
}

export default CountryCodeWithMobile
