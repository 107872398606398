import React from 'react'
import Header from '../../components/header/Header'
import HeroAnimated from '../../components/body/HeroAnimated'
import Hero from '../../components/body/Hero'
import Prefooter from '../../components/footer/Prefooter'
import Footer from '../../components/footer/Footer'
import Subtitle from '../../components/body/Subtitle'
import TopIntro from '../../components/body/TopIntro'
import ClientResult from '../../components/body/ClientResult'
import WhatWeDo from '../../components/body/WhatWeDo'
import HowWeCanHelp from '../../components/body/HowWeCanHelp'
import Impact from '../../components/body/Impact'
import InsightsBox from '../../components/body/InsightsBox'
import Insights from '../../components/body/Insights'
import HorizontalSpce from '../../components/body/HorizontalSpace'
import PostHero from '../../components/body/PostHero'
import PostHeroBar from '../../components/body/PostHeroBar'
import H16 from '../../components/body/H16'
import H32 from '../../components/body/H32'
import H64 from '../../components/body/H64'
import Products from '../../components/body/Products'

function CBDCSmartContracts() {
    const insights = [
        {
            id: 1,
            category: "Mergers and Acquisitions",
            title: "Thoughts on Smart Contracts and Feasibility",
            description: "Oliver Hart won the Nobel Memorial Prize in Economic Sciences in 2016 for his work in contract theory.",
            ftrcategory: "Youtube",
            img: "img/olivrhrt.jpg",
            link: "https://www.youtube.com/watch?v=Ee_3Nvl-lGE"
        },
        {
            id: 2,
            category: "Blockchain",
            title: "How Smart Contracts Will Change the World - Olga Mack",
            description: "Olga Mack is an experienced lawyer who developed a passion about the intersection of law and blockchain.",
            ftrcategory: "Youtube",
            img: "img/olgamck.jpeg",
            link: "https://www.youtube.com/watch?v=pA6CGuXEKtQ"
        },
        {
            id: 3,
            category: "Blockchain",
            title: "DBS – Blockchain: Bringing Intelligence into Payments",
            description: `DBS - Digital Business Leaders Series 2021, themed “Back to the Future”, featured the most influential technology developments.`,
            ftrcategory: "Payments",
            img: "img/globaldbs.webp",
            link: "https://www.youtube.com/watch?v=1qXs_A0rPrE"
        },
        {
            id: 4,
            category: "Artificial Intelligence",
            title: "Applications of smart contracts - Sergey Nazarov and Lex Fridman",
            description: "Sergey Nazarov is the CEO of Chainlink, a decentralized oracle network that provides data to smart contracts.",
            ftrcategory: "Webinar",
            img: "img/smc.avif",
            link: "https://www.youtube.com/watch?v=l-sTw136zAs"
        },
    ]

    const topIntros = [
        {
            id: 1,
            title: "Customer Smartcontract Design Needs & Suitability",
            description: "Our approach to Customer Smart Contract Design revolves around a customer-centric philosophy, ensuring that each smart contract aligns seamlessly with the client's objectives while maximizing efficiency and security.",
        },
        {
            id: 2,
            title: "Smart Contract Development and Deployment",
            description: "With a deep understanding of blockchain technology and programming languages such as Solidity, our team crafts smart contracts that are not only secure and efficient but also aligned with our clients' specific use cases and business goals.",
        },
        {
            id: 3,
            title: "Post Deployment Monitoring & Feedback",
            description: "Our approach to this critical phase revolves around continuous monitoring and proactive feedback mechanisms, ensuring the ongoing optimization and refinement of smart contracts in response to real-world usage and evolving market conditions.",
        },
    ]
    const experience = [
        {
            id: 1,
            url: "img/nft.jpg",
            category: "Smart Contracts",
            title: "With Solidity Smart Contracts, Organizations Can Set Sail",
            description: "We offer invaluable guidance to individuals and organizations navigating the complexities of blockchain development on the Ethereum platform.",
        },
        {
            id: 2,
            url: "https://www.qloudx.com/wp-content/uploads/2021/04/Blog-14-Microchip-Network.jpg",
            category: "CBDC & Blockchain",
            title: "Central Bank Digital Currency, Smart Contracts & Middleware",
            description: "Drawing from extensive experience in CBDC solutions, our consultancy offers a holistic approach to navigating the complexities of modern digital finance ecosystems.",
        },
    ]
    const ourhelp = [
        {
            id: 1,
            title: "Smart Contract Design",
            description: "At the design stage of every smart contract, we can help to carefully plan for the successful implementation of a robust solution."
        },
        {
            id: 2,
            title: "Smart Contract Development",
            description: "We help through blockchain platform selection, feasibility, contract logic, functionalities and security audits."
        },
        {
            id: 3,
            title: "Smart Contract Deployment",
            description: "Specialized expertise will help to ensure seamless and secure transition of smart contracts from development to live blockchain environments"

        },
        {
            id: 4,
            title: "Smart Contract Support",
            description: "Launching your smart contract is a major milestone. However, post-go live support is crucial for maintaining a smooth operation and ensuring your smart contract functions as intended."

        },
    ]
    const whatwedo = "Storcs understand the seismic forces reshaping business for defense contractors today. Amid extraordinary budgetary pressures, new entrants are emerging around the globe as corporate consolidation continues across the industry. While capital is sometimes abundant, it is harder than ever to find and harness the right talent. To thrive, established companies must become more agile, getting products to market faster than ever—customers increasingly expect this, and at lower cost. We can help you adapt to these trends, with a proven track record built on successful engagements with virtually every A&D prime and dozens of Tier I/II suppliers.";

    const impacts = [
        {
            id: 1,
            figure: "10,000+",
            description: "Number of Lines of Codes of Smart Contracts completed."
        },
        {
            id: 2,
            figure: "43%",
            description: "Average additional value that companies using Storcs for Smart Contract support realize compared to those that don’t"
        },
    ]

    return (
        <div>
            <Header bg={"light"} />
            <HeroAnimated
                imgLink={"img/crypto.jpg"}
                category={"CBDC & Crypto"}
                title={"Central Bank Digital Currency, Smart Contracts, Crypto"}
            />



            <div>
                <PostHero
                    img={'img/sftw.jpg'}
                    premessage={"Smartcontracts are defining the future."}
                    message1={`Embarking on the journey of Central Bank Digital Currency (CBDC) implementation requires a deep understanding of both blockchain technology and regulatory compliance. Our CBDC Smart Contract Consulting services offer tailored guidance to central banks and financial institutions seeking to navigate this complex terrain.`}
                    message2={`From drafting smart contract protocols to ensuring compliance with local regulations, our team of experts provides comprehensive support at every stage of the CBDC development process. With a focus on security, efficiency, and transparency, we empower our clients to harness the transformative potential of CBDCs while mitigating risks and maximizing impact.`}
                    message3={`Furthermore, we provide strategic guidance on integrating CBDCs into existing payment infrastructures, fostering interoperability with traditional financial systems while leveraging the benefits of distributed ledger technology`}
                />
                <H64 />
            </div>

            <div className=' ' style={{ background: "url('img/cloud.jpg')", backgroundPosition: "top -100% -80%", backgroundRepeat: "no-repeat", }}>
                <Subtitle title={"Our Approach"} />
                <TopIntro topIntros={topIntros} />
                <H64 />
            </div>

            {/*  <div>
                <Subtitle title={"What we do"} />
                <WhatWeDo whatwedo={whatwedo} />
                <H64 />
            </div> */}

            <div>
                <Subtitle title={"Our Experience"} />
                <ClientResult experience={experience} />
                <H64 />
            </div>




            <div className=' mt-10'>
                <Subtitle title={"How We Can Help"} />
                <HowWeCanHelp ourhelp={ourhelp} />
                <H64 />
            </div>




            <div className=' mt-10'>
                <Subtitle title={"Impact"} />
            </div>

            <div>
                <Impact impacts={impacts} />
                <H64 />
            </div>

            <div>
                <Subtitle title={"Insights on Smart Contracts"} />
            </div>

            <div>
                <InsightsBox insights={insights} />
                <H64 />
            </div>


            <div>
                <Subtitle title={"Top AI Products"} />
            </div>

            <div>
                <Products />
                <H64 />
            </div>



            <div className=' h-20'></div>
            <div>
                <Prefooter />
            </div>

            <Footer />
        </div>
    )
}

export default CBDCSmartContracts
