import React from 'react'

const NavButton = ({ icon, title, link }) => {
    return (
        <a href={link}
            className='
            flex 
            w-fit px-3 py-1 rounded-full
            place-content-center place-items-center
            gap-2 text-[14px]
            '
        >
            <span>
                {icon}
            </span>
            <span>
                {title}
            </span>
        </a>

    )
}

export default NavButton
