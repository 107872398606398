import React, { useContext, useEffect, useState } from 'react'
import HeaderLightSearch from '../../components/header/headerLight/HeaderLightSearch'
import HeaderSpace from '../../components/header/HeaderSpace'
import Prefooter from '../../components/footer/Prefooter'
import Footer from '../../components/footer/Footer'
import PageStructure from './PageStructure'
import { GlobalContext } from '../../context/GlobalContext'
import { UtilContext } from '../../context/UtilContext'
import axios from 'axios'
import SubNav from './SubNav'
import { BusinessProvider } from './BusinessContext'
import H32 from '../../components/body/H32'
import SubNavFlex from '../../components/body/subnavflex/SubNavFlex'
import H16 from '../../components/body/H16'

import { useCookies } from 'react-cookie'
import { sha256 } from 'js-sha256'
import TopBanner from '../../components/ads/TopBanner'

const Business = () => {
    const [id, setId] = useState("")
    const [businessData, setBusinessData] = useState(null)
    const [loading, setLoading] = useState(true)
    const { REACT_APP_IMG_PATH, user, REACT_APP_API_URL,
        isAdsense, gcolors } = useContext(GlobalContext)
    const [counter, setCounter] = useState(0)
    const { getUrlId, setCookie, getCookie } = useContext(UtilContext)
    const [businessVisitorCookie, setBusinessVisitorCookie] = useState(null)
    const [cookies, setCookies] = useCookies()
    const [working, setWorking] = useState(false)

    useEffect(() => {
        let id = getUrlId()
        setId(id)
    }, [getUrlId])

    useEffect(() => {
        if (id) {
            const endpoint = REACT_APP_API_URL + "?operation=READ_BUSINESS&id=" + id
            //window.location.href = (endpoint)
            axios.get(endpoint, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then((res) => {
                    const data = JSON.stringify(res.data.data[0])
                    //console.log(data)
                    setBusinessData((JSON.parse(data)))
                    setLoading(false)
                    //alert(businessData)
                })
        }
    }, [setBusinessData, id])

    const logVisit = async (cookie) => {
        const data = new FormData()
        data.append('cookie_id', cookie)
        data.append('business_id', businessData?.business_id)
        data.append('webpage', 'business')
        data.append('client', navigator.userAgent)
        data.append('url_visited', window.location.href)

        let endpoint = REACT_APP_API_URL + "?operation=LOG_VISIT"
        endpoint += "&cookie_id=" + cookie
        endpoint += "&business_id=" + businessData?.business_id
        endpoint += "&webpage=" + 'business'
        endpoint += "&client=" + navigator.userAgent
        endpoint += "&url_visited=" + window.location

        //alert(endpoint)
        //window.location.href = endpoint
        //return false

        const res = await axios.post(endpoint, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((rsp) => {

        }).catch((rsp) => {
            let err = (rsp.response.data.data[0].error)
            console.log(rsp)
        })
    }

    const createCookie = () => {
        const cookie = getCookie("business-visitor")

        if (cookie === null) {
            let days = 1
            let cookieVal = new Date().getTime()
            let valueHash = sha256(cookieVal.toString())

            setCookie("business-visitor", valueHash, days)

            setBusinessVisitorCookie(getCookie("business-visitor"))
            logVisit(getCookie("business-visitor"))
            console.log(businessVisitorCookie)
        } else {
            setBusinessVisitorCookie(cookie)
            logVisit(getCookie("business-visitor"))
        }



    }

    useEffect(() => {
        if (businessData) {
            if (process.env.REACT_APP_ENV == 'DEV') {
                createCookie()
                //alert('boo')
            } else {
                if (user?.user_id === businessData?.owner_id) {
                    //do nothing
                    //alert('he')
                } else {
                    createCookie()
                    console.log(businessData)
                }
            }



        }

    }, [businessData])





    useEffect(() => {
        if (businessVisitorCookie) {
            console.log(businessVisitorCookie)
        }
    }, [businessVisitorCookie])

    return (
        <div>

            <HeaderLightSearch
                bgColor={"bg-transparent"}
                bgColorOnScroll={"bg-white"}
                logoColor={"text-[#fff]"}
                logoTextColor={"text-[#4b0d96]"}
                logoTextColorOnScroll={"text-[#4b0d96]"}
                logoColorOnScroll={"text-[#fff]"}
                logoBgColor={"bg-[#4b0d96]"}
                logoBgColorOnScroll={"bg-[#4b0d96]"}
                color={"text-black"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />

            <HeaderSpace />


            <SubNav />
            <H16 />


            <TopBanner
                dataAdSlot={'3148685431'}
                dataAdFormat={'auto'}
                dataFullWidthResponsive={true}
            />
            <H32 />

            {businessData !== null && businessData !== '[]' && businessData !== null &&

                <BusinessProvider>
                    <PageStructure
                        business={businessData}
                    />
                </BusinessProvider>

            }


            <HeaderSpace />
            <Prefooter />
            <Footer />

        </div>
    )
}

export default Business
