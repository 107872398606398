import React, { useState } from 'react'
import { GrLocation } from 'react-icons/gr'
import countryjson from '../../../json/country_locale.json'

const Where = ({ register, setValue, changeHandler }) => {
    const [results, setResults] = useState([])
    const getCountry = (value) => {
        const results = countryjson.filter((country) => {
            return (
                country.countryName.toLowerCase().includes(value)
            )
        })
        setResults(results)
    }
    const fetchData = (value) => {
        fetch("https://jsonplaceholder.typicode.com/users")
            .then((response) => response.json())
            .then((json) => {
                const results = json.filter((user) => {
                    return (
                        value &&
                        user &&
                        user.name &&
                        user.name.toLowerCase().includes(value)
                    )
                })

                setResults(results)
            })
    }

    const loadData = (value) => {
        getCountry(value)
    }
    return (
        <div className='border-[1px] border-gray-500/30 h-[45px] w-[44%]
                px-[4px] py-[0px] flex place-items-center gap-2 bg-white
                relative'>
            <div className='ml-1 place-content-center'>
                <GrLocation size={17} />
            </div>
            <div
                className='place-content-center w-full bg-blue-500 '
                onBlur={async () => {
                    const resultslist = document.getElementById("results-list")
                    if (resultslist) {
                        await new Promise((resolve) => setTimeout(resolve, 200));
                        resultslist.style.display = 'none'
                    }

                }}
            >
                <input
                    {...register("where", {
                        onChange: changeHandler
                    })}
                    className=' text-[17px] outline-none 
                             w-full'
                    placeholder='Where?'
                    type="text"
                    id='xbar'
                    onKeyUp={(e) => loadData(e.target.value)}
                    onFocus={(e) => {
                        const resultslist = document.getElementById("results-list")
                        if (resultslist) {
                            resultslist.style.display = 'block'
                        }
                    }}


                />


            </div>
            {results && results.length > 0 &&
                <SearchResultsList setValue={setValue} results={results} />
            }
        </div>
    )
}


export const SearchResultsList = ({ results, setValue }) => {
    return (
        <div id='results-list' className=' w-[100%] absolute top-[40px] z-30 bg-white flex flex-col border-[1px] border-gray-500 shadow-md rounded-b-[10px] mt-[5px] max-h-[300px] overflow-y-auto'>
            {
                results.map((result, id) => {
                    return (
                        <SearchResult setValue={setValue} result={result.countryName} key={id} />
                    )
                })
            }
        </div>
    )
}


export const SearchResult = ({ result, setValue }) => {

    return (
        <div
            className=' px-[20px] cursor-pointer py-[10px] hover: bg-[#efefef]
             '
            onClick={async (e) => {
                document.getElementById("xbar").value = result
                await new Promise((resolve) => setTimeout(resolve, 100));
                setValue("where", result)
                document.getElementById("results-list").style.display = 'none'
            }}
            id='search-result'
        >
            {result}
        </div>
    )
}

export default Where
