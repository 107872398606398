import React from 'react'

const GrayButton = (props) => {
    return (
        <div>
            <a href={props.url}
                className='
             hover:text-white/70
              bg-[#6001d2]/80 px-2 
               rounded-full 
               shadow-md
                font-bold
              text-[14px] pt-[7px] pb-[8px]
              text-white
            '
            >
                {props.title}
            </a>
        </div>
    )
}

export default GrayButton
