import { React, useState, useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import MiddleMenu from '../MiddleMenu'
import Logo from '../Logo'
import Hamburger from '../Hamburger'
import LogoAlt from '../LogoAlt'

const routeConfig = [

]

function HeaderLight({ bgColor, bgColorOnScroll, logoColor, logoColorOnScroll, logoBgColor, logoBgColorOnScroll, color, colorBorder, colorOnScroll, borderOnScroll, borderColorOnHover }) {
    const router = useRoutes(routeConfig)

    const [isScrolling, setisScrolling] = useState(false);
    const [defaultBg, setDefaultBg] = useState("light")
    const [defaultTxt, setDefaultTxt] = useState()

    const TOP_OFFSET = 50;
    useEffect(() => {
        const handleScroll = () => {

            if (window.scrollY >= TOP_OFFSET) {
                setisScrolling(true)
            } else {
                setisScrolling(false)
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        setDefaultBg(bgColor)
    }, [bgColor])


    return (
        <div>
            <div className={`fixed
            w-full
            top-0 z-10 transition duration-1000
            ${isScrolling ? bgColorOnScroll : bgColor} 
            ${isScrolling ? 'shadow-md' : ''}
            border-b-[1px] border-white/20
             pt-3 pb-3 z-[11]
            `}>


                <div className='mx-5 '>
                    <div className=' max-w-[1200px] mx-auto w-full'>
                        <header className=' relative flex items-center justify-between gap-10'>
                            <div className='relative top-[-3px]'>
                                <LogoAlt
                                    isScrolling={isScrolling}
                                    colorInfo={logoColor}
                                    colorOnScroll={logoColorOnScroll}
                                    logoBgColor={logoBgColor}
                                    logoBgColorOnScroll={logoBgColorOnScroll}
                                    fullLogoOnMobile={true}
                                />
                            </div>
                            <div className='hidden lg:block grow relative'>
                                <MiddleMenu router={router} isScrolling={isScrolling} color={color} colorBorder={colorBorder} borderOnScroll={borderOnScroll} colorOnScroll={colorOnScroll} />
                            </div>
                            <div className={`hover:text-white/60 hover:cursor-pointer flex items-center ${isScrolling ? 'text-black' : 'text-white'} space-x-2 border-1 border-gray-300 rounded-full py-2`}>
                                <Hamburger isScrolling={isScrolling} color={color} colorOnScroll={colorOnScroll} />
                            </div>
                        </header>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderLight
