import React from 'react'
import { BsClock } from 'react-icons/bs';

const GetDates = ({ workDays, businessData }) => {
    //alert(JSON.stringify(workDays))
    const getDay = () => {
        var today = new Date();
        //   multiplied by negative 24
        let currentDate = (today).toDateString(); // will be Monday

        let month = (today.getMonth() + 1).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })
        let oday = today.getDate().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })


        let year = today.getFullYear().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })


        let itemToReturn = ""

        workDays.map((item) => {
            let day = item.day
            let time = item.time
            if (time !== "" && time !== null) {
                if (currentDate.includes(day)) {
                    if (item.time.includes("Closed")) {

                        itemToReturn = <div className=' flex place-content-start place-items-center gap-2'>
                            <div className='w-fit text-[14px] font-bold'>
                                CLOSED NOW
                            </div>
                            <div className=' text-[14px]'>
                                Today: <b>{item.day}</b>, {item.time}
                            </div>
                        </div>
                    } else {
                        if (item.time.length >= 5) {

                            let hourAdjustment = 0
                            if (businessData?.timezone !== null && businessData?.timezone !== "") {
                                hourAdjustment = (businessData?.timezone * 1)
                            }
                            hourAdjustment = hourAdjustment
                            let date = new Date();
                            //let timezoneOffset = date.getTimezoneOffset();
                            let pstOffset = hourAdjustment * 60;
                            let adjustedTime = new Date(date.getTime() + (pstOffset) * 60 * 1000);

                            let currTime = adjustedTime.getTime()


                            let lastSegmentOfTime = item.time.substring(0, item.time.length - 3)
                            let secondSegment = lastSegmentOfTime.substring(lastSegmentOfTime.length - 5)
                            let hourInfo = secondSegment.split(":")

                            let closingHour = hourInfo[0].toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false
                            })
                            let closingMinute = hourInfo[1].toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false
                            })

                            let closingTimeStr = year + "-" + month + "-" + oday + "T" + (closingHour) + ":" + closingMinute + ":" + "00"
                            let closingOptions = {}
                            let closingTime = new Date(closingTimeStr).getTime()


                            let openingSegmentOfTime = item.time.substring(0, 5)
                            let secondSegmentOpening = openingSegmentOfTime.split(":")
                            let openingHour = secondSegmentOpening[0].toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false
                            });
                            let openingMinute = secondSegmentOpening[1].toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false
                            });

                            let openingTimeStr = year + "-" + month + "-" + oday + "T" + (openingHour) + ":" + openingMinute + ":" + "00"
                            let openingTime = new Date(openingTimeStr).getTime()


                            let diffOpening = currTime - openingTime
                            let diffClosing = currTime - closingTime

                            //alert(diffOpening + "=" + diffClosing)



                            if (diffOpening > 0 && diffClosing < 0) {
                                itemToReturn = <div className=' flex place-content-start place-items-center gap-2'>
                                    <span className='text-[13px]'>
                                        <BsClock />
                                    </span>
                                    <div className='w-fit text-[14px] font-bold'>
                                        OPEN NOW
                                    </div>
                                    <div className=' text-[14px]'>
                                        Today: <b>{item.day}</b>, {item.time}
                                    </div>
                                </div>
                            } else {
                                itemToReturn = <div className=' flex place-content-start place-items-center gap-2'>
                                    <div className='w-fit text-[14px] font-bold'>
                                        CLOSED NOW
                                    </div>
                                    <div className=' text-[14px]'>
                                        Today: <b>{item.day}</b>, {item.time}
                                    </div>
                                </div>
                            }
                        }
                    }
                    //alert(lastSegmentOfTime)
                }
            }
        })

        return itemToReturn
    }

    let day = getDay()

    return (
        <div>
            {day}
        </div>
    )
}

export default GetDates
