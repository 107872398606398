import React from 'react'
import { z } from 'zod'

const passwordValidation = new RegExp(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
);

const ResetPwSchema = z.object({
    email: z.string()
        .email({ message: "Please enter a valid email" }),
})

export default ResetPwSchema
