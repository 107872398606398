import React from 'react'

const PageTitle = ({ title }) => {
    return (
        <div className=' font-black text-[28px] tracking-tighter'>
            {title}
        </div>
    )
}

export default PageTitle
