import { CgSpinner } from "react-icons/cg"
import { GlobalContext } from "../../../../context/GlobalContext"
import { useContext, useEffect, useState } from "react"
import axios from "axios"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import AlertContext from "../../../../context/AlertContext"
import EditProfileSchema from "./EditProfileSchema"
import InputCustom from "../../components/InputCustom"
import ProfilePic from "./ProfilePic"
import CountryCodeWithMobile from "../../components/CountryCodeWithMobile"
import CountryDropDown from "../../components/CountryDropDown"
import countryjson from "../../../../components/json/country.json"
import GreenButton from "../../components/GreenButton"
import GrayButton from "../../components/GrayButton"
import PageTitle from "../../components/PageTitle"

export const ProfileContent = ({ preloadedValues, user }) => {

    const parsedPreloadedValue = (JSON.parse(preloadedValues))
    const [formData, setFormData] = useState(parsedPreloadedValue)
    const [loading, setLoading] = useState(false)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const [userData, setUserData] = useState(null)
    const { showAlert, timerRef, closeAlert, fadeOutAlert, fadeOutAlertWithParam } = useContext(AlertContext)
    const [msg, setMsg] = useState()
    const [working, setWorking] = useState()


    function getFormValues() {

        return parsedPreloadedValue
    }

    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    useEffect(() => {
        console.log(parsedPreloadedValue.email)
    })

    useEffect(() => {
        const endpoint = REACT_APP_API_URL + "?operation=READ_USER&id=" + user.user_id

        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = JSON.stringify(res.data.data[0])
                //alert(data)
                setUserData((JSON.parse(data)))
                setLoading(false)
                //alert(businessData)
            })
    }, [setUserData])

    const updateProfile = async (data) => {
        setWorking(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        let xdata = JSON.stringify(data)
        let xobj = JSON.parse(xdata)
        xobj["user_id"] = user.user_id

        const endpoint = REACT_APP_API_URL + "?operation=EDIT_USER"

        const res = await axios.post(endpoint, xobj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((rsp) => {

            showAlert("success", "Update Successful.")

            setTimeout(() => {
                showAlert("info", "Reloading...")

                setTimeout(() => {
                    window.location.href = ("/account/settings/profile")
                }, 2000)

            }, 1000)


        }).catch((rsp) => {

            let err = (rsp.response.data.data[0].error)
            showAlert("danger", "Error during update")
            fadeOutAlert(timerRef)
            setWorking(false)
            console.log(rsp)
        })
    }

    const onSubmit = async (data) => {
        showAlert("info", "Working...")
        updateProfile(data)
    }

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: (parsedPreloadedValue),
        resolver: zodResolver(EditProfileSchema)
    })





    return (
        <div className=' mt-6'>

            <div className=" border-b-[1px] border-b-gray-500/50 pb-[16px] grid grid-cols-2">
                <PageTitle title={"Account Profile"} />

                <div className="flex place-content-end place-items-center space-x-1 ">



                    <GrayButton
                        title="Go to home"
                        url="/account/home" />



                </div>
            </div>

            <div className=' mt-8'>
                <form id='euf' action={REACT_APP_API_URL} onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className=' grid lg:grid-cols-12 gap-4'>
                            <div className=' lg:col-span-7'>


                                <InputCustom
                                    register={register}
                                    changeHandler={changeHandler}
                                    controlName={"first_name"}
                                    type={"text"}
                                    maximumLength={200}
                                    width={100}
                                    labelTop={"First Name"}
                                    labelBottom={"Your first name may appear around Skyepages where you contribute or are mentioned. Your first name is compulsory"}
                                    placeholder={"Enter Your First Name"}
                                />

                                <InputCustom
                                    register={register}
                                    changeHandler={changeHandler}
                                    controlName={"last_name"}
                                    type={"text"}
                                    maximumLength={200}
                                    width={100}
                                    labelTop={"Last Name"}
                                    labelBottom={"Please enter your last name. This is a compulsory input. Preferably, an official last name."}
                                    placeholder={"Enter Your Last Name"}
                                />

                                <InputCustom
                                    register={register}
                                    changeHandler={changeHandler}
                                    controlName={"email"}
                                    type={"text"}
                                    maximumLength={200}
                                    width={100}
                                    disabled={"disabled"}
                                    labelTop={"Email Address"}
                                    labelBottom={<div>Your email can be changed using the link. <a href="/account/settings/email"><b><u>Change Email</u></b></a></div>}
                                    placeholder={"abc@domain.com"}
                                />
                            </div>
                            <div className=' lg:col-span-5 order-first lg:order-last'>
                                {parsedPreloadedValue && <ProfilePic profileData={userData} />}
                            </div>
                        </div>

                        <div className=" w-full lg:w-[50%]">
                            {
                                parsedPreloadedValue && <CountryCodeWithMobile
                                    register={register}
                                    changeHandler={changeHandler}
                                    getFormValues={getFormValues}
                                    countryName={"country_code"}
                                    phoneName={"mobile"}
                                    labelTop={"Mobile"}
                                />
                            }
                        </div>




                        <div className=' border-b-[1px] border-gray-700/20 mt-[32px] mb-[12px]'></div>
                        <div className=' mb-1 text-[17px] font-bold'>Business Address</div>
                        <div className=' text-[15px] tracking-tight font-light mb-6'>See sample entry below. Follow the entry below to add business address.</div>
                        <div className=' mb-6'>
                            <ul className=''>
                                <li className=' text-md'><b>Sample</b></li>
                                <li className=' text-sm'>612 Boulevard Avenue</li>
                                <li className=' text-sm'>New York, NY, 21345</li>
                                <li className=' text-sm'>United States of America</li>
                            </ul>
                        </div>
                        <div className="  w-full lg:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"address_one"}
                                type={"text"}
                                maximumLength={200}
                                width={100}
                                labelTop={"Address Line 1"}
                                labelBottom={"Enter anofficial business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"612 Boulevard Avenue"}
                            />
                        </div>

                        <div className=" w-full lg:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"address_two"}
                                type={"text"}
                                maximumLength={200}
                                width={100}
                                labelTop={"Address Line 2"}
                                labelBottom={"Enter an official business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"New York, NY, 21345"}
                            />
                        </div>

                        <div className=" w-full lg:w-[60%]">
                            <CountryDropDown
                                register={register}
                                changeHandler={changeHandler}
                                jsondata={countryjson}
                                controlName={"country"}
                                error={errors?.country}
                                labelTop={"Country"}
                                labelBottom={"Enter country of residence or country your business is registered in. Where a business is situated in more than one country, enter the country that represents the head quarters"}
                            />

                        </div>

                        <div className=' border-b-[1px] border-gray-700/20 mt-[32px] mb-[12px]'></div>
                        <div className=' text-[18px]'>Sample Completed Profile</div>
                        <div className=' text-[16px] tracking-tight font-normal mb-6'>If your entry is complete, your address should look like the following below. To get a complete address like the one below, fill all your details. They will automatically appear.</div>
                        <div className=' mb-6'>
                            <ul className=' text-[17px]'>

                                <li className=''><b>John, Doe</b></li>
                                <li className=' text-sm'>612 Boulevard Avenue</li>
                                <li className=' text-sm'>New York, NY, 21345</li>
                                <li className=' text-sm'>United States of America</li>
                                <li className=' text-sm'>+1 (704) 345 8736</li>
                            </ul>
                        </div>

                        {
                            (
                                parsedPreloadedValue.first_name &&
                                parsedPreloadedValue.last_name &&
                                parsedPreloadedValue.mobile &&
                                parsedPreloadedValue.address_one &&
                                parsedPreloadedValue.address_two &&
                                parsedPreloadedValue.country
                            ) && <div className=' mb-6 px-6 bg-gray-100 py-5 rounded-[12px] border-[1px] border-gray-500/80 w-[80%]'>
                                <ul className=' text-[17px] '>

                                    <li className=''><b>{parsedPreloadedValue.first_name}, {parsedPreloadedValue.last_name}</b></li>
                                    <li className=' text-sm'>{parsedPreloadedValue.address_one}</li>
                                    <li className=' text-sm'>{parsedPreloadedValue.address_two}</li>
                                    <li className=' text-sm'>{parsedPreloadedValue.country_code} {parsedPreloadedValue.mobile}</li>
                                    <li className=' text-sm'>{parsedPreloadedValue.country}</li>
                                </ul>
                            </div>
                        }




                        <div className=' border-b-[1px] border-gray-700/20 mt-[32px] mb-[12px]'></div>
                        <div className='flex flex-col w-[100%] mb-6'>


                            <label className='font-normal text-[14px] mt-[8px] leading-[18px]'>
                                Except your email address and first name,  all of the fields on this page are optional and can be deleted at any time, and by filling them out, you're giving us consent to share this data wherever your user profile appears. Please see our privacy statement to learn more about how we use this information.                             </label>

                            <div className=' mt-5'>
                                {/* <input type="hidden" name='operation' value={"EDIT_USER"} />
                                <input type="hidden" name='user_id' value={user.user_id} /> */}


                                <GreenButton
                                    title={"Update profile"}
                                    working={working}
                                />
                            </div>
                        </div>


                    </div>
                </form>
            </div >
        </div >
    )
}